import { Component, OnInit } from '@angular/core';
import { TsProgetto } from '../shared/ts-progetto.model';
import { AuthService } from '../auth.service'; 
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';

@Component({
  selector: 'app-ts-activity',
  templateUrl: './ts-activity.component.html',
  styleUrls: ['./ts-activity.component.scss']
})
export class TsActivityComponent implements OnInit {
  description_new:any;
  update_web:any = "false";
  company:any;
  dataSource = [];
  setting: TsProgetto = new TsProgetto();
  tableColumns: string[] = ['description', 'active', 'id'];
  constructor(public _storage: MyLocalStorageService, private _router: Router, private _service: NotificationsService, public _auth: AuthService) { }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.loadactivity();
  }
  loadactivity(){
    this._auth.load_activity_ts(this.setting)
    .subscribe(
      res => {
        if (res.risposta == "Errore") {
          this.dataSource = [];
        } else {
          this.dataSource = res; 
        }
      },
      err => console.log(err)
    );
  }
  update(row){
    this.setting=row;
    this.update_web="true";
  }
  back(){
    this._router.navigate(['/admin'], { skipLocationChange: true });
  }
  delete(id_ts_project, active){ 
    this.setting.id=id_ts_project;
    if (active == 1) {
      this._auth.disable_activity_ts(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
            'Activity disabilitata', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          } else {
            this._service.error('Error',
            'Qualcosa è andato storto', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
          this.loadactivity();
        },
        err => console.log(err)
      );
      
    } else {
      this._auth.enable_activity_ts(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
            'Activity riabilitata', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          } else {
            this._service.error('Error',
            'Qualcosa è andato storto', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
          this.loadactivity();
        },
        err => console.log(err)
      );
    }
      }
      save(){
        this._auth.update_activity_ts(this.setting)
        .subscribe(res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
            'Activity aggiornata', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
            this.update_web="false";
          } else {
            this._service.error('Error',
            'Activity non aggiornata', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
        },
         err => console.log(err)
       );  
      }

      newactivity(){
        this.update_web="new";
      }

      save_new(){
        this.setting.description=this.description_new;
        this._auth.nuovo_activity_ts(this.setting)
        .subscribe(res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
            'Activity inserita', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
            this.update_web="false";
          } else {
            this._service.error('Error',
            'Activity non inserita', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
          this._router.navigate(['/admin'], { skipLocationChange: true });
        },
         err => console.log(err)
       );  
      }
}
