<br>
<div style="text-align:center">
    <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<button style="margin:5px" mat-raised-button color="primary" (click)="newproject()">
    <mat-icon>fiber_new</mat-icon>
</button>



<input id="my-file-selector" type="file" (change)="importproj($event)" name="uploadExcel" style="display: none"/>
<button style="margin:5px" mat-raised-button color="primary" onclick="document.querySelector('#my-file-selector').click()" class="md-raised md-primary"> Import Projects </button>

<button style="margin:5px" mat-raised-button color="primary" (click)="back()">Back
    <mat-icon>keyboard_backspace</mat-icon>
</button>
<br>
<mat-card class="example-card" style="width: 100%; background-color: rgb(153, 218, 238)">
        <br>
        <mat-label style="width: 100%;font-size: x-large;">Timesheet Project</mat-label>
    <mat-table style="border: 1px;border-color: black;border-style: solid" [dataSource]="dataSource" matSort >
        <ng-container matColumnDef="code">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Code</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.code}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.description}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="active">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Active/Deactive</mat-header-cell>
            <mat-cell *matCellDef="let row"><button style="margin:5px" mat-raised-button color="warn" (click)="delete(row.id, row.active)">
                    <mat-icon style="color: green" *ngIf="row.active == '1'">radio_button_checked</mat-icon>
                    <mat-icon *ngIf="row.active == '0'">radio_button_unchecked</mat-icon>
                </button></mat-cell>
        </ng-container>
        <ng-container matColumnDef="id_legal_entity">
            <mat-header-cell *matHeaderCellDef  mat-sort-header>Id legal entity</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.id_legal_entity}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef >Edit</mat-header-cell>
            <mat-cell *matCellDef="let row"><button style="margin:5px" mat-raised-button color="primary" (click)="update(row)">
                    <mat-icon>update</mat-icon>
                </button></mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </mat-table>
    <mat-form-field style="width: 100%" *ngIf="update_web == 'true'">
        <input style="width: 100%" matInput placeholder="Code" [(ngModel)]="setting.code" value="setting.code" required>
        <mat-error *ngIf="passwordFormControl.hasError('required')">Mandatory</mat-error>
    </mat-form-field>
    <br>
    <mat-form-field style="width: 100%" *ngIf="update_web == 'true'">
        <input style="width: 100%" matInput placeholder="Description" [(ngModel)]="setting.description" value="setting.description" required>
        <mat-error *ngIf="passwordFormControl.hasError('required')">Mandatory</mat-error>
    </mat-form-field>
    <br>
    <mat-form-field style="width: 100%" *ngIf="update_web == 'true'">
        <mat-label>Legal Entity</mat-label>
        <mat-select style="width: 100%" [(ngModel)]="setting.id_legal_entity" required>
            <mat-option *ngFor="let entity of entities" [value]="entity.viewValue">
                {{entity.viewValue}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="passwordFormControl.hasError('required')">Mandatory</mat-error>
    </mat-form-field>
    <br>
    <mat-form-field style="width: 100%" *ngIf="update_web == 'new'">
        <input style="width: 100%" matInput placeholder="Code" [(ngModel)]="code_new" value="setting.code" required>
        <mat-error *ngIf="passwordFormControl.hasError('required')">Mandatory</mat-error>
    </mat-form-field>
    <br>
    <mat-form-field style="width: 100%" *ngIf="update_web == 'new'">
        <input style="width: 100%" matInput placeholder="Description" [(ngModel)]="description_new" value="setting.code" required>
        <mat-error *ngIf="passwordFormControl.hasError('required')">Mandatory</mat-error>
    </mat-form-field>
    <br>
    <mat-form-field style="width: 100%" *ngIf="update_web == 'new'">
        <mat-label>Legal Entity</mat-label>
        <mat-select style="width: 100%" [(ngModel)]="id_legal_entity_new">
            <mat-option *ngFor="let entity of entities" [value]="entity.viewValue">
                {{entity.viewValue}}
            </mat-option>
            <mat-error *ngIf="passwordFormControl.hasError('required')">Mandatory</mat-error>
        </mat-select>
    </mat-form-field>
    <button *ngIf="update_web == 'true' || update_web == 'new' " style="margin:5px" mat-raised-button color="primary" (click)="back()"> Back
    </button>
    <button *ngIf="update_web == 'new'" style="margin:5px" mat-raised-button color="primary" (click)="checkprogetto()">
            <mat-icon>save</mat-icon>
    </button>
    <button *ngIf="update_web == 'true'" style="margin:5px" mat-raised-button color="primary" (click)="save()">
        <mat-icon>save</mat-icon>
</button>
</mat-card>
