import { Component, OnInit } from '@angular/core';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { Email } from '../shared/email.model';
import { NotificationsService } from 'angular2-notifications';
import { DatePipe } from '@angular/common';
import { Storerep } from '../shared/storerep.model';
import { FormControl, Validators } from '@angular/forms';
import { Reperibilita } from '../shared/reperibilita.model';
import { MatTableDataSource } from '@angular/material/table';
import { PeriodicElement } from '../settings/settings.component';
const dateFormat = require('dateformat');
// tslint:disable-next-line:class-name

export interface dati_json {
  year: number;
  month: number;
  day: number;
}
@Component({
  // tslint:disable:indent
  // tslint:disable:variable-name
  // tslint:disable:triple-equals
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  providers: [DatePipe],
})
export class PreviewComponent implements OnInit {
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  responce: string;
  wba: any;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  sendcheck: any = '0';
  passwordFormControl = new FormControl('', [Validators.required]);
  oggetto_email: any;
  storerep: Storerep = new Storerep();
  reperibilita: Reperibilita = new Reperibilita();
  dataSource2 = new MatTableDataSource<PeriodicElement>();
  dataSource1 = new MatTableDataSource<PeriodicElement>();
  tableColumns2: string[] = ['email'];
  tableColumns1: string[] = ['destinatari'];
  nome: any;
  cognome: any;
  corpo_email: any;
  email: Email = new Email();
  risposta_availability: any[] = [];
  request: any;
  day: any;
  hours: any;
  company: any;
  entry_time: any;
  reason: any;
  checkfilevariable: any;
  destinataritable: any[] = [];
  desttable: [];
  destinatari: any;
  check: any = [];
  destinatari_conoscenza: any = '';
  formattedDate: any;
  exit_time: any;
  giorni_di_reperibilita: any;
  destxml: any = '';
  return_time: any;
  lenghtfordestinatari: any;
  concordato: any;
  to_day: any;
  timesheet: any;
  attivita: any;
  minuti: any = '';
  ack: any = '0';
  temp: any;
  tiporichiesta: any;
  ruolo: any;
  tempdestinatari: any;
  firma: any;
  wait: any = '0';

  custom_reason: any;
  resource_name: any;
  resource_email: any;
  temp5: string;
  giornitemp: number;
  disableinvio: boolean;
  emailwba = '';
  temp1: string;
  emailweb: any;
  constructor(
    private datePipe: DatePipe,
    public _auth: AuthService,
    public _storage: MyLocalStorageService,
    private _router: Router,
    private _service: NotificationsService,
  ) { }
  // tslint:disable:align
  async ngOnInit() {
    this.temp1 = this._storage.getItem('email_certificati');
    this.temp5 = this._storage.getItem('email')
    this.wba = this._storage.getItem('customer_mail').substring(this._storage.getItem('customer_mail').lastIndexOf('\@') + 1);
    await this.loadclientemail();
    this.loaddestinatari();
    this.tiporichiesta = localStorage.getItem('tiporichiesta').replace(/_/g, ' ');
    this.company = localStorage.getItem('company');
    this.to_day = this._storage.getItem('data_al');
    this.request = this._storage.getItem('tiporichiesta');
    this.exit_time = this._storage.getItem('orario');
    this.return_time = this._storage.getItem('orario_ingresso');
    this.concordato = this._storage.getItem('concordato');
    this.day = this._storage.getItem('data');
    this.hours = this._storage.getItem('ora');
    this.reason = this._storage.getItem('motivo');
    this.destinatari = this._storage.getItem('destinatari') + ' ; ';
    this.tempdestinatari = this.destinatari;
    this._storage.setItem('tempdestinatari', this.tempdestinatari);
    this.timesheet = this._storage.getItem('timesheet');
    this.attivita = this._storage.getItem('attivita');
    this.minuti = this._storage.getItem('minuti');
    this.ruolo = this._storage.getItem('ruolo');
    this.daysavailability();
    this._storage.setItem('giornidiavailability', this.giorni_di_reperibilita);
    if (this.ruolo == undefined) {
      this._service.error('Error', 'Please, add your role!!!', {
        position: ['bottom', 'center'],
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
      this._router.navigate(['/settings'], { skipLocationChange: true });
    }
    this.resource_name = this._storage.getItem('resourcename');
    if (this.resource_name === 'vuoto') {
      this.destinatari = this._storage.getItem('destinatari') + ';' + localStorage.getItem('email_certificati');
      this.tempdestinatari = this.destinatari.replace(',', ';');
      this._storage.setItem('tempdestinatari', this.tempdestinatari);
      this.nome = this._storage.getItem('nome')
      this.cognome = this._storage.getItem('cognome')
    } else {
      this.temp5 = this._storage.getItem('resourceemail');
      this.destinatari =
        this._storage.getItem('destinatarifor') + ' ; ' + localStorage.getItem('email_certificati');
      this.tempdestinatari = this.destinatari.replace(',', ' ; ');
      this._storage.setItem('tempdestinatari', this.tempdestinatari);
      this.nome = this._storage.getItem('nomeresource')
      this.cognome = this._storage.getItem('cognomeresource')
    }
    //console.log('destinatari - ' + this.destinatari);
    this.tiporichiesta_email();

  }
  tiporichiesta_email() {
    if (localStorage.getItem('customer') !== '1' && localStorage.getItem('customer') !== '8') {
      if (this._storage.getItem('resourcename') !== 'vuoto') {
        this.nome = this._storage.getItem('nomeresource');
        this.cognome = this._storage.getItem('cognomeresource');
        this.destinatari_conoscenza = this.emailwba;
        this.firma =
          '<font face="Calibri" color="#002060">Regards,<br><b>' +
          this.nome +
          ' ' +
          this.cognome +
          '</b></font><br><font face="Calibri" color="#002060"> ' +
          this.ruolo +
          // tslint:disable-next-line:max-line-length
          ', Pharmacy and Healthcare Product Engineering - Tecnology Solution</font><br><br><font face="Calibri" color="#002060"><b>Walgreens Boots Alliance</b> │ Via Vicinale S.Maria del Pianto - Centro Polifunzionale - Torre 5 - Piano 8, Napoli(Italy) 80143</font><br><font face="Calibri" color="#FFFFFF"><p>[]</p></font>';

      } else {
        this.destinatari_conoscenza = this.emailwba;
        this.firma =
          '<p hidden>Easyjob</p><font face="Calibri" color="#002060">Regards,<br><b>' +
          this.nome +
          ' ' +
          this.cognome +
          '</b></font><br><font face="Calibri" color="#002060"><b> ' +
          this.ruolo +
          // tslint:disable-next-line:max-line-length
          ',<br>' + this._storage.getItem('companyfullname') + '</b></font><br><br><font face="Calibri" color="#002060"><b>' + this._storage.getItem('indirizzocompany') + '</font><br><font face="Calibri" color="#FFFFFF"><p>[]</p></font>';
      }
    } else {
      this.destinatari_conoscenza = this._storage.getItem('email_certificati');
      this.firma =
        '<font face="Calibri" color="#002060">Regards,<br><b>' +
        this.nome +
        ' ' +
        this.cognome +
        '</b></font><br><font face="Calibri" color="#002060">' +
        this.ruolo +
        // tslint:disable-next-line:max-line-length
        ', Pharmacy and Healthcare Product Engineering - Tecnology Solution </b></font><br><br><font face="Calibri" color="#002060"><b>Walgreens Boots Alliance</b> │ Via Vicinale S.Maria del Pianto - Centro Polifunzionale - Torre 5 - Piano 8, Napoli(Italy) 80143</font><br><font face="Calibri" color="#FFFFFF"><p>[]</p></font>';
    }
    if (this.request == 'LATE_ENTRY') {
      if (this.reason == 'familiar') {
        this.custom_reason = '</b><p>due to some family reasons. <b>';
      }
      if (this.reason == 'personal') {
        this.custom_reason = '</b><p>due to some ' + this.reason + ' reasons. <b>';
      }
      if (this.reason == 'illness') {
        this.custom_reason = '</b><p>due to ' + this.reason + '. <b>';
      }
      this.day = this.datePipe.transform(this.day, 'dd/MM/yy');
      if (this.resource_name == 'vuoto') {
        this.oggetto_email = 'HOURS OFF ' + this.day + ' - (' + this.hours + ')';
      } else {
        this.oggetto_email = 'HOURS OFF ' + this.day + ' - (' + this.hours + ')';
      }

      this.corpo_email =
        '<font face="Calibri"><p>Hi,</p><p>the present to let you know that I entered at hours <b>' +
        this.return_time +
        '</b>, on date <b>' +
        this.day +
        '</b>, for a total of <b> ' +
        this.hours +
        'h</b> of permission,' +
        this.custom_reason +
        '</b></p></font>' +
        this.firma;
    } else if (this.request == 'PERMISSION') {
      this.day = this.datePipe.transform(this.day, 'dd/MM/yy');
      if (this.resource_name == 'vuoto') {
        this.oggetto_email = 'HOURS OFF ' + this.day + ' - (' + this.hours + ')';
      } else {
        this.oggetto_email = 'HOURS OFF ' + this.day + ' - (' + this.hours + ') ';
      }
      this.corpo_email =
        '<font face="Calibri"><p>Hi,</p><p>the present to inform you that I will leave at the time <b>' +
        this.return_time +
        '</b> and I\'ll be back at the time <b> ' +
        this.exit_time +
        '</b>, on date <b>' +
        this.day +
        '</b>, for a total of <b> ' +
        this.hours +
        'h</b> of permission. <p>Permission agreed with <b>' +
        this.concordato +
        '</b>.  </p></font>' +
        this.firma;
    } else if (this.request == 'PERMISSION_ENTRY') {
      this.day = this.datePipe.transform(this.day, 'dd/MM/yy');
      if (this.resource_name == 'vuoto') {
        this.oggetto_email = 'HOURS OFF ' + this.day + ' - (' + this.hours + ')';
      } else {
        this.oggetto_email = 'HOURS OFF ' + this.day + ' - (' + this.hours + ') ';
      }
      this.corpo_email =
        '<font face="Calibri"><p>Hi,</p><p>the present to inform you that I will enter at the time <b>' +
        this.return_time +
        '</b>, on date <b>' +
        this.day +
        '</b>, for a total of <b> ' +
        this.hours +
        'h</b> of permission. <p>Permission agreed with <b>' +
        this.concordato +
        '</b>.  </p></font>' +
        this.firma;
    } else if (this.request == 'PERMISSION_EXIT') {
      this.day = this.datePipe.transform(this.day, 'dd/MM/yy');
      if (this.resource_name == 'vuoto') {
        this.oggetto_email = 'HOURS OFF ' + this.day + ' - (' + this.hours + ')';
      } else {
        this.oggetto_email = 'HOURS OFF ' + this.day + ' - (' + this.hours + ') ';
      }
      this.corpo_email =
        '<font face="Calibri"><p>Hi,</p><p>the present to inform you that I will leave at the time <b>' +
        this.exit_time +
        '</b>, on date <b>' +
        this.day +
        '</b>, for a total of <b> ' +
        this.hours +
        'h</b> of permission. <p>Permission agreed with <b>' +
        this.concordato +
        '</b>.  </p></font>' +
        this.firma;
    } else if (this.request == 'VACANTION_1(DAY)') {
      this.day = this.datePipe.transform(this.day, 'dd/MM/yy');
      if (this.resource_name == 'vuoto') {
        this.oggetto_email = 'HOLIDAY OF ' + this.day;
      } else {
        this.oggetto_email = 'HOLIDAY OF ' + this.day;
      }
      this.corpo_email =
        '<font face="Calibri"><p>Hi,</p><p>The present inform you that I will take a day off on the date <b>' +
        this.day +
        '</b>.<p>Absence agreed with <b> ' +
        this.concordato +
        '</b>.  </p></font>' +
        this.firma;
    } else if (this.request == 'VACANTIONS_+(DAYS)') {
      this.day = this.datePipe.transform(this.day, 'dd/MM/yy');
      this.to_day = this.datePipe.transform(this.to_day, 'dd/MM/yy');
      if (this.resource_name == 'vuoto') {
        this.oggetto_email = 'HOLIDAY FROM ' + this.day + ' TO ' + this.to_day;
      } else {
        this.oggetto_email = 'HOLIDAY FROM ' + this.day + ' TO ' + this.to_day;
      }
      this.corpo_email =
        '<font face="Calibri"><p>Hi,</p><p>the present to inform you that I will be on vacation from <b>' +
        this.day +
        '</b> to <b>' +
        this.to_day +
        '</b>.<p>Absence agreed with <b> ' +
        this.concordato +
        '</b>.  </p></font>' +
        this.firma;
    } else if (this.request == 'ABSENCE_1(DAY)') {
      if (this.reason == 'familiar') {
        this.custom_reason = '</b><p>due to some family reasons.<b>';
      }
      if (this.reason == 'personal') {
        this.custom_reason = '</b><p>due to some ' + this.reason + ' reasons.<b>';
      }
      if (this.reason == 'illness') {
        this.custom_reason = '</b><p>due to ' + this.reason + '.<b>';
      }
      this.day = this.datePipe.transform(this.day, 'dd/MM/yy');
      if (this.resource_name == 'vuoto') {
        this.oggetto_email = 'ABSENCE OF ' + this.day;
      } else {
        this.oggetto_email = 'ABSENCE OF ' + this.day;
      }
      this.corpo_email =
        '<font face="Calibri"><p>Hi,</p><p>the present to inform you that I will carry out a day of absence on the date <b>' +
        this.day + ',' +
        this.custom_reason +
        '</b>  </p></font>' +
        this.firma;
    } else if (this.request == 'ABSENCES_+(DAYS)') {
      if (this.reason == 'familiar') {
        this.custom_reason = '</b><p>due to some family reasons.<b>';
      }
      if (this.reason == 'personal') {
        this.custom_reason = '</b><p>due to some ' + this.reason + ' reasons.<b>';
      }
      if (this.reason == 'illness') {
        this.custom_reason = '</b><p> due to ' + this.reason + '.<b>';
      }
      this.day = this.datePipe.transform(this.day, 'dd/MM/yy');
      this.to_day = this.datePipe.transform(this.to_day, 'dd/MM/yy');
      if (this.resource_name == 'vuoto') {
        this.oggetto_email = 'ABSENCE FROM ' + this.day + ' TO ' + this.to_day;
      } else {
        this.oggetto_email = 'ABSENCE FROM ' + this.day + ' TO ' + this.to_day;
      }
      this.corpo_email =
        '<font face="Calibri"><p>Hi,</p><p>the present to inform you that I will be absent from <b>' +
        this.day +
        '</b> to <b>' +
        this.to_day + ',' +
        this.custom_reason +
        '</b>  </p></font>' +
        this.firma;
    } else if (this.request == 'OVERTIME') {
      if (localStorage.getItem('customer') !== '1') {
        this.tempdestinatari = this.emailwba

      } else {
        this.tempdestinatari = this.destinatari;
      }
      this.email.company = 'staordinario';
      console.log(this.email);
      this.day = this.datePipe.transform(this.day, 'dd/MM/yy');
      this.to_day = this.datePipe.transform(this.to_day, 'dd/MM/yy');
      (this.oggetto_email = 'OVERTIME OF ' + this.day + ' - (' + this.hours + ')'), (this.corpo_email =
        '<font face="Calibri"><p>Hi,</p><p>the present mail to inform you that on <b>' +
        this.day +
        '</b> i did <b>' +
        this.hours +
        'h</b> of overtime on the following activity:</p><p>	-  ' +
        this.attivita +
        '  </p><p><b>Project: </b>' +
        this.timesheet +
        '</b>.  </p></font>' +
        this.firma);
    } else if (this.request == 'PLAN_VACANTION_1(DAY)') {
      this.day = this.datePipe.transform(this.day, 'dd/MM/yy');
      this.to_day = this.datePipe.transform(this.to_day, 'dd/MM/yy');
      (this.oggetto_email = 'HOLIDAY OF ' + this.day), (this.corpo_email =
        '<font face="Calibri"><p>Hi,</p><p>The present inform you that I will take a day off on the date <b>' +
        this.day +
        '</b>.<p><i>As shared in the project holiday plan. </i></p></font>' +
        this.firma);
    } else if (this.request == 'PLAN_VACANTIONS_+(DAYS)') {
      this.day = this.datePipe.transform(this.day, 'dd/MM/yy');
      this.to_day = this.datePipe.transform(this.to_day, 'dd/MM/yy');
      (this.oggetto_email = 'HOLIDAY FROM ' + this.day + ' TO ' + this.to_day), (this.corpo_email =
        '<font face="Calibri"><p>Hi,</p><p>the present to inform you that I will be on vacation from <b>' +
        this.day +
        '</b> to <b>' +
        this.to_day +
        '</b>.<p><i>As shared in the project holiday plan. </i></p></font>' +
        this.firma);
    } else if (this.request == 'AVAILABILITY') {
      this.day = this.datePipe.transform(this.day, 'dd/MM/yy');
      this.to_day = this.datePipe.transform(this.to_day, 'dd/MM/yy');
      (this.oggetto_email = 'AVAILABILITY FROM ' + this.day + ' TO ' + this.to_day), (this.corpo_email =
        '<font face="Calibri"><p>Hi,</p><p>the present mail to inform you that from <b>' +
        this.day +
        '</b> to <b>' +
        this.to_day +
        '</b> I was available for the following activity:<br><li>' + this.attivita + '</li><br><br><p>Project:<b> ' +
        this.timesheet + '.</b></p><br><br>Availability agreed with <b>' + this.concordato + '</b>.</font><br><br>' +
        this.firma);
    }
  }
  loaddestinatari() {
    if (localStorage.getItem('resourcename') == 'vuoto') {
      this.email.id_utente = localStorage.getItem('id');
      this.resource_email = localStorage.getItem('email');
    } else {
      this.email.id_utente = localStorage.getItem('resource_id');
      this.resource_email = localStorage.getItem('resourceemail');
    }
    this.resource_name = localStorage.getItem('resourcename');
    this._auth.loaddestinatari(this.email).subscribe(
      res => {
        if (res.length == undefined) {
          this._service.error('Error', 'Please, add at least one project mail recipient!!!', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this._router.navigate(['/settings'], { skipLocationChange: true });
        }

        if (this.resource_name == 'vuoto') {
          res.push({ destinatari: this.temp1 });
          this.dataSource1 = res;
          this.destinataritable = res;
          this._storage.setItem('lunghezza', res.length);
          this.lenghtfordestinatari = res.length;
          this._storage.setItem('desttable', res);
        } else {
          res.push({ destinatari: this.temp1 });
          this.dataSource1 = res;
          this.destinataritable = res;
          this._storage.setItem('lunghezza', res.length);
          this.lenghtfordestinatari = res.length;
          this._storage.setItem('desttable', res);
        }
        this._storage.getItem('tempdestinatari');
        this.destxml = this._storage.getItem('tempdestinatari');
        this.destxml = this.destxml.replace(',', ';');
        this._storage.setItem('destxml', this.destxml);

      },
      err => console.log(err)
    );
  }

  sleep(milliseconds) {
    const start = new Date().getTime();
    for (let i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }

  async invioemailnew() {
    this.email.corpo_email_send = this.corpo_email;
    this.email.subject_email_send = this.oggetto_email;
    this.temp5 = this._storage.getItem('customer_mail').substring(this._storage.getItem('customer_mail').lastIndexOf('\@') + 1);
    this.email.destarray = this._storage.getItem('destxml');
    this.email.lunghezza = this._storage.getItem('lunghezza');
    this.email.email = this._storage.getItem('email')
    this.email.resource_name = this._storage.getItem('resourcename');
    if (this._storage.getItem('resourcename') !== 'vuoto') {
      this._storage.setItem('cognome', this._storage.getItem('resourcename').substring(0, this._storage.getItem('resourcename').lastIndexOf(' ')))
      this._storage.setItem('nome', this._storage.getItem('resourcename').substring(this._storage.getItem('resourcename').lastIndexOf(' ') + 1))
      this.email.email = this._storage.getItem('resourceemail')
    }
    this.email.nome = this._storage.getItem('nome');
    this.email.cognome = this._storage.getItem('cognome');
    this.email.resource_email = this._storage.getItem('resourceemail');
    await this._auth.invioemail(this.email).subscribe(
      res => {
        if (res.status === 'SUCCESS') {
          // tslint:disable-next-line:no-unused-expression
          this.wait == '0';
          this._router.navigate(['/welcome'], { skipLocationChange: true });
          if (this._storage.getItem('tiporichiesta') == 'AVAILABILITY') {
            this.store_comunicazioneavailability();
            this._service.success('Success', 'An Email was sent', {
              position: ['bottom', 'center'],
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          } else {
            //this.store_comunicazione();
            if (this._storage.getItem('tiporichiesta') == 'ABSENCES_+(DAYS)' || (this._storage.getItem('tiporichiesta') == 'PLAN_VACANTIONS_+(DAYS)') || (this._storage.getItem('tiporichiesta') == 'VACANTIONS_+(DAYS)')) {
              this.store_summary_moredays();
              this._service.success('Success', 'An Email was sent', {
                position: ['bottom', 'center'],
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: true,
              });
            } else {
              this.store_summary();
              this._service.success('Success', 'An Email was sent', {
                position: ['bottom', 'center'],
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: true,
              });
            }
          }
        } else {
          // tslint:disable:no-unused-expression
          this.wait == '0';
          this._service.error('Error', 'Contact Administrator', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this._router.navigate(['/preview'], { skipLocationChange: true });
        }
      },
      err => console.log(err)
    );
  }
  async invioemail() {
    this.email.corpo_email_send = this.corpo_email;
    this.email.subject_email_send = this.oggetto_email;
    this.temp5 = this._storage.getItem('customer_mail').substring(this._storage.getItem('customer_mail').lastIndexOf('\@') + 1);
    if (this._storage.getItem('customer_mail').substring(this._storage.getItem('customer_mail').lastIndexOf('\@') + 1) == 'psen.wbaoneit.com') {
      this.email.username = this._storage.getItem('username');
      this.email.wbaoneit = 'false';
    } else if (this._storage.getItem('customer_mail').substring(this._storage.getItem('customer_mail').lastIndexOf('\@') + 1) == 'alliance-heallthcare.net') {
      this.email.username = this._storage.getItem('username');
      this.email.wbaoneit = 'false';
    } else if (this._storage.getItem('customer_mail').substring(this._storage.getItem('customer_mail').lastIndexOf('\@') + 1) == 'wbaoneit.com') {
      this.email.username = this._storage.getItem('customer_mail');
      this.email.wbaoneit = 'true';
    }
    this.email.destarray = this._storage.getItem('destxml');
    this.email.lunghezza = this._storage.getItem('lunghezza');

    this.email.resource_name = this._storage.getItem('resourcename');
    this.email.resource_email = this._storage.getItem('resourceemail');
    await this._auth.invioemail(this.email).subscribe(
      res => {
        if (res.risposta[0].indexOf('NoError') != -1) {
          // tslint:disable-next-line:no-unused-expression
          this.wait == '0';
          this._router.navigate(['/welcome'], { skipLocationChange: true });
          if (this._storage.getItem('tiporichiesta') == 'AVAILABILITY') {
            this.store_comunicazioneavailability();
            this._service.success('Success', 'An Email was sent', {
              position: ['bottom', 'center'],
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          } else {
            //this.store_comunicazione();
            if (this._storage.getItem('tiporichiesta') == 'ABSENCES_+(DAYS)' || (this._storage.getItem('tiporichiesta') == 'PLAN_VACANTIONS_+(DAYS)') || (this._storage.getItem('tiporichiesta') == 'VACANTIONS_+(DAYS)')) {
              this.store_summary_moredays();
              this._service.success('Success', 'An Email was sent', {
                position: ['bottom', 'center'],
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: true,
              });
            } else {
              this.store_summary();
              this._service.success('Success', 'An Email was sent', {
                position: ['bottom', 'center'],
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: true,
              });
            }
          }
        } else {
          // tslint:disable:no-unused-expression
          if (res.risposta[0] === '0') {
            this.wait == '0';
            this._service.error('Error', 'Please check your PASSWORD!', {
              position: ['bottom', 'center'],
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          } else {
            this.wait == '0';
            this._service.error('Error', res.risposta[0], {
              position: ['bottom', 'center'],
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
          this._router.navigate(['/preview'], { skipLocationChange: true });
        }
      },
      err => console.log(err)
    );
  }
  store_comunicazione() {
    this.email.concordato = this.concordato;
    this.email.data = this.day;
    this.email.ore = this.hours;
    this.email.email = this._storage.getItem('email');
    this.email.orario_ingresso = this._storage.getItem('orario');
    this.email.orario_uscita = this._storage.getItem('orario_ingresso');
    this.email.motivo = this.reason;
    this.email.destinatari = this._storage.getItem('destinatari');
    this.email.linguaemail = this._storage.getItem('lingua_email');
    this.email.tiporichiesta = this._storage.getItem('tiporichiesta');
    this.email.nome = this._storage.getItem('nome');
    this.email.cognome = this._storage.getItem('cognome').replace(/'/g, '');
    if (this._storage.getItem('resourcename') == 'vuoto') {
      this.email.id_utente = this._storage.getItem('id');
    } else {
      this.email.id_utente = this._storage.getItem('resource_id');
    }
    this._auth.salvacomunicazioni(this.email).subscribe(
      res => {
        this._storage.removeItem('attivita');
        this._storage.removeItem('concordato');
      },
      err => console.log(err)
    );
  }
  store_summary() {
    const tempdata = new Date(this._storage.getItem('data'));
    this.email.datetosend = dateFormat(tempdata, 'yyyy-mm-dd HH:MM');
    this.email.mese = dateFormat(tempdata, 'mm');
    this.email.anno = dateFormat(tempdata, 'yyyy');
    this.email.concordato = this.concordato;
    this.email.data = dateFormat(tempdata, 'yyyy-mm-dd');
    this.email.ore = this.hours;
    this.email.email = this._storage.getItem('email');
    this.email.orario_ingresso = this._storage.getItem('orario_ingresso');
    this.email.orario_uscita = this._storage.getItem('orario');
    this.email.motivo = this.reason;
    this.email.destinatari = this._storage.getItem('destinatari');
    this.email.linguaemail = this._storage.getItem('lingua_email');
    this.email.tiporichiesta = this._storage.getItem('tiporichiesta');
    this.email.nome = this._storage.getItem('nome');
    this.email.cognome = this._storage.getItem('cognome').replace(/'/g, '');
    switch (this._storage.getItem('tiporichiesta')) {
      case 'LATE_ENTRY':
        this.email.specifica = 'Late entry'
        break;
      case 'PERMISSION':
        this.email.specifica = 'Hours off'
        break;
      case 'PERMISSION_ENTRY':
        this.email.specifica = 'Hours off'
        break;
      case 'PERMISSION_EXIT':
        this.email.specifica = 'Hours off'
        break;
      case 'ABSENCE':
        this.email.specifica = 'Absence'
        break;
      case 'ABSENCE_1(DAY)':
        this.email.specifica = 'Absence'
        break;
      case 'ABSENCES_+(DAYS)':
        this.email.specifica = 'Absence'
        break;
      case 'VACANTION':
        this.email.specifica = 'Holiday'
        break;
      case 'PLAN_VACANTION':
        this.email.specifica = 'Holiday'
        break;
      case 'VACANTION_1(DAY)':
        this.email.specifica = 'Holiday'
        break;
      case 'VACANTIONS_+(DAYS)':
        this.email.specifica = 'Holiday'
        break;
      case 'PLAN_VACANTION_1(DAY)':
        this.email.specifica = 'Holiday'
        break;
      case 'PLAN_VACANTIONS_+(DAYS)':
        this.email.specifica = 'Holiday'
        break;
      case 'OVERTIME':
        this.email.specifica = 'Overtime'
        break;
    }

    if (this._storage.getItem('resourcename') == 'vuoto') {
      this.email.id_utente = this._storage.getItem('id');
    } else {
      this.email.id_utente = this._storage.getItem('resource_id');
    }
    this._auth.salvasummary(this.email).subscribe(
      res => {
        this._storage.removeItem('attivita');
        this._storage.removeItem('concordato');
      },
      err => console.log(err)
    );
  }
  async store_summary_moredays() {
    this.giornitemp = Number(this._storage.getItem('giornidiavailability'));
    const tempdata = new Date(this._storage.getItem('data'));
    const tempdatetosend = new Date(this._storage.getItem('data'));
    let newdate;
    for (let index = 0; index < this.giornitemp; index++) {
      this.email.concordato = this.concordato;
      if (index == 0) {
        this.email.data = this.day;
        newdate = tempdata;
        this.email.mese = dateFormat(tempdata, 'mm');
        this.email.anno = dateFormat(tempdata, 'yyyy');
        this.email.giorno = dateFormat(tempdata, 'dd');
        this.email.dataavailability = dateFormat(tempdata, 'yyyy-mm-dd');
        this.email.datetosend = dateFormat(tempdatetosend, 'yyyy-mm-dd HH:MM:ss');

      } else {
        newdate = new Date(newdate.getFullYear(), newdate.getMonth(), newdate.getDate() + 1);
        this.email.mese = dateFormat(newdate, 'mm');
        this.email.anno = dateFormat(newdate, 'yyyy');
        this.email.giorno = dateFormat(newdate, 'dd');
        this.email.data = dateFormat(newdate, 'yyyy-mm-dd');
        this.email.dataavailability = dateFormat(newdate, 'yyyy-mm-dd');
        this.email.datetosend = dateFormat(newdate, 'yyyy-mm-dd HH:MM:ss');
      }
      this.email.ore = this.hours;
      this.email.email = this._storage.getItem('email');
      this.email.orario_ingresso = this._storage.getItem('orario');
      this.email.orario_uscita = this._storage.getItem('orario_ingresso');
      this.email.motivo = this.reason;
      this.email.destinatari = this._storage.getItem('destinatari');
      this.email.linguaemail = this._storage.getItem('lingua_email');
      this.email.tiporichiesta = this._storage.getItem('tiporichiesta');
      this.email.nome = this._storage.getItem('nome');
      this.email.cognome = this._storage.getItem('cognome').replace(/\'/, "\\'");
      switch (this._storage.getItem('tiporichiesta')) {
        case 'ABSENCES_+(DAYS)':
          this.email.specifica = 'Ferie'
          break;
        case 'VACANTIONS_+(DAYS)':
          this.email.specifica = 'Ferie'
          break;
        case 'PLAN_VACANTION_1(DAY)':
          this.email.specifica = 'Ferie'
          break;
        case 'PLAN_VACANTIONS_+(DAYS)':
          this.email.specifica = 'Ferie'
          break;
      }
      const festivo = dateFormat(this.email.datetosend, 'dddd');
      if (this._storage.getItem('resourcename') == 'vuoto') {
        this.email.id_utente = this._storage.getItem('id');
      } else {
        this.email.id_utente = this._storage.getItem('resource_id');
      }
      if (festivo === 'Saturday' || festivo === 'Sunday'
        || this.email.dataavailability === this.email.anno + '-01-01'
        || this.email.dataavailability === this.email.anno + '-01-06'
        || this.email.dataavailability === '2021-04-05'
        || this.email.dataavailability === this.email.anno + '-04-25'
        || this.email.dataavailability === this.email.anno + '-05-01'
        || this.email.dataavailability === this.email.anno + '-06-02'
        || this.email.dataavailability === this.email.anno + '-08-15'
        || this.email.dataavailability === this.email.anno + '-11-01'
        || this.email.dataavailability === this.email.anno + '-12-08'
        || this.email.dataavailability === this.email.anno + '-12-25'
        || this.email.dataavailability === this.email.anno + '-12-26'
      ) {
        console.log('festa');
      } else {
        const res = await this._auth.salvacomunicazioni(this.email).toPromise();
        if (res.risposta == 'Success') {
          this.risposta_availability.push('OK');

          await this.storedbsummarymoredays(this.email);
        } else {
          this.risposta_availability.push('ERROR');
        }
      }
    }
    const temprisposta = this.risposta_availability.filter(x => x === 'ERROR');
    if (temprisposta.length > 0) {
      this._service.error('Errore', 'Ops... somethings gone wrong, No record inserted', {
        position: ['bottom', 'center'],
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
    } else {
      const records = this.risposta_availability.length;
      this._service.success('Success', 'Inserted ' + records + ' records', {
        position: ['bottom', 'center'],
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
    }
  }
  async store_comunicazioneavailability() {
    this.giornitemp = Number(this._storage.getItem('giornidiavailability'));
    const tempdata = new Date(this._storage.getItem('data'));
    const tempdatetosend = Date.now();
    let newdate;

    for (let index = 0; index < this.giornitemp; index++) {
      this.email.concordato = this.concordato;
      if (index == 0) {
        this.email.data = this.day;
        newdate = tempdata;
        this.email.mese = dateFormat(tempdata, 'mm');
        this.email.anno = dateFormat(tempdata, 'yyyy');
        this.email.giorno = dateFormat(tempdata, 'dd');
        this.email.dataavailability = dateFormat(tempdata, 'yyyy-mm-dd');
        this.email.datetosend = dateFormat(tempdatetosend, 'yyyy-mm-dd HH:MM:ss');

      } else {
        newdate = new Date(newdate.getFullYear(), newdate.getMonth(), newdate.getDate() + 1);
        this.email.mese = dateFormat(newdate, 'mm');
        this.email.anno = dateFormat(newdate, 'yyyy');
        this.email.giorno = dateFormat(newdate, 'dd');
        this.email.data = dateFormat(newdate, 'yyyy-mm-dd');
        this.email.dataavailability = dateFormat(newdate, 'yyyy-mm-dd');
      }
      this.email.ore = this.hours;
      this.email.email = this._storage.getItem('email');
      this.email.orario_ingresso = this._storage.getItem('orario');
      this.email.orario_uscita = this._storage.getItem('orario_ingresso');
      this.email.motivo = this.reason;
      this.email.destinatari = this._storage.getItem('destinatari');
      this.email.linguaemail = this._storage.getItem('lingua_email');
      this.email.tiporichiesta = this._storage.getItem('tiporichiesta');
      this.email.nome = this._storage.getItem('nome');
      this.email.cognome = this._storage.getItem('cognome').replace(/\'/, "\\'");
      if (this._storage.getItem('resourcename') == 'vuoto') {
        this.email.id_utente = this._storage.getItem('id');
      } else {
        this.email.id_utente = this._storage.getItem('resource_id');
      }
      const res = await this._auth.salvacomunicazioni(this.email).toPromise();
      if (res.risposta == 'Success') {
        this.risposta_availability.push('OK');
        await this.storedbavailability(this.email);
      } else {
        this.risposta_availability.push('ERROR');
      }
    }
    const temprisposta = this.risposta_availability.filter(x => x === 'ERROR');
    if (temprisposta.length > 0) {
      this._service.error('Errore', 'Ops... somethings gone wrong, No record inserted', {
        position: ['bottom', 'center'],
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
    } else {
      this._storage.removeItem('attivita');
      this._storage.removeItem('concordato');
      this._service.success('Success', 'Inserted ' + this.giornitemp + ' of availability', {
        position: ['bottom', 'center'],
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
      this._router.navigate(['/updaterep'], { skipLocationChange: true });
    }
  }
  async storedbavailability(data) {
    const res = await this._auth.salvaavailability(data).toPromise();
    return res;
  }
  async storedbsummarymoredays(data) {
    const res = await this._auth.salvasummary(data).toPromise();
    return res;
  }

  insertpwd() {
    this.sendcheck = '1';
    this.ack = '0';

  }

  cancel() {
    this.ack = '0';
    this.sendcheck = '0';
  }
  invioemailinterni() {
    this.email.corpo_email_send = this.corpo_email;
    this.email.subject_email_send = this.oggetto_email;
    this.email.username = this._storage.getItem('email');
    this.email.destarray = this._storage.getItem('tempdestinatari');
    this.email.resource_name = this._storage.getItem('resourcename');
    this.email.resource_email = this._storage.getItem('resourceemail');
    if (this._storage.getItem('resourcename') !== 'vuoto') {
      this._storage.setItem('cognome', this._storage.getItem('resourcename').substring(0, this._storage.getItem('resourcename').lastIndexOf(' ')))
      this._storage.setItem('nome', this._storage.getItem('resourcename').substring(this._storage.getItem('resourcename').lastIndexOf(' ') + 1))
    }
    this.email.nome = this._storage.getItem('nome');
    this.email.cognome = this._storage.getItem('cognome');
    this._auth.invioemailinterni(this.email).subscribe(
      res => {
        if (res.status == 'SUCCESS') {

          this.wait == '0';
          this._router.navigate(['/welcome'], { skipLocationChange: true });
          this._service.success('Success', 'An Email of was sent', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this.store_comunicazione();
        } else {
          this.wait == '0';
          this._router.navigate(['/preview'], { skipLocationChange: true });
          this._service.error('Errore', 'Ops... somethings gone wrong, I believe your password is wrong', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });

        }
      },
      err => console.log(err)
    );
  }
  async loadclientemail() {
    const data = { "cust_id": localStorage.getItem('customer') }
    let desttemp = '';
    const res = await this._auth.loadclientemail(data).toPromise();
    if (res.risposta === 'error') {
      this.email.clientemail = null
    } else {
      this.dataSource2 = res;
      res.forEach(element => {
        this.emailwba = this.emailwba + element.email + ';';
        desttemp = desttemp + element.email + ';';
      });
      this.email.clientemail = desttemp;
    }
  }
  daysavailability() {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    // tslint:disable: max-line-length
    const annoda = Number(this.datePipe.transform(this.day, 'yyyy'));
    const meseda = Number(this.datePipe.transform(this.day, 'MM'));
    const giornoda = Number(this.datePipe.transform(this.day, 'dd'));
    const annoto = Number(this.datePipe.transform(this.to_day, 'yyyy'));
    const meseto = Number(this.datePipe.transform(this.to_day, 'MM'));
    const giornoto = Number(this.datePipe.transform(this.to_day, 'dd'));
    const utc1 = Date.UTC(annoda, meseda, giornoda);
    const utc2 = Date.UTC(annoto, meseto, giornoto);
    if (meseda !== meseto) {
      this.giorni_di_reperibilita = (Math.floor((utc2 - utc1) / MS_PER_DAY)) + 2;
    } else {
      this.giorni_di_reperibilita = (Math.floor((utc2 - utc1) / MS_PER_DAY)) + 1;
    }
  }
}
