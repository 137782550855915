import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UploadService } from '../upload.service';
import { forkJoin } from 'rxjs';
import { MatListModule } from '@angular/material/list';
@Component({
	selector: 'app-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
	@ViewChild('file', {static: true})
	file;

	public files: Set<File> = new Set();

	constructor(public dialogRef: MatDialogRef<DialogComponent>, public uploadService: UploadService) {}

	ngOnInit() {}

	progress;
	canBeClosed = true;
	primaryButtonText = 'Upload';
	showCancelButton = true;
	uploading = false;
	uploadSuccessful = false;

	onFilesAdded() {
		const files: { [key: string]: File } = this.file.nativeElement.files;
		console.log(files)
		for (let key in files) {
			if (!isNaN(parseInt(key))) {
				this.files.add(files[key]);
			}
		}
	}

	addFiles() {
		this.file.nativeElement.click();
	}

	closeDialog() {
		// if everything was uploaded already, just close the dialog
		if (this.uploadSuccessful) {
		
			return this.dialogRef.close();
		}

		// set the component state to "uploading"
		this.uploading = true;

		// start the upload and save the progress map
		this.progress = this.uploadService.upload(this.files);
		console.log(this.progress);
		for (const key in this.progress) {
			this.progress[key].progress.subscribe(val => console.log(val));
		}

		// convert the progress map into an array
		let allProgressObservables = [];
		for (let key in this.progress) {
			allProgressObservables.push(this.progress[key].progress);
		}

		// Adjust the state variables
		this.primaryButtonText = 'Close';

		// The dialog should not be closed while uploading
		this.canBeClosed = false;
		this.dialogRef.disableClose = true;

		// Hide the cancel-button
		this.showCancelButton = false;

		// When all progress-observables are completed...
		forkJoin(allProgressObservables).subscribe(end => {
			// ... the dialog can be closed again...
			this.canBeClosed = true;
			this.dialogRef.disableClose = false;

			// ... the upload was successful...
			this.uploadSuccessful = true;

			// ... and the component is no longer uploading
			this.uploading = false;
		});
	}
}
