<br>
<div style="text-align:center">
  <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
  <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<html>

<body>

  <div>

    <table *ngIf="sendcheck=='0'">
      <tr style="border-style: double;">
        <td style="border-style: double; background-color: lightgrey; text-align: center;">
          <b>TO</b>
        </td>
        <td>
          {{provider_email}}
        </td>
      </tr>
      <tr style="border-style: double;">
        <td style="border-style: double; background-color: lightgrey; text-align: center;">
          <b>CC</b>
        </td>
        <td>
          {{resource_email}}
        </td>
      </tr>
      <tr style="border-style: double; ">
        <td style="border-style: double; background-color: lightgrey; text-align: center;">
          <b>Subject</b>
        </td>
        <td>
          {{oggetto_email}}
        </td>
      </tr>
      <tr style="border-style: double;">
        <td colspan="2" style="border-style: double;">
          <div [innerHTML]="corpo_email"></div>
        </td>
      </tr>
    </table>
  </div>
  <br>
  <button style="margin:5px" *ngIf="sendcheck=='0'" mat-raised-button color="primary"(click)="back()" type="submit">Back<i class="material-icons right">cancel</i></button>
  <button style="margin:5px" *ngIf="sendcheck=='0'" mat-raised-button color="accent" (click)="invioemail()" type="submit">Send<i
      class="material-icons right">email</i></button>
  <br>
</body>

</html> 