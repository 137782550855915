import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { Login } from '../shared/login.model';
import { Setting } from '../shared/setting.model';
import { Email } from '../shared/email.model';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-primo-accesso',
  templateUrl: './primo-accesso.component.html',
  styleUrls: ['./primo-accesso.component.scss']
})
export class PrimoAccessoComponent implements OnInit {
  login: Login = new Login();
  email: Email = new Email();
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);
  id_utente: any;
  matcher = new MyErrorStateMatcher();
  setting: Setting = new Setting();
  constructor(public _auth: AuthService, private _router: Router, private _service: NotificationsService, public _storage: MyLocalStorageService) { }

  ngOnInit() {
  }
  // tslint:disable: indent
  firstlogin () {
		this.login.email = this.emailFormControl.value.toString();
		const suffix = this.login.email.substring( this.login.email.lastIndexOf('\@') + 1);
	/*	if ( suffix !== 'emminformatica.it') {
			if ( suffix !== 'dmgit.it') {
			this._service.error('Error', 'Please insert only email of your Company!', {
				position: ['bottom', 'center'],
				timeOut: 5000,
				showProgressBar: true,
				pauseOnHover: false,
				clickToClose: true,
			});
		}
		 this.emailFormControl = new FormControl('', [Validators.required, Validators.email]);
		 return;
		} */
  this._auth.firstlogin(this.login)
    .subscribe(
      res => {
        if (res.risposta === 'updatepwd') {
        this.login.nome = res.nome;
        this.login.cognome = res.cognome;
        this.login.id_utente = res.id;
        this._auth.updatepwd(this.login)
        .subscribe(
          res => {
            if (res.status === 'SUCCESS') {
              this._service.success('Success',
            'A password was sent by EMAIL', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
              this._router.navigate(['/login'], { skipLocationChange: true });
            }
          },
          err => console.log(err)
        );

        } else if (res.risposta == 'changepassword') {
          this._storage.setItem('email' , res.email);
          this._router.navigate(['/registrazione'], { skipLocationChange: true });
        } else if (res.risposta === 'Success') {
          this._storage.setItem('id', res.id);
          this._storage.setItem('token', res.token);
          this._storage.setItem('nome', res.nome);
          this._storage.setItem('cognome' , res.cognome);
          this._storage.setItem('email' , res.email);
          this._service.success('Success',
          'Login Avvenuto con successo.', {
            position: ['bottom', 'center'],
            timeOut: 1000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this._router.navigate(['/welcome'], { skipLocationChange: true });
        } else {
          this.email.email = this.login.email;
          this.email.subject_email_send = 'User ' + this.login.email + ' not inserted';
          this._auth.invioemailnuovoutente(this.email).subscribe(
            res => {
              if (res.status === 'SUCCESS') {
                this._service.error('Error', 'You are a NEW USER! You will be contacted ASAP.', {
                  position: ['bottom', 'center'],
                  timeOut: 5000,
                  showProgressBar: true,
                  pauseOnHover: false,
                  clickToClose: true,
                });
              } else {
                this._service.error('Error', 'Please Check you email adress', {
                  position: ['bottom', 'center'],
                  timeOut: 5000,
                  showProgressBar: true,
                  pauseOnHover: false,
                  clickToClose: true,
                });
              }
            });
        }

      },
      err => console.log(err)
    );

  }
}
