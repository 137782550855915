import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { Reperibilita } from '../shared/reperibilita.model';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { RepUserDatasource } from '../shared/rep-user-datasource.model';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { DialogComponent1 } from './dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';

// tslint:disable:class-name
export interface anno {
  value: string;
  viewValue: string;
}
export interface mese {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-update-rep',
  templateUrl: './update-rep.component.html',
  styleUrls: ['./update-rep.component.scss']
})
  // tslint:disable:max-line-length
  // tslint:disable:variable-name
export class UpdateRepComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  mese_web: any;
  anno_web: any;
  availability: any;
  anni: anno[] = [
    { value: '2019', viewValue: '2019' },
    { value: '2020', viewValue: '2020' },
    { value: '2021', viewValue: '2021' },
    { value: '2022', viewValue: '2022' },
    { value: '2023', viewValue: '2023' },
    { value: '2024', viewValue: '2024' },
    { value: '2025', viewValue: '2025' },
  ];
  mesi: mese[] = [
    { value: '01', viewValue: 'Gennaio' },
    { value: '02', viewValue: 'Febbraio' },
    { value: '03', viewValue: 'Marzo' },
    { value: '04', viewValue: 'Aprile' },
    { value: '05', viewValue: 'Maggio' },
    { value: '06', viewValue: 'Giugno' },
    { value: '07', viewValue: 'Luglio' },
    { value: '08', viewValue: 'Agosto' },
    { value: '09', viewValue: 'Settembre' },
    { value: '10', viewValue: 'Ottobre' },
    { value: '11', viewValue: 'Novembre' },
    { value: '12', viewValue: 'Dicembre' }
  ];
  tableColumns: string[] = ['data', 'locked'];
  reperibilita: Reperibilita = new Reperibilita();
  sortdata: RepUserDatasource[] = [];
  dataSource  = new MatTableDataSource(this.sortdata);
  date: any;
  id_rep: any;
  mese: boolean;
  company: any;

  constructor(public dialog: MatDialog, public _storage: MyLocalStorageService, private datePipe: DatePipe, private _service: NotificationsService, public _auth: AuthService, private _router: Router) {

   }
  ngOnInit() {
    // tslint:disable:no-shadowed-variable
    this.availability = this._storage.getItem('availabilityfull');
    const mese = this.datePipe.transform(new Date(), 'MM');
    const anno = this.datePipe.transform(new Date(), 'yyyy');
    this.company = localStorage.getItem('company');
    this.reperibilita.mese = mese;
    this.reperibilita.anno = anno;
    this.mese_web = mese;
    this.anno_web = anno;
    this.loadreperibilita();
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  loadreperibilita()  {
    this.reperibilita.id_utente = localStorage.getItem('id');
    console.log(this.reperibilita);
    this._auth.leggiperibilita(this.reperibilita)
    .subscribe(
      res => {
        if (res.risposta === 'novalue') {
          this.dataSource = new MatTableDataSource([]);
        } else {
          this._storage.setItem('mese', res[0].mese);
          this._storage.setItem('anno', res[0].anno);
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.sort = this.sort;
      }
      },
      err => console.log(err)
    );
}

    deleterep(id_rep) {
      this.reperibilita.id_utente = localStorage.getItem('id');
      this.reperibilita.id = id_rep;
      this._auth.deletereperibilita(this.reperibilita)
      .subscribe(
        res => {
            if (res.risposta === 'Success') {
              this._service.success('Success',
              'Record deleted', {
                position: ['bottom', 'center'],
                timeOut: 1000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: true,
              });
              this.loadreperibilita();
            } else {
              this._service.error('Errore',
          'Record validated or month closed', {
            position: ['bottom', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
            }
        }
      );
    }
    reperibilitaaction() {
      this._router.navigate(['/reperibilita'], { skipLocationChange: true });
    }
    changemese(e) {
      this.reperibilita.mese = this.mese_web;
      this.reperibilita.anno = this.anno_web;
      this.loadreperibilita();
    }
    changeanno(e) {
      this.reperibilita.mese = this.mese_web;
      this.reperibilita.anno = this.anno_web;
      this.loadreperibilita();
    }
    openDialog(action, obj) {
      obj.action = action;
      const dialogRef = this.dialog.open(DialogComponent1, {
        width: '400px',
        data: obj
      });
      dialogRef.afterClosed().subscribe(result => {
        this._router.navigate(['/welcome'], { skipLocationChange: true });
          this.loadreperibilita();
      });
    }
}
