import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../auth.service';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
export interface training {
  priority:string;
  title: string;
  input_date: string;
}
@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
  displayedColumns: string[] = ['priority', 'title', 'input_date'];
 ELEMENT_DATA: training[] = [];
 dataSource = this.ELEMENT_DATA
  constructor( private fb: FormBuilder, public _auth: AuthService, public _storage: MyLocalStorageService, private _router: Router) { }

  ngOnInit(): void {
    const data = {"id_anagrafica" : this._storage.getItem("id")}
    this.loadtraining(data);
  }
  loadtraining(data){
    this._auth.loadtraining(data)
  .subscribe(res => {
    if(res.length > 0) {
    this.ELEMENT_DATA = res
    this.dataSource = res;
  } else {
   // this.ELEMENT_DATA = []
    this.dataSource = this.ELEMENT_DATA;
  }
  },
   err => console.log(err)
 );
  }
  ngAfterViewInit() {}
  back(){
    this._router.navigate(['/admin'], { skipLocationChange: true });
  }
}
