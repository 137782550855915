<br><br>
<br>
<div style="text-align:center">
    <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<mat-form-field>
    <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
</mat-form-field>
<br>
<br>
<mat-table style="border: 1px;border-color: black;border-style: solid; width: 100%" [dataSource]="dataSource" matSort matSortStart="desc">
  <ng-container  matColumnDef="id" hidden>
    <mat-header-cell *matHeaderCellDef  hidden></mat-header-cell>
    <mat-cell *matCellDef="let element"  hidden>{{element.id}}</mat-cell>
</ng-container>
    <ng-container  matColumnDef="nome">
      <mat-header-cell *matHeaderCellDef >Nome</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.nome}}</mat-cell>
  </ng-container>
    <ng-container matColumnDef="cognome">
        <mat-header-cell *matHeaderCellDef> Cognome </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.cognome}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tel">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Telefono </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.tel}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
            <button mat-raised-button color="accent" (click)="updateanagrafica(element.id)" type="submit"><mat-icon>update</mat-icon></button>
            <button mat-raised-button color="primary" *ngIf="element.status=='active'" (click)="delete_ana(element.id)" type="submit"><mat-icon *ngIf="element.status=='active'" style="color: red" >block</mat-icon></button>
            <button mat-raised-button color="warn" *ngIf="element.status=='deactive'" (click)="reactivate(element.id)" type="submit"><mat-icon *ngIf="element.status=='deactive'" style="color: green" >check_circle</mat-icon></button>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
