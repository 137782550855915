<br>
<div style="text-align:center">
    <img width="10%" *ngIf="_storage.getItem('company') =='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="_storage.getItem('company') =='2'" src="../assets/diemmegi.jpg">
</div>
<br>

<button mat-raised-button color="primary" (click)="back()">Back
  <mat-icon>keyboard_backspace</mat-icon>
</button>
<div class="mat-elevation-z8">
  <button><mat-icon (click)="AddNewRow()" class="add-button">add_box</mat-icon></button>
  <br />
  <mat-form-field style="width: 40%">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
  </mat-form-field>
  <mat-form-field style="width: 20%; float: right;">
    <mat-label>Go to Page</mat-label>
    <input matInput type="number" placeholder="Enter page number" [(ngModel)]="pageNumber" (ngModelChange)="goToPage()">
  </mat-form-field>
  <form [formGroup]="VOForm" autocomplete="off">
    <ng-container formArrayName="VORows">
      <table #table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef> No. </th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
            <mat-form-field style="width: 70px;" appearance="none">
              <input matInput type="text" formControlName="value" [readonly]="true">
            </mat-form-field>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="viewValue">
          <th mat-header-cell *matHeaderCellDef> Role </th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">

            <!-- <span [hidden]="VOForm.get('VORows').value[i].isEditable"> -->
            <mat-form-field
              [appearance]="VOForm.get('VORows').value[i].isEditable? 'none' : 'legacy'">
              <input matInput type="text" formControlName="viewValue" [readonly]="VOForm.get('VORows').value[i].isEditable">
            </mat-form-field>
            <!-- </span> -->

          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
            <button mat-icon-button class="material-icons app-toolbar-menu save-button"
                                    matTooltip="Save Changes" (click)="SaveVO(element, VOForm,i)"
                                    *ngIf="!VOForm.get('VORows').value[i].isEditable" color="primary">
                                    <mat-icon>check_circle</mat-icon>
                                </button>

            <button mat-icon-button class="material-icons app-toolbar-menu cancel-button"
                                    matTooltip="Cancel Changes" color="warn"
                                    (click)="CancelSVO(VOForm,i)"
                                    *ngIf="!VOForm.get('VORows').value[i].isEditable">
                                    <mat-icon>cancel</mat-icon>
                                </button>

            <button mat-icon-button class="material-icons app-toolbar-menu save-button"
                                    *ngIf="VOForm.get('VORows').value[i].isEditable"
                                    matTooltip="Edit" color="primary" (click)="EditSVO(VOForm,i)">
                                    <mat-icon>edit</mat-icon>
                                </button>

            <button mat-icon-button class="material-icons app-toolbar-menu delete-button"
                                    *ngIf="VOForm.get('VORows').value[i].isEditable"
                                    matTooltip="Delete" color="warn" (click)="delrole(element)">
                                    <mat-icon>delete</mat-icon>
                                </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>
    </ng-container>
  </form>
  <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>

  <!-- <mat-paginator-goto [length]="100" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="paginationChange($event)"></mat-paginator-goto> -->
  <mat-paginator [pageSizeOptions]="[5, dataSource.data.length>8? dataSource.data.length:''  ]" showFirstLastButtons>
  </mat-paginator>
</div>
