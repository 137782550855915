import { Component, OnInit } from '@angular/core';
import { Anagrafica } from '../shared/anagrafica.model';
import { Progetti } from '../shared/progetti';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';

// tslint:disable: class-name
export interface deleteanagrafica {
  id: any;
}

@Component({
  selector: 'app-updateanagrafica',
  templateUrl: './Updateanagrafica.Component.html',
  styleUrls: ['./updateanagrafica.component.scss']
})
export class UpdateanagraficaComponent implements OnInit {
  company: any;
  tiporichiesta: any;
  setting: Progetti = new Progetti();
  ELEMENT_DATA: any[] = [];
  displayedColumns: string[] = ['id', 'nome', 'cognome', 'tel', 'action'];
  dataSource = new MatTableDataSource<Anagrafica>();
  // tslint:disable:max-line-length
  // tslint:disable: variable-name
  constructor(public _storage: MyLocalStorageService, private _service: NotificationsService, public _auth: AuthService, private _router: Router) { }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.load_anagrafica();
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  load_anagrafica() {
    this._auth.loadanagrafica('pippo')
    .subscribe(
      res => {
       this.dataSource.data = res as Anagrafica[];
      },
      err => console.log(err)
    );
  }
  delete_ana(id_web) {
   this.setting.id = id_web;
   this._auth.deleteanagrafica(this.setting)
    .subscribe(
      res => {
       // tslint:disable: triple-equals
       if (res.risposta == 'Success') {
        this._service.success('Success',
        'Record disabilitato', {
          position: ['bottom', 'center'],
          timeOut: 2000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
       } else {
        this._service.error('Errore',
        'Record non disabilitato', {
          position: ['bottom', 'center'],
          timeOut: 2000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
       }
       this.load_anagrafica();
      },
      err => console.log(err)
    );
  }
  reactivate(id_web) {
    this.setting.id = id_web;
    this._auth.riattivaanagrafica(this.setting)
    .subscribe(
      res => {
       if (res.risposta == 'Success') {
        this._service.success('Success',
        'Record abilitato', {
          position: ['bottom', 'center'],
          timeOut: 2000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
       } else {
        this._service.error('Errore',
        'Record non abilitato', {
          position: ['bottom', 'center'],
          timeOut: 2000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
       }
       this.load_anagrafica();
      },
      err => console.log(err)
    );
  }
  updateanagrafica(id_web) {
    this._storage.setItem('id_anagrafica', id_web);
    this._router.navigate(['/updateanagraficaadmin'],  { skipLocationChange: true });
  }
}
