import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {
constructor() { }
public exportAsExcelFile(json: any[], json1: any[], excelFileName: string): void {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json1);
  let workbook: XLSX.WorkBook
  if (excelFileName == 'Summary_Report'){
    workbook = { Sheets: { 'Summary_Report': worksheet1}, SheetNames: ['Summary_Report'] };
  } else {
    workbook = { Sheets: { 'availability': worksheet1, 'details': worksheet }, SheetNames: ['availability', 'details'] };
  }
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array'});

  this.saveAsExcelFile(excelBuffer, excelFileName);
}

private saveAsExcelFile(buffer: any, fileName: string): void {
   const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
   FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
}
}