import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../auth.service'; 
import { Router } from '@angular/router';
import { Progetti } from '../shared/progetti';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';

@Component({
  selector: 'app-progetti',
  templateUrl: './progetti.component.html',
  styleUrls: ['./progetti.component.scss']
})
export class ProgettiComponent implements OnInit {
  nuovo: boolean;
  status:any;
  company:any;
  progetto:any = "";
  tableColumns: string[] = ['code', 'description', 'id', 'active'];
  dataSource = [];
  setting: Progetti = new Progetti();
  constructor(public _storage: MyLocalStorageService, private _router: Router, private _service: NotificationsService, public _auth: AuthService) { }

  ngOnInit() {
    this.status = "active"
    this.company = localStorage.getItem('company');
    this.nuovo = false;
    this.load_progetti();
  }
  load_progetti(){
    this._auth.loadproject(this.setting)
    .subscribe(
      res => {
        if (res.risposta == "NoValue") {
          this.dataSource = [];
        } else {
          console.log(res)
          this.dataSource = res; 
          
        }
      },
      err => console.log(err)
    );
  }
newproject(){
  this.nuovo = true;
  }
saveproject(){
  this.setting.progetto = this.progetto;
  this._auth.saveproject(this.setting)
  .subscribe(
    res => {
      if (res.risposta == "Errore") {
        this._service.error('Errore',
        'Errore inserimento dati', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      } else {
        this.load_progetti();
      }
    },
    err => console.log(err)
  );
}

delete(id){
  this.setting.id = id;
  this._auth.deleteproject(this.setting)
  .subscribe(
    res => {
      if (res.risposta == "Errore") {
        this._service.error('Error',
        'Project not deactivated', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      } else {
        this._service.success('Success',
        'Project deactivated', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this.load_progetti();
      }
    },
    err => console.log(err)
  );
  }
  reactivate(id){
    this.setting.id = id;
    this._auth.reactivateproject(this.setting)
    .subscribe(
      res => {
        if (res.risposta == "Errore") {
          this._service.error('Error',
          'Project not reativated', {
            position: ['bottom', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
        } else {
          this._service.success('Success',
          'Project reactivated', {
            position: ['bottom', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this.load_progetti();
        }
      },
      err => console.log(err)
    );
    }
update(id_project, code_project, description_project){ 
  this._storage.setItem('id_project', id_project);
  this._storage.setItem('project_code', code_project);
  this._storage.setItem('project_description', description_project);
  this._router.navigate(['/updateproject'], { skipLocationChange: true });
}
}
