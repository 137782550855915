<br>
<div style="text-align:center">
    <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<div align="center">
    <label style="font-size: 20px;color: rgb(103, 150, 238)"> REQUEST TYPE</label>
    <br>
    <mat-card class="example-card" style="width: 20em; background-color: rgb(153, 218, 238)">
        <br>
        <mat-form-field>
            <mat-label>Select the request type</mat-label>
            <mat-select [(ngModel)]="tiporichiesta" [formControl]="passwordFormControl" required>
                <mat-option *ngFor="let richiesta of richieste" [value]="richiesta.value" >
                    {{richiesta.viewValue}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="passwordFormControl.hasError('required')">Mandatory</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="tiporichiesta=='PERMISSION'">
            <mat-label>Permission type</mat-label>
            <mat-select [(ngModel)]="tipo_richiesta">
                <mat-option *ngFor="let permesso of permessi" [value]="permesso.value">
                    {{permesso.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="tiporichiesta=='PLAN_VACANTION'">
            <mat-label>Vacantion Plan type</mat-label>
            <mat-select [(ngModel)]="tipo_richiesta">
                <mat-option *ngFor="let pianoferie of pianoferies" [value]="pianoferie.value" >
                    {{pianoferie.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="tiporichiesta=='VACANTION'">
            <mat-label>Vacantion type</mat-label>
            <mat-select [(ngModel)]="tipo_richiesta">
                <mat-option *ngFor="let ferie of feries" [value]="ferie.value">
                    {{ferie.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="tiporichiesta=='ABSENCE'">
            <mat-label>Absence type</mat-label>
            <mat-select [(ngModel)]="tipo_richiesta">
                <mat-option *ngFor="let assenza of assenze" [value]="assenza.value">
                    {{assenza.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field *ngIf="profile=='1'">
            <mat-label>Resource Name</mat-label>
            <mat-select [(ngModel)]="pippo" (ngModelChange)="dataChanged()">
              <input (keyup)="onKey($event.target.value)">
                <mat-option [value]="0">None</mat-option>
                <mat-option *ngFor="let utente of utenti1" [value]="utente">{{utente}}</mat-option>
            </mat-select>

        </mat-form-field>
        <br>
    </mat-card>
    <br>
    <button id="button" style="margin:5px" mat-raised-button color="primary" (click)=settiporichiesta() [disabled]="!passwordFormControl.valid ">Next</button>
</div>
