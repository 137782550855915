<div class="container" align="center">
    <div style="text-align:center">
        <img width="15%" alt="EMM Logo" src="../assets/accamieasyjob_logo_small.jpg">
    </div>
    <br>
    <button mat-raised-button color="accent" (click)="admin()" *ngIf="_storage.getItem('profile') =='1' && _storage.getItem('Adminsett') =='1'">
      <i class="material-icons left">whatshot</i>Admin
    </button>
    <br>
    <div align="center">
        <!-- <button mat-raised-button color="primary" style="width: 120px" (click)="login()" >
        <i class="material-icons left">account_circle</i> Login</button>
        <button style="width:120px" mat-raised-button color="accent" style="width: 120px" (click)="primoaccesso()"  *ngIf="_storage.getItem('firstlogin') ==='false'">
      <i class="material-icons left">account_circle</i> First Login</button>-->
        <button  style="width:120px; margin:5px" mat-raised-button color="primary" (click)="settings()" *ngIf="_authService.loggedIn()  && _storage.getItem('navigation') !=='settings'"><i class="material-icons left">settings</i>Settings</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="accent" (click)="settings()" *ngIf="_authService.loggedIn() && _storage.getItem('navigation') =='settings'"><i class="material-icons left">settings</i>Settings</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="primary" (click)="welcome()" *ngIf="_authService.loggedIn()   && _storage.getItem('navigation') !=='Benvenuto'"><i class="material-icons left">home</i>Home</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="accent" (click)="welcome()" *ngIf="_authService.loggedIn() && _storage.getItem('navigation') =='Benvenuto'"><i class="material-icons left">home</i>Home</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="primary" (click)="updateaccount()" *ngIf="_authService.loggedIn()  && _storage.getItem('navigation') !=='updateaccount'"><i class="material-icons left">account_circle</i>Profile</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="accent" (click)="updateaccount()" *ngIf="_authService.loggedIn() && _storage.getItem('navigation')=='updateaccount'"><i class="material-icons left">account_circle</i>Profile</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="warn" (click)="_authService.logoutUser()" *ngIf="_authService.loggedIn()"><i class="material-icons left">exit_to_app</i>Logout</button>
        <br><br>
        <button  style="width:160px; margin:5px" mat-raised-button color="primary" (click)="summary()" *ngIf="_authService.loggedIn()  && _storage.getItem('navigation') !=='summary'  && _storage.getItem('summary_report') === '1'"><i class="material-icons left">assignment</i>Summary Report</button>
        <button  style="width:160px; margin:5px" mat-raised-button color="accent" (click)="summary()" *ngIf="_authService.loggedIn() && _storage.getItem('navigation') =='summary'  && _storage.getItem('summary_report') === '1'"><i class="material-icons left">assignment</i>Summary Report</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="primary" (click)="updatetrasferta()" *ngIf="_authService.loggedIn()  && _storage.getItem('navigation') !=='updatetrasferta' && _storage.getItem('buz_trip') === '1'"><i class="material-icons left">card_travel</i>Buz Trip</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="accent" (click)="updatetrasferta()" *ngIf="_authService.loggedIn() && _storage.getItem('navigation') =='updatetrasferta' && _storage.getItem('buz_trip') === '1'"><i class="material-icons left">card_travel</i>Buz Trip</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="primary" (click)="updaterep()" *ngIf="_authService.loggedIn()  && _storage.getItem('navigation') !=='updaterep' && _storage.getItem('availability') === '1'"><i class="material-icons left">assignment_turned_in</i>Availability</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="accent" (click)="updaterep()" *ngIf="_authService.loggedIn() && _storage.getItem('navigation') =='updaterep' && _storage.getItem('availability') === '1'"><i class="material-icons left">assignment_turned_in</i>Availability</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="primary" (click)="menu()" *ngIf="_authService.loggedIn()  && _storage.getItem('navigation') !=='menu' && _storage.getItem('send_email') === '1'"><i class="material-icons left">alternate_email</i>Send Email</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="accent" (click)="menu()" *ngIf="_authService.loggedIn() && _storage.getItem('navigation') =='menu' && _storage.getItem('send_email') === '1'"><i class="material-icons left">alternate_email</i>Send Email</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="primary" (click)="certificato()" *ngIf="_authService.loggedIn()  && _storage.getItem('navigation') !=='certificato' && _storage.getItem('send_certificate') === '1'"><mat-icon>note_add</mat-icon>Send Cert.</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="accent" (click)="certificato()" *ngIf="_authService.loggedIn() && _storage.getItem('navigation') =='certificato' && _storage.getItem('send_certificate') === '1'"><mat-icon>note_add</mat-icon>Send Cert.</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="primary" (click)="corsi()" *ngIf="_authService.loggedIn()  && _storage.getItem('navigation') !=='training' && _storage.getItem('training') === '1'"><mat-icon>book</mat-icon>Training</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="accent" (click)="corsi()" *ngIf="_authService.loggedIn() && _storage.getItem('navigation') =='training' && _storage.getItem('training') === '1'"><mat-icon>book</mat-icon>Training</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="primary" (click)="rubrica()" *ngIf="_authService.loggedIn()  && _storage.getItem('navigation') !=='rubrica' && _storage.getItem('Address_Book') === '1'"><mat-icon>contact_phone</mat-icon> Address</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="accent" (click)="rubrica()" *ngIf="_authService.loggedIn() && _storage.getItem('navigation') =='rubrica' && _storage.getItem('Address_Book') === '1'"><mat-icon>contact_phone</mat-icon> Address</button>
        <!---------------------------------------------------------------------------------------------------------->
        <button  style="width:120px; margin:5px" mat-raised-button color="primary" (click)="settings()" *ngIf="_authService.loggedIn()  && _storage.getItem('profile') =='super' && _storage.getItem('navigation') !=='settings'"><i class="material-icons left">settings</i>Settings</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="accent" (click)="settings()" *ngIf="_authService.loggedIn() && _storage.getItem('profile') =='super' && _storage.getItem('navigation') =='settings'"><i class="material-icons left">settings</i>Settings</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="primary" (click)="menu()" *ngIf="_authService.loggedIn()  && _storage.getItem('profile') =='super' && _storage.getItem('navigation') !=='menu'"><i class="material-icons left">alternate_email</i>Send mail</button>
        <button  style="width:120px; margin:5px" mat-raised-button color="accent" (click)="menu()" *ngIf="_authService.loggedIn() && _storage.getItem('profile') =='super' && _storage.getItem('navigation') =='menu'"><i class="material-icons left">alternate_email</i>Send mail</button>
    </div>
    <!--<iframe scrolling='no' frameborder='no' width='55%' height='50' src='https://www.agi.it/embed.html' frameborder='0' allowfullscreen></iframe>
    --><br>
    <router-outlet></router-outlet>
    <simple-notifications></simple-notifications>
    <app-loader></app-loader>
</div>
