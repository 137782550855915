<br>
<div style="text-align:center">
    <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>

<mat-card class="example-card" style="background-color: rgb(153, 218, 238);width: 100%">
  <mat-form-field style="width:20%">
      <input matInput placeholder="Name" [(ngModel)]="anagrafica.nome">
  </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field style="width:20%">
      <input matInput placeholder="Surname" [(ngModel)]="anagrafica.cognome" [formControl]="textFormControl1" required>
      <mat-error *ngIf="textFormControl1.hasError('required')">Mandatory</mat-error>
  </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field style="width: 15%;">
    <input matInput [matDatepicker]="picker3" [(ngModel)]="anagrafica.datadinascita" placeholder=" Data di Nascita">
    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
    <mat-datepicker #picker3></mat-datepicker>
  </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
<mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Luogo di Nascita</mat-label>
    <input type="text"
           placeholder="Seleziona"
           aria-label="Number"
           matInput
           [formControl]="myControl"
           [matAutocomplete]="auto"
           [(ngModel)]="anagrafica.luogodinascita">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option" >
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field  >
    <mat-label>Luogo di Residenza</mat-label>
    <mat-select [(ngModel)]="pippo">
       <input (keyup)="onKey($event.target.value)">
      <mat-option *ngFor="let state of selectedStates" [value]="state" >{{state}}</mat-option>
    </mat-select>
  </mat-form-field>
<br>
<mat-form-field style="width:20%">
<input matInput placeholder="Indirizzo" [(ngModel)]="anagrafica.indirizzo">
</mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
<mat-form-field style="width:20%">
  <mat-label>Mansione</mat-label>
  <input matInput placeholder="Mansione" [(ngModel)]="anagrafica.profile">
</mat-form-field>
<!--<mat-form-field class="profile" style="width:20%">
  <mat-label>Role</mat-label>
  <mat-select [(ngModel)]="anagrafica.role" [formControl]="textFormControl5" required>
    <mat-option *ngFor="let role of roles" [value]="role.value">
        <b>{{role.viewValue}}</b>
    </mat-option>
</mat-select>
</mat-form-field> -->&nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field class="profile" *ngIf="_storage.getItem('profile') =='1'">
      <mat-label *ngIf="_storage.getItem('profile') =='1'">Profile</mat-label>
      <mat-select *ngIf="_storage.getItem('profile') =='1'" [(ngModel)]="anagrafica.role" [formControl]="textFormControl5" required>
          <mat-option *ngFor="let profile of profiles" [value]="profile.value">
              <b>{{profile.viewValue}}</b>
          </mat-option>
      </mat-select>
      <mat-error *ngIf="textFormControl5.hasError('required')">Mandatory</mat-error>
  </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field style="width:20%">
    <input matInput placeholder="Codice Fiscale" [(ngModel)]="anagrafica.codicefiscale" >
</mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field *ngIf="editcontr==false" style="width:20%">
    <mat-label>Tipo Contratto</mat-label>
    <mat-select [(ngModel)]="contractvalue">
        <mat-option *ngFor="let contratto of contratti" [value]="contratto.value">
            <b>{{contratto.viewValue}}</b>
        </mat-option>
    </mat-select>
</mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
<mat-form-field *ngIf="editcontr==true">
  <mat-label>Tipo Contratto</mat-label>
 <button  style="background-color: rgb(153, 218, 238);" (click)="updatecontr()"> <input matInput [(ngModel)]="contract2" disabled="true"></button>
</mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
<mat-form-field style="width:50%">
<input matInput placeholder="Note" [(ngModel)]="anagrafica.note">
</mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
<br>
<mat-form-field style="width: 15%;">
<input matInput [matDatepicker]="picker1" [(ngModel)]="anagrafica.datainizio" placeholder=" Data Inizio">
<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
<mat-datepicker #picker1></mat-datepicker>
</mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
<mat-form-field style="width: 15%;">
<input matInput [matDatepicker]="picker2" [(ngModel)]="anagrafica.datafine" placeholder=" Data Fine">
<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
<mat-datepicker #picker2></mat-datepicker>
</mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field style="width:15%">
      <mat-label>Gender</mat-label>
      <mat-select [(ngModel)]="anagrafica.gender">
          <mat-option *ngFor="let gender of genders" [value]="gender.value">
              {{gender.viewValue}}
          </mat-option>
      </mat-select>
  </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field style="width: 30%">
      <input matInput placeholder="Mobile" [(ngModel)]="anagrafica.tel">
  </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
  <br>
 <!-- <mat-form-field style="width: 15%" *ngIf="editcomp==true">
    <mat-label>Company</mat-label>
   <button style="background-color: rgb(153, 218, 238);" (click)="updatecomp()"> <input matInput [(ngModel)]="companyweb2" disabled="true"></button>
  </mat-form-field>-->
  <mat-form-field style="width: 15%" *ngIf="editcomp==false">
      <mat-label>Company</mat-label>
      <mat-select [(ngModel)]="companyvalue">
          <mat-option *ngFor="let company of companies" [value]="company.value">
              {{company.viewValue}}
          </mat-option>
      </mat-select>
      <mat-error *ngIf="textFormControl7.hasError('required')">Mandatory</mat-error>
  </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field style="width: 30%">
    <mat-label>Company Email</mat-label>
      <input matInput placeholder="Email Company" [(ngModel)]="anagrafica.posta_elettronica" [formControl]="textFormControl2">
      <mat-error *ngIf="textFormControl2.hasError('email')">Insert valid email</mat-error>
  </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;

  <mat-form-field *ngIf="editcust==false" style="width: 15%">
      <mat-label>Customer</mat-label>
      <mat-select [(ngModel)]="customersvalue" [formControl]="textFormControl">
          <mat-option [value]="0"></mat-option>
          <mat-option *ngFor="let customer of customers" [value]="customer.value">
              {{customer.viewValue}}
          </mat-option>
      </mat-select>
  </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;

  <mat-form-field style="width: 15%" *ngIf="editcust==true">
    <mat-label>Customer</mat-label>
    <button style="background-color: rgb(153, 218, 238);" (click)="updatecust()"> <input matInput [(ngModel)]="customerweb2" disabled="true"></button>
   </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field style="width: 30%">
      <input matInput placeholder="Email Customer" [(ngModel)]="anagrafica.customer_mail">
  </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field style="width: 30%">
    <input matInput placeholder="Welfare" [(ngModel)]="anagrafica.welfare">
</mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
<mat-form-field style="width: 30%">
  <input matInput placeholder="Cral" [(ngModel)]="anagrafica.cral">
</mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
<mat-form-field style="width: 15%;">
<input matInput [matDatepicker]="picker" [(ngModel)]="anagrafica.datavisitamedica" placeholder=" Data Visite Mediche">
<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
<mat-datepicker #picker></mat-datepicker>
</mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
</mat-card>
    <br>
    <button [disabled]="!textFormControl2.valid || !textFormControl1.valid" mat-raised-button color="primary" (click)="salvaanagrafica()">Save</button>
