import { Component, OnInit } from '@angular/core';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Anagrafica } from '../shared/anagrafica.model';
import { AuthService } from '../auth.service';
import { NotificationsService } from 'angular2-notifications';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// tslint:disable:class-name
export interface gender {
  value: string;
  viewValue: string;
}
export interface progetto {
  value: string;
  viewValue: string;
}
export interface role {
  value: string;
  viewValue: string;
}
export interface profile {
  value: string;
  viewValue: string;
}
export interface stato {
  value: string;
  viewValue: string;
}
export interface company {
  value: string;
  viewValue: string;
}
export interface customer {
  value: string;
  viewValue: string;
}
export interface citta {
  [x: string]: any;
  viewValue: string;
}
export interface contratto {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-updateadminanagrafica',
  templateUrl: './updateadminanagrafica.component.html',
  styleUrls: ['./updateadminanagrafica.component.scss']
})
export class UpdateadminanagraficaComponent implements OnInit {
  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  companies: company [];
  textFormControl  = new FormControl('', [Validators.required]);
  textFormControl1 = new FormControl('', [Validators.required]);
  textFormControl2 = new FormControl('', [Validators.email]);
  textFormControl3 = new FormControl('', [Validators.required]);
  textFormControl4 = new FormControl('', [Validators.required]);
  textFormControl5 = new FormControl('', [Validators.required]);
  textFormControl7 = new FormControl('', [Validators.required]);
  textFormControl8 = new FormControl('', [Validators.required]);
  progetti: progetto [];
  customers: customer [];
  profiles: profile[] = [{value: '2' , viewValue: 'User' }, {value: '1' , viewValue: 'Admin' },  {value: '3' , viewValue: 'Training' }];;
  roles: role[];
  genders: gender[] = [{value: 'M', viewValue: 'M'}, {value: 'F', viewValue: 'F'}, {value: 'Fluid', viewValue: 'Fluid'}, {value: 'Agender', viewValue: 'Agender'}];
  stati: stato[] = [{value: 'active', viewValue: 'active'}, {value: 'deactive', viewValue: 'deactive'}];
  anagrafica: Anagrafica = new Anagrafica();
  contratti: contratto[];
  // tslint:disable:variable-name
  company: any;
  id_anagrafica: any;
  companyweb: any;
  customerweb: any;
  residenza:any
  pippo:any;
  cittas: citta[] = [];
  state:any;
  editres: boolean;
  editcomp: boolean;
  companyweb1: company[];
  companyweb2: any = '';
  customerweb1: customer[];
  customerweb2: any = '';
  editcust: boolean;
  contract2: any = '';
  contract1: contratto[];
  contract: any;
  editcontr: boolean;
  contractvalue: string;
  companyvalue: any;
  customersvalue: any;
  // tslint:disable:max-line-length
  constructor(private _router: Router, public _storage: MyLocalStorageService, public _auth: AuthService, private _service: NotificationsService) { }

  async ngOnInit() {
    this.loadcontratto();
    this.loadclient();
    this.editres = false
    this.editcomp = false
    this.editcust = false
    this.editcontr = false
    this.company = localStorage.getItem('company');
    this.anagrafica.id = localStorage.getItem('id_anagrafica');
    await this.loadproject_web();
    await this.loadcitta();
    await this.loadcompany();
    await this.setinterface();
    await this.loadroles();

   // this.refresh()
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(this.anagrafica.luogodinascita),
      map(value => this._filter(value)),
    );
  }
  ngAfterViewInit(){

  }
  selectedStates = this.cittas;
  loadcontratto(){
    this._auth.loadcontratto()
  .subscribe(res => {
    this.contratti = res;
  },
   err => console.log(err)
 );
  }
  setinterface(){
    this._auth.loadanagraficatoupdate(this.anagrafica).subscribe(res => {
    this.state=res[0].comunediresidenza;
    this.pippo=res[0].comunediresidenza;
    this.anagrafica.comunediresidenza=res[0].comunediresidenza || ''
    this.anagrafica.nome = res[0].nome || '';
    this.anagrafica.cognome = res[0].cognome || '';
    this.anagrafica.posta_elettronica = res[0].posta_elettronica || '';
    this.anagrafica.tel = res[0].tel || '';
    let tipcompany = res[0].company
    let tipcompany1 = this.companies.filter(p => p.value == tipcompany);
    this.companyvalue = tipcompany1[0].value
    this.anagrafica.company = res[0].company || '';
    this.anagrafica.customer_team = res[0].customer_team || '';
    this.anagrafica.profile = res[0].ruolo || '';
    this.anagrafica.role = res[0].role;
    this.anagrafica.gender = res[0].gender || '';
    this.anagrafica.customer_mail = res[0].customer_mail || '';
    this.anagrafica.customer = res[0].customer || '';
    this.anagrafica.status = res[0].status || '';
    this.companyweb = Number(res[0].company) ;
    this.customersvalue = Number(res[0].customer);
    this.anagrafica.codicefiscale= res[0].codicefiscale || '';
    this.anagrafica.datadinascita= res[0].datadinascita || '';
    this.anagrafica.luogodinascita= res[0].luogodinascita || '';
    this.anagrafica.indirizzo= res[0].indirizzo || '';
    this.anagrafica.tipocontratto= res[0].tipocontratto || '';
    let tipcontr = res[0].tipocontratto
    let tipcontr1 = this.contratti.filter(p => p.value == tipcontr);
    this.contractvalue= tipcontr1[0].value;
    this.anagrafica.note= res[0].note || '';
    this.anagrafica.datainizio= res[0].datainizio || '';
    this.anagrafica.datafine= res[0].datafine || '';
    this.anagrafica.welfare= res[0].welfare || '';
    this.anagrafica.cral= res[0].cral || '';
    this.anagrafica.datavisitamedica= res[0].datavisitamedica || '';
    this._storage.setItem('anagrafica',JSON.stringify(this.anagrafica))
    });

  }

  onKey(value) {
  this.selectedStates = this.search(value);
  }
  search(value: string) {
    let filter = value.toLowerCase();
    return this.cittas.filter(option => option.toLowerCase().startsWith(filter));
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
   return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
  loadcompany() {
    this._auth.loadcompany(this.anagrafica)
    .subscribe(res => {
      this.companies = res;
    },
     err => console.log(err)
   );
  }
  updateres(){
    this.editres = true
  }
  updatecomp(){
    this.editcomp = true
   }
   updatecust(){
    this.editcust = true
   }
   updatecontr(){
    this.editcontr = true
   }
   loadroles(){
    this._auth.loadroles()
    .subscribe(res => {
          this.roles = res
    },
     err => console.log(err)
   );
   }
  loadcitta(){
    this._auth.loadcitta()
    .subscribe(res => {
      res.forEach(element => {
        this.options.push (element.citta)
        this.cittas.push (element.citta)
      });

    },
     err => console.log(err)
   );
  }
  loadclient() {
    this._auth.loadclient(this.anagrafica)
    .subscribe(res => {
      this.customers = res;
    },
     err => console.log(err)
   );
  }
loadanagrafica() {
  this._auth.loadanagraficatoupdate(this.anagrafica)
  .subscribe(res => {
    return res
  },
   err => console.log(err)
 );
}
loadproject_web() {
  this._auth.loadproject1(this.anagrafica)
  .subscribe(res => {
    this.progetti = res;
  },
   err => console.log(err)
 );
}
salvaanagrafica() {
  if (this.anagrafica.datafine == ""){
    this.anagrafica.datafine = null
  }
  this.anagrafica.password =''
  if (this.pippo !== undefined && this.pippo !== null){
    this.anagrafica.comunediresidenza = this.pippo ;
  } else {this.anagrafica.comunediresidenza = ''}
  if (this.contractvalue !== undefined && this.contractvalue !== null) {
    this.anagrafica.tipocontratto = this.contractvalue
  } else { this.anagrafica.tipocontratto = ''}
  if (this.companyvalue !== undefined && this.companyvalue !== null){
    this.anagrafica.company = this.companyvalue
  } else {this.anagrafica.company = '' }
  if ( this.customersvalue !== undefined && this.customersvalue !== null){
    this.anagrafica.customer = this.customersvalue
  } else { this.anagrafica.customer = ''}

  this._auth.updateanagraficaadmin(this.anagrafica)
  .subscribe(res => {
    // tslint:disable:triple-equals
    if (res.risposta == 'Success') {
      this._service.success('Success',
      'Record aggiornato', {
        position: ['bottom', 'center'],
        timeOut: 2000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
      this._router.navigate(['/admin'],  { skipLocationChange: true });
    } else {
      this._service.error('Error',
      'Record non aggiornato', {
        position: ['bottom', 'center'],
        timeOut: 2000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
    }
  },
   err => console.log(err)
  );
}
}
