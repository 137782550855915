import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { FormControl, Validators } from '@angular/forms';
import { Email } from '../shared/email.model';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { Client } from '../shared/client.model';
// tslint:disable: indent
// tslint:disable: class-name
export interface richiesta {
	value: string;
	viewValue: string;
}

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
	providers: [DatePipe, { provide: MAT_DATE_LOCALE, useValue: 'it-IT' }]
})
export class FileUploadComponent implements OnInit {
	requiredFormControl = new FormControl('', [Validators.required]);
	requiredFormControl1 = new FormControl('', [Validators.required]);
	requiredFormControl2 = new FormControl('', [Validators.required]);
	requiredFormControl3 = new FormControl('', [Validators.required]);
	client: Client = new Client();
	uploadedFiles: Array<File>;
	richiesta_web: any;
	variabile_data: any;
	tipo_certificato: any;
	tipocertificato: any;
	company: any;
	request: any;
	request1: any;
	certificato_web: any;
	variabile_data_al: any;
	email: Email = new Email();
	richieste: richiesta[] = [
		{ value: 'Day_Hospital', viewValue: 'Day Hospital' },
		{ value: 'Donazione_Sangue', viewValue: 'Donazione Sangue' },
		{ value: 'Ferie_Matrimoniali', viewValue: 'Ferie Matrimoniali' },
		{ value: 'Lutto', viewValue: 'Lutto' },
		{ value: 'Malattia', viewValue: 'Malattia' },
		{ value: 'Maternità', viewValue: 'Maternità' },
		{ value: 'Nascita_Figlio', viewValue: 'Nascita Figlio' },
		{ value: 'Permesso_Parentale', viewValue: 'Permesso Parentale' },
		{ value: 'Permesso_Studio', viewValue: 'Permesso Studio' },
    { value: 'Permesso_Elettorale', viewValue: 'Permesso Elettorale' },
    { value: 'Permesso_Tribunale', viewValue: 'Permesso Tribunale' },
	];
	constructor(
		private datePipe: DatePipe,
		public _storage: MyLocalStorageService,
		public _auth: AuthService,
		private http: HttpClient,
		private router: Router,
		private _service: NotificationsService,
		private _adapter: DateAdapter<any>
	) { }

	ngOnInit() {
		this.richiesta_web = '';
		this.company = localStorage.getItem('company');
		this._adapter.setLocale('it');
		this.loadcompanyemail();
	}
	fileChange(element) {
		this.uploadedFiles = element.target.files;
		console.log(this.uploadedFiles)
		this.upload();
	}
	onChange(event) {
		this._storage.setItem('tipo_certificato', this.richiesta_web);
    if (this.richiesta_web == 'Day_Hospital') { this.request = 'other'; }
		if (this.richiesta_web == 'Malattia') { this.request = 'certificato'; }
		if (this.richiesta_web == 'Donazione_Sangue') { this.request = 'other'; }
		if (this.richiesta_web == 'Ferie_Matrimoniali') { this.request = 'other'; }
		if (this.richiesta_web == 'Lutto') { this.request = 'other'; }
		if (this.richiesta_web == 'Nascita_Figlio') { this.request = 'other'; }
		if (this.richiesta_web == 'Maternità') { this.request = 'other'; }
		if (this.richiesta_web == 'Permesso_Parentale') { this.request = 'other'; }
		if (this.richiesta_web == 'Permesso_Studio') { this.request = 'other'; }
		if (this.richiesta_web == 'Permesso_104') { this.request = 'other'; }
    if (this.richiesta_web == 'Permesso_Elettorale') { this.request = 'other'; }
    if (this.richiesta_web == 'Permesso_Tribunale') { this.request = 'other'; }
	}
	upload() {
		this._storage.setItem('data_al', this.datePipe.transform(this.variabile_data_al, 'dd/MM/yy'));
		this._storage.setItem('data', this.datePipe.transform(this.variabile_data, 'dd/MM/yy'));
		this._storage.setItem('protocollo', this.certificato_web);
		this.tipocertificato = this._storage.getItem("tipo_certificato");
		let formData = new FormData();
		if (this.tipocertificato == "Malattia") {
		} else {
			for (var i = 0; i < this.uploadedFiles.length; i++) {
				formData.append('uploads[]', this.uploadedFiles[i], this.uploadedFiles[i].name);
				formData.append('nome', localStorage.getItem('nome'));
				formData.append('cognome', localStorage.getItem('cognome'));
				formData.append('id_utente', localStorage.getItem('id'));
				formData.append('tipo_cert', localStorage.getItem('tipo_certificato'));
			}

			this._auth.upload(formData).subscribe(response => {
				if (response.message == 'File uploaded successfully') {
					this._storage.setItem('file_name', response.file);
					//				this.invioemail();
				}
			});
		}

	}
	invioemail() {

		this.email.tiporichiesta = this._storage.getItem('tipo_certificato');
		this.email.username = this._storage.getItem('email');
		this.email.file_name = this._storage.getItem('file_name');
		this.email.data = this._storage.getItem('data');
		this.email.dataal = this._storage.getItem('data_al');
		this.email.num_protocollo = this._storage.getItem('protocollo');
		this.email.nome = this._storage.getItem('nome');
		this.email.id_utente = this._storage.getItem('id');
		this.email.cognome = this._storage.getItem('cognome');
		this.email.nome =  this._storage.getItem('nome');
		this.email.email = this._storage.getItem('email')
		this.email.email_certificati = this._storage.getItem('email_certificati');
		this._auth.inviocertificato(this.email).subscribe(
			res => {
				if (res.status == "SUCCESS") {
					this._service.success('Success', 'Mail per ' + this.email.tiporichiesta + ' inviata', {
						position: ['bottom', 'center'],
						timeOut: 5000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
					this.salvacertificato();
				} else {
					this._service.error('Errore', 'Ops... somethings gone wrong, I believe your password is wrong', {
						position: ['bottom', 'center'],
						timeOut: 5000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);
	}
	salvacertificato() {
		console.log("salva certificato")
		this._auth.salvacertificato(this.email).subscribe(
			res => {
				if (res.risposta == "Success") {
					this.router.navigate(['/welcome']);
				} else {
					this._service.error('Errore', 'Ops... somethings gone wrong, I believe your password is wrong', {
						position: ['bottom', 'center'],
						timeOut: 5000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);
	}

	preview() {
		this._storage.setItem('data_al', this.datePipe.transform(this.variabile_data_al, 'dd/MM/yy'));
		this._storage.setItem('data', this.datePipe.transform(this.variabile_data, 'dd/MM/yy'));
		this._storage.setItem('protocollo', this.certificato_web);
		this.router.navigate(['/previewcertificati'], { skipLocationChange: true });

	}
	loadcompanyemail() {
		this.client.id = this._storage.getItem('company');
		this._auth.loadcompanyemail(this.client).subscribe(
			res => {
				console.log(res);
				this._storage.setItem('indirizzocompany', res[0].indirizzo);
				this._storage.setItem('companyfullname', res[0].full_name);
			},
			err => console.log(err)
		);
	}
}
