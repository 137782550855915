import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly apiUrl = "https://api.github.com/users";
  constructor(private _http: HttpClient) { }
  getAll(): Observable<Spinner[]> {
    this.showSpinner.next(true);
    return this._http.get<Spinner[]>(this.apiUrl).pipe(
      tap(response => this.showSpinner.next(false), (error: any) => this.showSpinner.next(false))
    );
  }

}
export interface Spinner {
  [x: string]: any;
}
