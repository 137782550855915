import { Component, OnInit, ViewChild } from '@angular/core';
import { Estrairep } from '../shared/estrairep.model';
import { AuthService } from '../auth.service';
import { ExcelService } from '../services/excel.service';
import { DatePipe } from '@angular/common';
import { Reperibilita } from '../shared/reperibilita.model';
import { RepDatasource } from '../shared/rep-datasource.model';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from 'angular2-notifications';
import { HttpClient } from '@angular/common/http';
import { MatDialog} from '@angular/material/dialog';
import { DialogComponent2 } from '../dialog/dialog.component';
// tslint:disable:indent
// tslint:disable: class-name
export interface anno {
	value: string;
	viewValue: string;
}
export interface mese {
	value: string;
	viewValue: string;
}
@Component({
	selector: 'app-estrai-rep',
	templateUrl: './estrai-rep.component.html',
	styleUrls: ['./estrai-rep.component.scss'],
})
export class EstraiRepComponent implements OnInit {
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	checklock: any;
	company: any;
	SheetNames: any = [];
	Sheets: any = {};
	tableColumns: string[] = ['resource', 'total', 'locked', 'idutente'];
	setting: Estrairep = new Estrairep();
	sortdata: RepDatasource[] = [];
	dataSource = new MatTableDataSource(this.sortdata);
	reperibilita: Reperibilita = new Reperibilita();
	confirm_uncofirm = Object.assign({}, { "locked": "", "id_utente": "", "mese": "", "anno": "" })
	// tslint:disable: variable-name
	mese_web: any;
	anno_web: any;
	id_utente: any;
	lock: any;
	tempinsert: any[] = [];
	temp_estrazione_excel: [];
	estrazione_excel: any;
	anni: anno[] = [
		{ value: '2019', viewValue: '2019' },
		{ value: '2020', viewValue: '2020' },
		{ value: '2021', viewValue: '2021' },
		{ value: '2022', viewValue: '2022' },
    { value: '2023', viewValue: '2023' },
    { value: '2024', viewValue: '2024' },
    { value: '2025', viewValue: '2025' },
	];
	mesi: mese[] = [
		{ value: '01', viewValue: 'Gennaio' },
		{ value: '02', viewValue: 'Febbraio' },
		{ value: '03', viewValue: 'Marzo' },
		{ value: '04', viewValue: 'Aprile' },
		{ value: '05', viewValue: 'Maggio' },
		{ value: '06', viewValue: 'Giugno' },
		{ value: '07', viewValue: 'Luglio' },
		{ value: '08', viewValue: 'Agosto' },
		{ value: '09', viewValue: 'Settembre' },
		{ value: '10', viewValue: 'Ottobre' },
		{ value: '11', viewValue: 'Novembre' },
		{ value: '12', viewValue: 'Dicembre' },
	];
	estrazione_excel1: any;
	constructor(
		public dialogRef: MatDialog,
		private httpClient: HttpClient,
		private _router: Router,
		public _storage: MyLocalStorageService,
		private datePipe: DatePipe,
		public _auth: AuthService,
		private excelService: ExcelService,
		private _service: NotificationsService
	) { }

	ngOnInit() {
		this.company = localStorage.getItem('company');
		// tslint:disable: no-shadowed-variable
		const mese = this.datePipe.transform(new Date(), 'MM');
		const anno = this.datePipe.transform(new Date(), 'yyyy');
		this.reperibilita.mese = mese;
		this.reperibilita.anno = anno;
		this.mese_web = mese;
		this.anno_web = anno;
		this._storage.setItem('mese', this.mese_web);
		this._storage.setItem('anno', this.anno_web);
		this.loadreperibilita();
	}
	ngAfterViewInit() {
		this.dataSource.sort = this.sort;
	}
	async preestrazione() {
		let temp = await this._auth.loadavailabilityfull('').toPromise();
		let data = await this.preestrazione1(temp);
		if (data === 'ok') {
			this.estrazionefinale();
		}
	}
	async preestrazione1(data) {
		this.reperibilita.mese = this._storage.getItem('mese');
		this.reperibilita.anno = this._storage.getItem('anno');

		// tslint:disable-next-line: prefer-for-of
		for (let index = 0; index < data.length; index++) {
			const element = data[index];
			this.reperibilita.id_utente = element.id;
			await this._auth.checkreperibilitafull(this.reperibilita).subscribe(
				async res => {
					if (res.risposta === 'Success') {
						this.insertreperibilitafull(res);
					} else {
						if (res.data[0].conta !== 14) {
						const tempdelete = res.data[0].id;
						this.deletefullavailability(tempdelete, res);
					}
					}
				},
				err => console.log(err)
			);
		}
		this.setting.mese = this.mese_web;
		this.setting.anno = this.anno_web;
		this.estrazione_excel1 = await this._auth.estrairep(this.setting).toPromise();
		this.estrazione_excel = await this._auth.estrairepfull(this.setting).toPromise();
		const risposta = 'ok';
		return risposta;
	}
	async deletefullavailability(data , data1) {
		await this._auth.deletefull({id: data}).subscribe(
			async res => {
				if (res.risposta === 'Success') {
					await this.insertreperibilitafull(data1);
				} else {
					this._service.error('Error', 'Record not deleted', {
						position: ['bottom', 'center'],
						timeOut: 2000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);
	}
	async insertreperibilitafull(data) {
		// tslint:disable-next-line: prefer-for-of
		data.dataforcheck = data.anno + '-' + data.mese + '-' + data.giorno;
		await this._auth.insertreperibilitafull(data).subscribe(
				async res => {
					if (res.risposta === 'Success') {
					} else {
						this._service.error('Error', 'Record not inserted', {
							position: ['bottom', 'center'],
							timeOut: 2000,
							showProgressBar: true,
							pauseOnHover: false,
							clickToClose: true,
						});
					}
				},
				err => console.log(err)
			);

	}

	async estrazionefinale() {
		this.setting.mese = this.mese_web;
		this.setting.anno = this.anno_web;
		this.estrazione_excel1 = await this._auth.estrairep(this.setting).toPromise();
		this.estrazione_excel = await this._auth.estrairepfull(this.setting).toPromise();
		this.exportAsXLSX(this.estrazione_excel, this.estrazione_excel1);
	}
	async estrai() {
		this.setting.mese = this.mese_web;
		this.setting.anno = this.anno_web;
		await this._auth.estrairep(this.setting).subscribe(
			res => {
				this.estrazione_excel1 = res;
			},
			err => console.log(err)
		);
	}
	async estraifull() {
		this.setting.mese = this.mese_web;
		this.setting.anno = this.anno_web;
		await this._auth.estrairepfull(this.setting).subscribe(
			res => {
				this.estrazione_excel = res;

			},
			err => console.log(err)
		);
	}
	exportAsXLSX(data, data1): void {
		this.excelService.exportAsExcelFile(data, data1, 'availability_EMM_Full');
		this.loadreperibilita();
	}

	loadreperibilita() {
		this.reperibilita.id_utente = localStorage.getItem('id');
		this._auth.leggiperibilita_global(this.reperibilita).subscribe(
			res => {
				if (res.risposta === 'novalue') {
					this.dataSource = new MatTableDataSource([]);
				} else {
					this.dataSource = new MatTableDataSource(res);
					this.dataSource.sort = this.sort;
				}
			},
			err => console.log(err)
		);
		this._router.navigate(['/estrairep'], { skipLocationChange: true });
	}
	private async fetchData() {
		const apiUrl = ('https://be.easyjob.ddns.net/reperibilita/leggiperibilita');
		const promise = this.httpClient.post(apiUrl, this.confirm_uncofirm).toPromise();
		promise.then((data) => {
			this.checklock = data[0].locked;
			if (this.checklock == '0') {
				this.confirmreperibilita(this.confirm_uncofirm.id_utente);
			} else if (this.checklock == '1') {
				this.unconfirmreperibilita(this.confirm_uncofirm.id_utente);
			}
		}).catch((error) => {
			console.log("Promise rejected with " + JSON.stringify(error));
		});
	}
	confirm(id_utente_web: any) {
		this.confirm_uncofirm.id_utente = id_utente_web;
		this.confirm_uncofirm.mese = this.mese_web;
		this.confirm_uncofirm.anno = this.anno_web;
		this.fetchData();

	}
	confirmreperibilita(id_utente_web) {
		this.confirm_uncofirm.id_utente = id_utente_web;
		this.confirm_uncofirm.mese = this._storage.getItem('mese');
		this.confirm_uncofirm.anno = this._storage.getItem('anno');
		this.confirm_uncofirm.locked = '1';
		console.log(this.confirm_uncofirm)
		this._auth.confirmreperibilita(this.confirm_uncofirm).subscribe(res => {
			if (res.risposta == 'Error') {
				this._service.error('Error', 'Record not confirmed', {
					position: ['bottom', 'center'],
					timeOut: 2000,
					showProgressBar: true,
					pauseOnHover: false,
					clickToClose: true,
				});
			} else if (res.risposta == 'Success') {
				this.loadreperibilita();
			}
		}, err => console.log(err));
	}
	unconfirmreperibilita(id_utente_web) {
		this.confirm_uncofirm.id_utente = id_utente_web;
		this.confirm_uncofirm.mese = this._storage.getItem('mese');
		this.confirm_uncofirm.anno = this._storage.getItem('anno');
		this.confirm_uncofirm.locked = '0';
		console.log(this.confirm_uncofirm)
		this._auth.unconfirmreperibilita(this.confirm_uncofirm).subscribe(res => {
			if (res.risposta == 'Error') {
				this._service.error('Error', 'Record not updated', {
					position: ['bottom', 'center'],
					timeOut: 2000,
					showProgressBar: true,
					pauseOnHover: false,
					clickToClose: true,
				});
				this.loadreperibilita();
			} else {
				this.loadreperibilita();
			}
		}, err => console.log(err));
	}

	delete(id_utente_web) {
		console.log("delete")
		this.confirm_uncofirm.id_utente = id_utente_web;
		this.confirm_uncofirm.mese = this._storage.getItem('mese');
		this.confirm_uncofirm.anno = this._storage.getItem('anno');
		console.log(this.confirm_uncofirm);
		this._auth.delete_rep(this.confirm_uncofirm).subscribe(
			res => {
				if (res.risposta == 'Success') {
					this._service.success('Success', 'Record deleted', {
						position: ['bottom', 'center'],
						timeOut: 2000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
					this.loadreperibilita();
				} else {
					this._service.error('Error', 'Record not deleted', {
						position: ['bottom', 'center'],
						timeOut: 2000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);

	}
	admin() {
		this._router.navigate(['/admin'], { skipLocationChange: true });
	}
	changemese(e) {
		this.reperibilita.mese = this.mese_web;
		this.reperibilita.anno = this.anno_web;
		this._storage.setItem('mese', this.mese_web);
		this.loadreperibilita();
	}
	changeanno(e) {
		this.reperibilita.mese = this.mese_web;
		this.reperibilita.anno = this.anno_web;
		this._storage.setItem('anno', this.anno_web);
		this.loadreperibilita();
	}
	estrazionecustom() {
	    const dialogRef = this.dialogRef.open(DialogComponent2, {
			width: '350px'});
		}
}
