<br>
<mat-card class="example-card" style="width: 16em; background-color: rgb(153, 218, 238)">
<div class="row">
  <mat-form-field>
  <input matInput placeholder="Temporany Password" [(ngModel)]="temporanypassword" type="password" passwordToggle [formControl]="passwordFormControl" required>
  <mat-error *ngIf="passwordFormControl.hasError('required')">Mandatory</mat-error>
</mat-form-field>
</div>
<div class="row">
    <mat-form-field>
    <input matInput placeholder="New Password" [(ngModel)]="newpassword" type="password" passwordToggle [formControl]="mandatoryFormControl" required>
    <mat-error *ngIf="mandatoryFormControl.hasError('required')">Mandatory</mat-error>
  </mat-form-field>
  </div>
  <div class="row">
      <mat-form-field>
      <input matInput placeholder="Confirm New Password" [(ngModel)]="confirmnewpassword" type="password" passwordToggle [formControl]="mandatoryFormControl1" required>
      <mat-error *ngIf="mandatoryFormControl1.hasError('required')">Mandatory</mat-error>
    </mat-form-field>
    </div>
<button mat-raised-button color="primary" (click)= "changepassword()" [disabled]="!passwordFormControl.valid || !mandatoryFormControl.valid || !mandatoryFormControl1.valid"
type="submit">Change Password
    <i class="material-icons right">send</i>
</button>
</mat-card>
