<br>
<div style="text-align:center">
  <img width="10%"  *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
  <img width="5%"  *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<ngb-datepicker #dp  [displayMonths]="1" [dayTemplate]="t">
</ngb-datepicker>
<ng-template #t let-date="date" let-focused="focused" >
  <span class="custom-day" (click)="onDateSelection($event,date)"
        [class.focused]="focused"
        [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        [class.selected]="isDateSelected(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
<br>
<div align="center">
<br>
<a (click)="updaterep()"><button mat-raised-button color="primary">Back</button></a>
<button mat-raised-button color="primary" (click)="checkdata()">Save</button>
</div>
