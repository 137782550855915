<br>
<div style="text-align:center">
    <img width="10%" *ngIf="_storage.getItem('company') =='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="_storage.getItem('company') =='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<button mat-raised-button color="primary" (click)="newclient()">
    <mat-icon>fiber_new</mat-icon>
</button>
<button mat-raised-button color="primary" (click)="back()">Back
    <mat-icon>keyboard_backspace</mat-icon>
</button>
<mat-card class="example-card" style="width: 100%; background-color: rgb(153, 218, 238)">
    <mat-table *ngIf="update_web == 'false'" style="border: 1px;border-color: black;border-style: solid"
        [dataSource]="dataSource" style="width: 100%">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="full_name">
            <mat-header-cell *matHeaderCellDef>Full Name</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.full_name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button mat-raised-button color="warn" (click)="delete(row.id, row.status)">
                    <mat-icon *ngIf="row.status == 'active'">delete_forever</mat-icon>
                    <mat-icon style="color: green" *ngIf="row.status == 'deactive'">check</mat-icon>
                </button>
                <button mat-raised-button color="primary" (click)="update(row.name, row.full_name, row.id)">
                    <mat-icon>update</mat-icon>
                </button>
                <button mat-raised-button color="primary" (click)="settings(row.id, row.full_name)">
                  <mat-icon>build</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </mat-table>
    <mat-form-field style="width: 100%" *ngIf="update_web == 'true' || update_web == 'new'">
        <input style="width: 100%" matInput placeholder="name" [(ngModel)]="name_web" value="name_web">
    </mat-form-field>
    <br>
    <mat-form-field style="width: 100%" *ngIf="update_web == 'true' || update_web == 'new'">
        <input style="width: 100%" matInput placeholder="Full Name" [(ngModel)]="fullname_web"
            value="fullname_web">
    </mat-form-field>
    <br>
    <button *ngIf="update_web == 'true'" mat-raised-button color="primary" (click)="save()">
            <mat-icon>save</mat-icon>
    </button>
    <button *ngIf="update_web == 'new'" mat-raised-button color="primary" (click)="savenew()">
            <mat-icon>save</mat-icon>
    </button>
</mat-card>
<br>
<mat-card  *ngIf="update_settingweb == 'true'" class="example-card" style="width: 100%; background-color: rgb(153, 218, 238)">
  <br>
  <h1 style="text-align: center;">{{this.namesetting}}</h1>
<br>
  <table style="width: 100%; background-color: white;">

    <tr style="text-align: center;">
      <th style="text-align: center;">summary report </th>
      <th style="text-align: center;"> buz trip </th>
      <th style="text-align: center;"> availability </th>
      <th style="text-align: center;"> send certificate </th>
      <th style="text-align: center;"> send email </th>
      <th style="text-align: center;"> training </th>
      <th style="text-align: center;"> Address Book </th>
      </tr>
      <tr  style="text-align: center;">
      <td><mat-checkbox  [(ngModel)]="summary_report"></mat-checkbox> </td>
      <td> <mat-checkbox  [(ngModel)]="buz_trip"></mat-checkbox> </td>
      <td> <mat-checkbox  [(ngModel)]="availability"></mat-checkbox> </td>
      <td> <mat-checkbox  [(ngModel)]="send_certificate"></mat-checkbox> </td>
      <td> <mat-checkbox  [(ngModel)]="send_email"></mat-checkbox> </td>
      <td> <mat-checkbox  [(ngModel)]="training"></mat-checkbox> </td>
      <td> <mat-checkbox  [(ngModel)]="Address_Book"></mat-checkbox> </td>
    </tr>
    <tr></tr>
    <tr >
      <th style="text-align: center;"  colspan="6">
        <button  mat-raised-button color="primary" (click)="savesettings()">
          <mat-icon>save</mat-icon>
  </button>
      </th>
    </tr>
    </table>
  <br>
</mat-card>
<br> <br>
