<br>
<div style="text-align:center">
  <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
  <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<!--<button mat-raised-button color="accent" (click)="admin()" *ngIf="profile=='admin' || profile =='super'">
  <i class="material-icons left">whatshot</i>Admin
</button> -->


<div align="center">

  <label style="font-size: 30px;color: rgb(103, 150, 238)">Welcome &nbsp;</label><label
    style=" font-size: 30px;color:  rgb(103, 150, 238)">{{nome}}</label>
  <br>
  <label *ngIf="availability=='1'" style="font-size: 15px;color: black"><mat-icon style='color: gold;'>star</mat-icon> Availability Full ACTIVE!</label>
  <br>
  <div style="text-align:center">
    <br>
    <label style="font-size: 14px; color: red;">New Version 2.2</label>
    <br><br><br>
    <label class= "label_new1">
      <br>July 2021,<br><br> Minor bug fixing.
           </label>
     <hr>
    <label class= "label_new1">
     <br>May 2021,<br><br> Improvements:<br>- pse-nadmin@alliance-healthcare.net address replaced with complete recipient list.<br>
      - less hassle - now send the email without entering the email password.<br>
      - now the e-mails are sent with the provider's e-mail, after sending it you will find a copy of the e-mail sent in your inbox.<br>
      Minor bug fixing.<br>
          </label>
    <hr>
    <label class= "label_new">October 2020, <br><br>NEW AVAILABILITY PROCESS:<br>to register the days of availability<br>
it is necessary to send emails with easyjob. <br>
Use "Send Email" <br>
and choose request type "AVAILABILITY" <br>
and send the mail.<br>
    </label>
    <hr>
    <label class="label_new">From July 2019, <br> <br>only the hours of availability
      included <br> in the new “Accami Easy Job” tool <br>within each 25th of the month, for the month incurred,<br> and
      subsequently
      validated will be paid,<br> after which the current month will be closed and <br>it will no longer be possible to
      enter
      them for that month.<br><br>If the hours of availability will not be entered, <br> it implies that no validation
      is
      possible<br> therefore no remuneration to the employee will be made, <br>and no subsequent recovery will be
      possible.
    </label>
    <br><br>
    <label style="font-size: 14px; color: rgb(103, 150, 238);font: bold;background-color: yellow"> From July 2019, only
      the availability entered in this tool will be paid!!!</label>
    <br><br>
    <label style="font-size: 114px0px; color: red">#AccamìEasyJob #WorkInProgress</label>
  </div>
