<br>
<div style="text-align:center">
    <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br><br><br>
<mat-card style="width: 50%;background-color:rgb(153, 218, 238)">
    <br>
    <br>
  <mat-card  *ngIf="_storage.getItem('Employeesett') =='1'" style="background-color: rgb(61, 61, 180); width: 100% ;">
        <div style="color: white; align-items: center;" >Employee</div>
    <br>
    <button mat-raised-button color="accent" (click)="nuovaanagrafica()" style="width: 100%">New</button>
    <br><br>
    <button hidden="true" mat-raised-button color="accent" (click)="updateanagrafica()" style="width: 100%; font-size: 10px">Update/Delete</button>   <br><br>
    <!--<button mat-raised-button color="accent" (click)="visitemediche()" style="width: 100%; font-size: 10px">medical exam deadline</button>-->
</mat-card>
<br>
    <mat-card *ngIf="_storage.getItem('customer') !='2' && _storage.getItem('Availabilitysett') =='1'" style="background-color: rgb(61, 61, 180); width: 100%">
      <div style="color: white; align-items: center;" >Availability</div>
        <button style="width: 100%" mat-raised-button color="accent" (click)="estrairep()">Availability</button>
        <br><br>
        <button style="width: 100%" mat-raised-button color="accent" (click)="meselock()">Mese Lock</button>
        <br><br>
        <button style="width: 100%" mat-raised-button color="accent" (click)="setavailailabilityfull()">Availability Full</button>
    </mat-card>
    <br>
    <mat-card *ngIf="_storage.getItem('customer') !='2' && _storage.getItem('Summarysett') =='1'" style="background-color: rgb(61, 61, 180); width: 100%">
            <div style="color: white; align-items: center;" >Summary</div>
        <button style="width: 100%" mat-raised-button color="accent" (click)="estraisummary()">Extract Summary</button>
    </mat-card>
    <br>
    <mat-card *ngIf="_storage.getItem('customer') =='2'" style="background-color: rgb(61, 61, 180); width: 100%">
            <div style="color: white; align-items: center;" >
                Trasferta
            </div>
        <button style="width: 100%" mat-raised-button color="accent" (click)="estraitrasferta()">Trasferta</button>
        <br><br>
        <button style="width: 100%" mat-raised-button color="accent" (click)="meselocktrasferta()">Mese Lock</button>
    </mat-card>
    <br>
    <mat-card *ngIf="_storage.getItem('customer') !='2' && _storage.getItem('Timesheetsett') =='1'" style="background-color: rgb(61, 61, 180); width: 100%">
            <div style="color: white; align-items: center;" >Timesheet entity settings</div>
        <button style="width: 100%" mat-raised-button color="accent" (click)="ts_progetto()">Project TS</button>
        <br><br>
        <button style="width: 100%" mat-raised-button color="accent" (click)="ts_entity()">Legal Entity TS</button>
        <br><br>
        <button style="width: 100%" mat-raised-button color="accent" (click)="ts_activity()">Activity TS</button>
    </mat-card>
    <br>
    <mat-card *ngIf="_storage.getItem('Internal_Settingssett') =='1'" style="background-color: rgb(61, 61, 180); width: 100%">
            <div style="color: white; align-items: center;" >Internal Settings</div>
        <button mat-raised-button color="accent" (click)="companyroute()" style="width: 100%">Company</button>
        <br><br>
        <button mat-raised-button color="accent" (click)="client()" style="width: 100%">Client</button>
        <br><br>
        <button mat-raised-button color="accent" (click)="tipocontratti()" style="width: 100%">Type of Contract</button>
        <br><br>
        <button mat-raised-button color="accent" (click)="programroles()" style="width: 100%">Program Roles</button>
        <br><br>
        <button mat-raised-button color="accent" (click)="roles()" style="width: 100%">Roles Setting</button>
    </mat-card>
<br>
<mat-card *ngIf="_storage.getItem('Trainingsett') =='1'" style="background-color: rgb(61, 61, 180); width: 100%">
        <div style="color: white; align-items: center;" >Training</div>
    <button mat-raised-button color="accent" (click)="admintraining()" style="width: 100%">Training Course</button>
    <br><br>
</mat-card>
</mat-card>
