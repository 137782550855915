<br>
<div style="text-align:center">
    <img width="10%" *ngIf="_storage.getItem('company') =='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="_storage.getItem('company') =='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<br>
<!--<button mat-raised-button color="primary" (click)="back()">Back
    <mat-icon>keyboard_backspace</mat-icon>
</button> -->
<mat-card style="width: fit-content; background-color: rgb(153, 218, 238)">
  <br> 
  <label style="font-size: 30px; color: blue;">Training Choise</label>
  <br>
  <br>
  <div>
        <table mat-table [dataSource]="dataSource" style="width: auto">
          <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef  style="text-align: left;"> Priority </th>
            <td mat-cell *matCellDef="let element"> {{element.priority}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="title" >
            <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Title </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="input_date"  style="text-align: center;">
            <th mat-header-cell *matHeaderCellDef> Request Date </th>
            <td mat-cell *matCellDef="let element"> {{element.input_date | date:'dd-MM-yy'}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
  </div>
</mat-card>