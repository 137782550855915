<br>
<div style="text-align:center">
  <img width="10%"  *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
  <img width="5%"  *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<br>
<mat-card class="example-card" style="width: 100%; background-color: rgb(153, 218, 238)">
    <br>
    <mat-form-field>
        <mat-label>Select month</mat-label>
        <mat-select [(ngModel)]="mese_web" (ngModelChange)="changemese($event)">
            <mat-option *ngFor="let mese of mesi" [value]="mese.value" [(value)]="mese_web">
                {{mese.viewValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Select year</mat-label>
        <mat-select [(ngModel)]="anno_web" (ngModelChange)="changeanno($event)">
            <mat-option *ngFor="let anno of anni" [value]="anno.value" [(value)]="anno_web">
                {{anno.viewValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <br>
    <a (click)="admin()"><button mat-raised-button color="primary">Back</button></a>
    <button mat-raised-button color="primary" (click)="preestrazione()"><mat-icon>fact_check</mat-icon></button>
    <button mat-raised-button color="primary" (click)="estrazionecustom()">Extract custom date</button>
</mat-card>
<mat-table style="border: 1px;border-color: black;border-style: solid; width: 100%" [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="resource">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Resource Name</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.resource}}</mat-cell> 
    </ng-container>
    <ng-container matColumnDef="total">
        <mat-header-cell fxLayoutAlign="center center" *matHeaderCellDef mat-sort-header>Total</mat-header-cell>
        <mat-cell fxLayoutAlign="center center" *matCellDef="let row">{{row.total}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="locked">
        <mat-header-cell fxLayoutAlign="center center" *matHeaderCellDef mat-sort-header>Confirmed</mat-header-cell>
        <mat-cell fxLayoutAlign="center center" *matCellDef="let row">
            <mat-icon *ngIf="row.locked=='1'">locked</mat-icon>
            <mat-icon *ngIf="row.locked=='0'">lock_open</mat-icon>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="idutente">
        <mat-header-cell fxLayoutAlign="center center" *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell fxLayoutAlign="center center" *matCellDef="let row"><button mat-raised-button color="warn" (click)="confirm(row.idutente)">Conf/Unconf</button><button mat-raised-button color="warn" (click)="delete(row.idutente)">Delete</button></mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
</mat-table>
<br>