<br>
<div style="text-align:center">
  <img width="10%"  *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
  <img width="5%"  *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<mat-card style="background-color: rgb(153, 218, 238)">
    <div class="example-header">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>
      </div>
    <mat-table  [dataSource]="dataSource">
        <ng-container  matColumnDef="id">
            <mat-header-cell *matHeaderCellDef ></mat-header-cell>
            <mat-cell *matCellDef="let row" class="ttaa" style="width: fit-content;">
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Cognome">
            <mat-header-cell *matHeaderCellDef>Cognome</mat-header-cell>
            <mat-cell *matCellDef="let row" class="ttaa"> {{row.cognome}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Nome">
            <mat-header-cell *matHeaderCellDef>Nome</mat-header-cell>
            <mat-cell *matCellDef="let row" class="ttaa"> {{row.nome}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fullavailability">
            <mat-header-cell *matHeaderCellDef>Availability full</mat-header-cell>
            <mat-cell *matCellDef="let row" class="ttaa">
                <button style="background: none; border: none;" matTooltip="Full Availability ACTIVE"><mat-icon *ngIf="row.fullavailability=='1'" style='color: gold; border: black; border-color: black;' (click)=unlock(row.id)>star</mat-icon></button>
                <button style="background: none; border: none;" matTooltip="No Full Availability"><mat-icon *ngIf="row.fullavailability=='0'" style='color: blu;' (click)=lock(row.id)>star_border</mat-icon></button>
            </mat-cell>
        </ng-container>
        <ng-container hidden matColumnDef="idanagrafica">
            <mat-header-cell hidden *matHeaderCellDef>arrprocess</mat-header-cell>
            <mat-cell hidden *matCellDef="let row" class="ttaa">{{row.idanagrafica}}
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </mat-table>
   <!-- <mat-paginator  [pageSizeOptions]="[5, 10, 20, 50]"></mat-paginator> -->
</mat-card>
