import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { NotificationsService } from 'angular2-notifications';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Login } from '../shared/login.model';
import { Setting } from '../shared/setting.model';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  nome:any;
  cognome: any;
  profile: any;
  company:any;
  login: Login = new Login();
  setting: Setting = new Setting();
  availability: string;
  constructor(
    public _auth: AuthService,
		private _router: Router,
		private _service: NotificationsService,
		public _storage: MyLocalStorageService) { }

  ngOnInit() {
    localStorage.setItem('navigation', 'Benvenuto');
    this.checkoreprogetto();
    this.nome = localStorage.getItem('nome');
    this.cognome = localStorage.getItem('cognome');
    this.profile = localStorage.getItem('profile');
    this.company = localStorage.getItem('company');
    this.availability = localStorage.getItem('availabilityfull');
    this.loadrolesettings(this.profile);

  }
  loadrolesettings(profile) {
    const data = {"ruolo" : profile}
    this._auth.loadrolesettings(data).subscribe(
      res1 => {
        this._storage.setItem('Employeesett', res1[0].Employee);
        this._storage.setItem('Availabilitysett', res1[0].Availability);
        this._storage.setItem('Summarysett', res1[0].Summary);
        this._storage.setItem('Timesheetsett', res1[0].Timesheet);
        this._storage.setItem('Internal_Settingssett', res1[0].Internal_Settings);
        this._storage.setItem('Trainingsett', res1[0].Training);
        this._storage.setItem('Adminsett', res1[0].Admin);
      },
      err => console.log(err)
    );

  }
admin(){
  this._router.navigate(['/admin'], { skipLocationChange: true });
}
checkoreprogetto(){
  this.login.id_utente = localStorage.getItem('id');
  this._auth.check_ore_progetto(this.login).subscribe(
    res => {
      if (res.ore == '8') {
        this._storage.setItem('ore_progetto', res.ore);
      } else {
        this._storage.setItem('ore_progetto', res.ore);
        this._service.error('Warning', 'Please, check your project, the hours of work per day must be 8h in total!!!', {
          position: ['bottom', 'center'],
          timeOut: 5000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this._router.navigate(['/settings'], { skipLocationChange: true });
      }
    },
    err => console.log(err)
  );
}
}
