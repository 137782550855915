<br>
<div style="text-align:center">
  <img width="10%"  *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
  <img width="5%"  *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
</mat-form-field>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="cognome">
    <th mat-header-cell *matHeaderCellDef> Surname </th>
    <td mat-cell *matCellDef="let element"> {{element.cognome}} </td>
  </ng-container>
  <ng-container matColumnDef="nome">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
  </ng-container>
  <ng-container matColumnDef="tel">
    <th mat-header-cell *matHeaderCellDef> Phone number </th>
    <td mat-cell *matCellDef="let element"> {{element.tel}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
