<br>
<div style="text-align:center">
    <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<mat-card style="width: 100%; background-color: rgb(153, 218, 238)">
    <label style="font-size: 20px;">My project mail recipients</label>
    <br>
    <br>
    <div style="text-align: start;">
    <label style="font-size: 14px; color:blue">Important: at least one team member mail recipient must be added.</label>
    </div>
    <mat-table style="border: 1px;border-color: black;border-style: solid; width: 100%" [dataSource]="dataSource">
        <ng-container matColumnDef="destinatari">
            <mat-header-cell *matHeaderCellDef>My Team Mail recipients:</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.destinatari}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="id">
            <mat-header-cell fxFlex="50px" *matHeaderCellDef></mat-header-cell>
            <mat-cell fxFlex="50px" *matCellDef="let row"></mat-cell>
        </ng-container>
        <!--  <ng-container matColumnDef="test">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row"></mat-cell>
    </ng-container> -->
        <ng-container matColumnDef="actions">
            <mat-header-cell fxLayoutAlign="end center" *matHeaderCellDef></mat-header-cell>
            <mat-cell fxLayoutAlign="end center" *matCellDef="let row">
                <button style="margin:5px" mat-raised-button color="warn" (click)="deletedest(row.id)" type="submit">Remove</button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </mat-table>
    <div style="text-align: start;">

        <mat-form-field style="width: 70%;">
            <input matInput placeholder="Team member mail recipient to insert (LINE MANAGER/CSM/SM/ADE/ADM)" type="text" [(ngModel)]="value1" [formControl]="emailFormControl">
            <mat-error *ngIf="emailFormControl.hasError('email')">Insert valid email - Insert one email at a time.</mat-error>
        </mat-form-field>
        <button style="margin:5px" mat-raised-button color="primary" type="submit" (click)="addElement()" [disabled]="!emailFormControl.valid">Insert</button>
    </div>
    <br>
    <mat-table style="border: 1px;border-color: black;border-style: solid; width: 100%" [dataSource]="dataSource3">
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef>{{company_name}} Mail recipients:</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.email}}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns3"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns3"></mat-row>
    </mat-table>
    <br>
    <mat-table  *ngIf="visibility===true" style="border: 1px;border-color: black;border-style: solid; width: 100%" [dataSource]="dataSource2">
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef>{{customer_name}} Mail recipients:</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.email}}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns2"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns2"></mat-row>
    </mat-table>
</mat-card>
<br>
<mat-card style="width: 100%; background-color: rgb(233, 243, 142)">
    <label style="font-size: 20px;">My daily hours of work</label>
    <br>
    <div style="text-align: start;">
    <br>
    <label style="font-size: 14px; color:blue">Important: the total hours of work per day must be 8h.</label>
    </div>
    <mat-table style="border: 1px;border-color: black;border-style: solid; width: 100%" [dataSource]="dataSource1">
        <ng-container matColumnDef="id">
            <mat-header-cell fxFlex="1px" *matHeaderCellDef style="width: 1px !important; white-space: nowrap;" ></mat-header-cell>
            <mat-cell fxFlex="1px" *matCellDef="let row"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="hours">
            <mat-header-cell fxLayoutAlign="center center" *matHeaderCellDef style="width: 15px !important; white-space: nowrap;">Hours of <br>work per day</mat-header-cell>
            <mat-cell fxLayoutAlign="center center" *matCellDef="let row" style="width: 15px !important; white-space: nowrap;">{{row.hours}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="project" class="mat-header-cell">
            <mat-header-cell *matHeaderCellDef class="mat-cell">Timesheet Project</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.project}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions" style="width: 15px;" >
            <mat-header-cell fxLayoutAlign="end center" *matHeaderCellDef></mat-header-cell>
            <mat-cell fxLayoutAlign="end center" *matCellDef="let row">
                <button style="margin:5px" mat-raised-button color="warn" (click)="deleteproject(row.id)" type="submit">Remove</button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns1"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns1"></mat-row>
    </mat-table>
    <br>
<div style="text-align: start;">
    <mat-form-field>
        <mat-label>Hours of work per day</mat-label>
        <mat-select [(ngModel)]="setting.ore_timesheet">
            <mat-option *ngFor="let ora of ore" [value]="ora.viewValue">
                {{ora.viewValue}}
            </mat-option>
        </mat-select>
    </mat-form-field> &nbsp;&nbsp;&nbsp;
    <mat-form-field style="width: 70%">
        <mat-label>Timesheet Project</mat-label>
        <mat-select [(ngModel)]="setting.id_timesheet">
            <mat-option *ngFor="let progetto of progetti" [value]="progetto.viewValue">
                {{progetto.viewValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button style="margin:5px" mat-raised-button color="primary" (click)="add()">Insert</button>
</div>
</mat-card>
<br>
