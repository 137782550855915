export class Inserttemptable {
TS_DATE: any;
NAME: any;
PROJECT_CODE: any;
PROJECT_DESCRIPTION: any;
ACTIVITY: any;
NOTE: any;
HOURS: number;
PROJECT: any;
LEGAL_ENTITY: any;
COMPANY: any;
}
