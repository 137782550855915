import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deleteanagrafica',
  templateUrl: './deleteanagrafica.component.html',
  styleUrls: ['./deleteanagrafica.component.scss']
})
export class DeleteanagraficaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
