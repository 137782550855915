<h1 mat-dialog-title>Select Date</h1>
<div mat-dialog-content style="text-align: center;">
    <mat-form-field style="margin:15px;">
        <mat-label>Start Date</mat-label>
        <input type="date" [(ngModel)]="startdateweb" matInput [(value)]="startdateweb">
    </mat-form-field>
    <br>
    <mat-form-field style="margin:15px;">
        <mat-label>End Date</mat-label>
        <input type="date" [(ngModel)]="enddateweb" matInput [(value)]="enddateweb">
    </mat-form-field>
    <br>
    <button mat-raised-button color="primary" (click)="doaction()">Extract</button>
    <button mat-raised-button color="primary" (click)="onNoClick()">back</button>
</div>