<br>
<div style="text-align:center">
    <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<div align="center">
    <button mat-raised-button color="accent" (click)="reperibilitaaction()">Add Business Trip</button>
</div>
<br>
<mat-card class="example-card" style="width: 60%; background-color: rgb(153, 218, 238)">
    <mat-form-field>
        <mat-label>Seleziona Mese</mat-label>
        <mat-select [(ngModel)]="mese_web" (ngModelChange)="changemese($event)">
            <mat-option *ngFor="let mese of mesi" [value]="mese.value" [(value)]="mese_web">
                {{mese.viewValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Seleziona Anno</mat-label>
        <mat-select [(ngModel)]="anno_web" (ngModelChange)="changeanno($event)">
            <mat-option *ngFor="let anno of anni" [value]="anno.value" [(value)]="anno_web">
                {{anno.viewValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <br>
    <mat-table [dataSource]="dataSource" style="width: 100%" matSort>
        <ng-container matColumnDef="data">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Date di Trasferta</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.data | date : 'dd/MMMM/yyyy - EEEE'}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="locked">
            <mat-header-cell *matHeaderCellDef>Stato</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-icon matTooltip="Validated" *ngIf="row.locked=='1'" style="color: green">check_circle</mat-icon>
                <mat-icon matTooltip="Awaiting validation" *ngIf="row.locked=='0'" style="color: yellow">warning</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button *ngIf="row.locked=='0' && row.mese_locked=='0'" mat-raised-button color="warn" (click)="deleterep(row.id)" type="submit">Delete</button></mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </mat-table>
</mat-card>
