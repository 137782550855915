export class Summary {
id:any;
id_utente:any;
data:any;
giorno:any;
mese:any;
anno:any;
certificato:any;
tipo_comunicazione:any;
idriga: any;
specifica: any;
orespecifica: any;
starttime: any;
finishtime: any;
}
