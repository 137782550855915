import { Component, OnInit } from '@angular/core';
import { TsProgetto } from '../shared/ts-progetto.model';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import * as XLSX from 'xlsx';
export interface legalentities {
  active: string;
  description: string;
  id: number;
}
@Component({
  selector: 'app-ts-legal-entity',
  templateUrl: './ts-legal-entity.component.html',
  styleUrls: ['./ts-legal-entity.component.scss']
})
export class TsLegalEntityComponent implements OnInit {
  currentURL='';
  description_new:any;
  update_web:any = "false";
  company:any;
  dataSource = [];
  legalcaricati: legalentities[];
  setting: TsProgetto = new TsProgetto();
  tableColumns: string[] = ['description', 'active', 'id'];
  constructor(public _storage: MyLocalStorageService, private _router: Router, private _service: NotificationsService, public _auth: AuthService) {
    this.currentURL = window.location.href;
  }

  ngOnInit() {
    console.log(this.currentURL);
    this.company = localStorage.getItem('company');
    this.loadentityts();
  }
  loadentityts(){
    this._auth.load_entity_ts(this.setting)
    .subscribe(
      res => {
        if (res.risposta == "Errore") {
          this.dataSource = [];
        } else {
          this.dataSource = res;
          this.legalcaricati=res
        }
      },
      err => console.log(err)
    );
  }
  update(row){
    this.setting=row;
    this.update_web="true";
  }

  back(){
    this._router.navigate(['/admin'], { skipLocationChange: true });
  }
  public importlegent(e) {
    console.log(e.target.files[0]);
    const target: DataTransfer = <DataTransfer>(<unknown>event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data:any[] = XLSX.utils.sheet_to_json(ws);
      data.forEach(element => {
        let tempresult =  this.legalcaricati.findIndex(i => i.id === element.LEGAL_ENTITY_CODE);
      if (tempresult == -1){
        this._auth.insert_legal_entity(element)
        .subscribe(
          res => {
            if (res.risposta == "Success") {
              this._service.success('Success',
              'Legal Entity inserted', {
                position: ['bottom', 'center'],
                timeOut: 3000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: true,
              });
            } else {
              this._service.error('Error',
              'Legal Entity not inserted', {
                position: ['bottom', 'center'],
                timeOut: 3000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: true,
              });
            }
            this.loadentityts();
          },
          err => console.log(err)
        );
          }
      });
    };
  }
  delete(id_ts_project, active){
    this.setting.id=id_ts_project;
    if (active == 1) {
      this._auth.disable_entity_ts(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
            'Entity disabilitata', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          } else {
            this._service.error('Error',
            'Qualcosa è andato storto', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
          this.loadentityts();;
        },
        err => console.log(err)
      );

    } else {
      this._auth.enable_entity_ts(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
            'Entity riabilitata', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          } else {
            this._service.error('Error',
            'Qualcosa è andato storto', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
          this.loadentityts();
        },
        err => console.log(err)
      );
    }
      }

      save(){
        this._auth.update_entity_ts(this.setting)
        .subscribe(res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
            'Entity aggiornata', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
            this.update_web="false";
          } else {
            this._service.error('Error',
            'Entity non aggiornata', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
        },
         err => console.log(err)
       );
      }

      newentity(){
        this.update_web="new";
      }

      save_new(){
        this.setting.description=this.description_new;
        this._auth.nuovo_entity_ts(this.setting)
        .subscribe(res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
            'Entity inserita', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
            this.update_web="false";
          } else {
            this._service.error('Error',
            'Entity non inserita', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
          this._router.navigate(['/admin'], { skipLocationChange: true });
        },
         err => console.log(err)
       );
      }
}
