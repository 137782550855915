import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../../auth.service';
import { MyLocalStorageService } from '../../localstorage/mylocalstorage.service';
export interface PeriodicElement {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-contratti',
  templateUrl: './contratti.component.html',
  styleUrls: ['./contratti.component.scss']
})

export class ContrattiComponent implements OnInit {

  displayedColumns: string[] = ['value', 'viewValue', 'action'];
  dataSource = new MatTableDataSource<any>();

 isLoading = true;
 ELEMENT_DATA: PeriodicElement[] = [];
 pageNumber: number = 1;
   VOForm: FormGroup;
   isEditableNew: boolean = true;
  contratti: any[];
  updatevalue: any;
  constructor(private _formBuilder: FormBuilder, private fb: FormBuilder, private _service: NotificationsService, public _auth: AuthService, public _storage: MyLocalStorageService, private _router: Router) { }

  ngOnInit(): void {
    this.loadcontratto();
    this.VOForm = this._formBuilder.group({
      VORows: this._formBuilder.array([])
    });
    if (this.ELEMENT_DATA.length === 0){


    } else {
      this.test(this.ELEMENT_DATA)
          }
  }

test(ELEMENT_DATA){
  this.VOForm = this.fb.group({
    VORows: this.fb.array(ELEMENT_DATA.map(val => this.fb.group({
      value: new FormControl(val.value),
      viewValue: new FormControl(val.viewValue),
      action: new FormControl('existingRecord'),
      isEditable: new FormControl(true),
      isNewRow: new FormControl(false),
    })
    )) //end of fb array
  }); // end of form group cretation
  this.dataSource = new MatTableDataSource((this.VOForm.get('VORows') as FormArray).controls);
  this.dataSource.paginator = this.paginator;
  this.isLoading = false;
  const filterPredicate = this.dataSource.filterPredicate;
    this.dataSource.filterPredicate = (data: AbstractControl, filter) => {
      return filterPredicate.call(this.dataSource, data.value, filter);
    }
}
  @ViewChild(MatPaginator) paginator: MatPaginator;
  loadcontratto(){
    this._auth.loadcontratto()
  .subscribe(res => {
    if(res.length > 0) {
    this.contratti = res;
    this.ELEMENT_DATA = res
    this.test(this.ELEMENT_DATA)
  } else {
    this.contratti =[]
    this.ELEMENT_DATA = []
    this.test(this.ELEMENT_DATA)
  }
  },
   err => console.log(err)
 );
  }

  goToPage() {
    this.paginator.pageIndex = this.pageNumber - 1;
    this.paginator.page.next({
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginatorList = document.getElementsByClassName('mat-paginator-range-label');

   this.onPaginateChange(this.paginator, this.paginatorList);

   this.paginator.page.subscribe(() => { // this is page change event
     this.onPaginateChange(this.paginator, this.paginatorList);
   });
  }
  back(){
    this._router.navigate(['/admin'], { skipLocationChange: true });
  }
  applyFilter(event: Event) {
    //  debugger;
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  // @ViewChild('table') table: MatTable<PeriodicElement>;
  AddNewRow() {
    const control = this.VOForm.get('VORows') as FormArray;
    control.insert(0,this.initiateVOForm());
    this.dataSource = new MatTableDataSource(control.controls)
    // control.controls.unshift(this.initiateVOForm());
    // this.openPanel(panel);
      // this.table.renderRows();
      // this.dataSource.data = this.dataSource.data;
  }

  // this function will enabled the select field for editd
  EditSVO(VOFormElement, i) {
    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(false);
  }
  delcontr(element){
    if (element.value.isNewRow ===false){
      this.updatevalue = {value: element.value.value}
      this._auth.deletecontratto(this.updatevalue)
      .subscribe(res => {
        if (res.risposta == 'Success') {
          this._service.success('Success',
          'Record cancellato', {
            position: ['bottom', 'center'],
            timeOut: 2000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this._router.navigate(['/admin'],  { skipLocationChange: true });
        } else {
          this._service.error('Error',
          'Record non cancellato', {
            position: ['bottom', 'center'],
            timeOut: 2000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
        }
      },
       err => console.log(err)
     );
    }
  }
  SaveVO(element, VOFormElement, i) {

    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
    if (element.value.isNewRow ===false){
      VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
      this.updatevalue = {value: element.value.value, viewValue: element.value.viewValue}
      this.storecontratto(this.updatevalue)
    } else  if (element.value.isNewRow ===true){
      VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
      this.updatevalue = {viewValue: element.value.viewValue}
      this._auth.newcontratto( this.updatevalue)
      .subscribe(res => {
        if (res.risposta == 'Success') {
          this._service.success('Success',
          'Record inserito', {
            position: ['bottom', 'center'],
            timeOut: 2000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this._router.navigate(['/admin'],  { skipLocationChange: true });
        } else {
          this._service.error('Error',
          'Record non inserito', {
            position: ['bottom', 'center'],
            timeOut: 2000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
        }
      },
       err => console.log(err)
     );
    }

  }
  storecontratto(updatevalue1){
    this._auth.updatecontratto(updatevalue1)
    .subscribe(res => {
      if (res.risposta == 'Success') {
        this._service.success('Success',
        'Record aggiornato', {
          position: ['bottom', 'center'],
          timeOut: 2000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this._router.navigate(['/admin'],  { skipLocationChange: true });
      } else {
        this._service.error('Error',
        'Record non aggiornato', {
          position: ['bottom', 'center'],
          timeOut: 2000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
    },
     err => console.log(err)
   );
  }
  // On click of cancel button in the table (after click on edit) this method will call and reset the previous data
  CancelSVO(VOFormElement, i) {
    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
  }


paginatorList: HTMLCollectionOf<Element>;
idx: number;
onPaginateChange(paginator: MatPaginator, list: HTMLCollectionOf<Element>) {
     setTimeout((idx) => {
         let from = (paginator.pageSize * paginator.pageIndex) + 1;

         let to = (paginator.length < paginator.pageSize * (paginator.pageIndex + 1))
             ? paginator.length
             : paginator.pageSize * (paginator.pageIndex + 1);

         let toFrom = (paginator.length == 0) ? 0 : `${from} - ${to}`;
         let pageNumber = (paginator.length == 0) ? `0 of 0` : `${paginator.pageIndex + 1} of ${paginator.getNumberOfPages()}`;
         let rows = `Page ${pageNumber} (${toFrom} of ${paginator.length})`;

         if (list.length >= 1)
             list[0].innerHTML = rows;

     }, 0, paginator.pageIndex);
}


  initiateVOForm(): FormGroup {
    return this.fb.group({

      value: new FormControl(1),
                viewValue: new FormControl(''),
                action: new FormControl('newRecord'),
                isEditable: new FormControl(false),
                isNewRow: new FormControl(true),
    });
  }
}
