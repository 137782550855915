import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../auth.service';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Router } from '@angular/router';
import { Anagrafica } from '../shared/anagrafica.model';
import { MatTableDataSource } from '@angular/material/table';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
export interface PeriodicElement {
  cognome: string;
  nome: string;
  tel: any;
}


@Component({
  selector: 'app-rubrica',
  templateUrl: './rubrica.component.html',
  styleUrls: ['./rubrica.component.scss']
})
export class RubricaComponent implements OnInit {
  rubrica_tab: any [];
  VOForm: FormGroup;
  anagrafica: Anagrafica = new Anagrafica();
	displayedColumns: string[] = ['cognome', 'nome', 'tel'];

  isLoading: boolean = true;
  company: any;
  ELEMENT_DATA: PeriodicElement[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  constructor(private _formBuilder: FormBuilder, private fb: FormBuilder, private _router: Router, public _storage: MyLocalStorageService, public _auth: AuthService, private _service: NotificationsService) { }

  ngOnInit(): void {
    this.loadanagrafica();
    this.company = localStorage.getItem('company');
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  loadanagrafica() {
    this._auth.rubrica()
    .subscribe(res => {
      if(res.length > 0) {
        this.dataSource = new MatTableDataSource(res);
    } else {
    }
    },
     err => console.log(err)
   );
  }
}
