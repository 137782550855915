<h1 mat-dialog-title>Delete Comunication</h1>

<br><br>
<div mat-dialog-content>
  Are you sure ?
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="doaction()">Delete</button>
  <button mat-raised-button color="primary" (click)="onNoClick()">Back</button>
  <br>
  </div>
