<br>
<div style="text-align:center">
  <img width="10%"  *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
  <img width="5%"  *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<mat-card class="example-card" style="width: 100%; background-color: rgb(153, 218, 238)" >
    <br>
    <mat-form-field>
        <mat-label>Seleziona Mese</mat-label>
        <mat-select [(ngModel)]="mese_web" name="mese" (ngModelChange)="changemese()">
          <mat-option *ngFor="let mese of mesi" [value]="mese.value">
            {{mese.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
        <mat-form-field>
          <mat-label>Seleziona Anno</mat-label>
          <mat-select [(ngModel)]="anno_web" name="anno" (ngModelChange)="changeanno()">
            <mat-option *ngFor="let anno of anni" [value]="anno.value">
              {{anno.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br>
      </mat-card>
<br />
<mat-form-field style="width: 40%">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
</mat-form-field>
<form [formGroup]="VOForm" autocomplete="off">
  <ng-container formArrayName="VORows">
    <table #table mat-table [dataSource]="dataSource" style="width: 80%;" >
      <ng-container matColumnDef="id">
        <th style="visibility: hidden;" mat-header-cell *matHeaderCellDef></th>
        <td style="visibility: hidden;" mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
      </ng-container>
      <ng-container matColumnDef="id_utente">
        <th style="visibility: hidden;" mat-header-cell *matHeaderCellDef></th>
        <td style="visibility: hidden;" mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
      </ng-container>
      <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; font-weight: bold;">Date</th>
        <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
          <mat-form-field style="width: 70px;" appearance="none">
            <input style="text-align: center;"matInput type="text" formControlName="data" [readonly]="true">
          </mat-form-field>
      </ng-container>
      <ng-container matColumnDef="ore">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; font-weight: bold;">Hours</th>
        <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
          <mat-form-field style="width: auto;" appearance="none">
            <input style="text-align: center;"matInput style="text-align: center;" type="text" formControlName="ore" [readonly]="true">
          </mat-form-field>
      </ng-container>
      <ng-container matColumnDef="specifica">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; font-weight: bold;">Details</th>
        <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
          <mat-form-field style="width: auto;" appearance="none">
            <input style="text-align: center;"matInput type="text" formControlName="specifica" [readonly]="true">
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="finish_time">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; font-weight: bold;"> Exit Time </th>
        <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
          <mat-form-field style="width: auto;" appearance="none">
            <input style="text-align: center;" matInput type="text" formControlName="finish_time" [readonly]="true">
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="start_time">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; font-weight: bold;"> Entry Time </th>
        <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
          <mat-form-field style="width: auto;" appearance="none">
            <input  style="text-align: center;" matInput type="text" formControlName="start_time" [readonly]="true">
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; font-weight: bold;"> Action </th>
        <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
          <button style="text-align: end;"mat-icon-button class="material-icons app-toolbar-menu save-button"
                                  *ngIf="VOForm.get('VORows').value[i].isEditable"
                                  matTooltip="Delete" color="primary" (click)="openDialog(VOForm,i)">
                                  <mat-icon>cancel</mat-icon>
                              </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
  </ng-container>
</form>
<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
<mat-progress-spinner
  color="primary"
  mode="indeterminate">
</mat-progress-spinner>
</mat-card>
<mat-paginator [pageSizeOptions]="[5, dataSource.data.length>8? dataSource.data.length:''  ]" showFirstLastButtons>
</mat-paginator>
        <br>
