import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './auth.guard';
import { CertificatoComponent } from './certificato/certificato.component';
import { MenuComponent } from './menu/menu.component';
import { SettingsComponent } from './settings/settings.component';
import { EntrataInRitardoComponent } from './entrata-in-ritardo/entrata-in-ritardo.component';
import { PermessoComponent } from './permesso/permesso.component';
import { PermessoInIngressoComponent } from './permesso-in-ingresso/permesso-in-ingresso.component';
import { PermessoInUscitaComponent } from './permesso-in-uscita/permesso-in-uscita.component';
import { FerieUnGiornoComponent } from './ferie-un-giorno/ferie-un-giorno.component';
import { FeriePiuGiorniComponent } from './ferie-piu-giorni/ferie-piu-giorni.component';
import { AssenzaPiuGiorniComponent } from './assenza-piu-giorni/assenza-piu-giorni.component';
import { PianoFeriePiuGiorniComponent } from './piano-ferie-piu-giorni/piano-ferie-piu-giorni.component';
import { AssenzaUnGiornoComponent } from './assenza-un-giorno/assenza-un-giorno.component';
import { PianoFerieUnGiornoComponent } from './piano-ferie-un-giorno/piano-ferie-un-giorno.component';
import { StraordinarioComponent } from './straordinario/straordinario.component';
import { ReperibilitaComponent } from './reperibilita/reperibilita.component';
import { UpdateRepComponent } from './update-rep/update-rep.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { PrimoAccessoComponent } from './primo-accesso/primo-accesso.component';
import { PreviewComponent } from './preview/preview.component';
import { AdminComponent } from './admin/admin.component';
import { EstraiRepComponent } from './estrai-rep/estrai-rep.component';
import { MeselockComponent } from './meselock/meselock.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { ProgettiComponent } from './progetti/progetti.component';
import { NuovaanagraficaComponent } from './nuovaanagrafica/nuovaanagrafica.component';
import { UpdateanagraficaComponent } from './updateanagrafica/updateanagrafica.component';
import { UpdateadminanagraficaComponent } from './updateadminanagrafica/updateadminanagrafica.component';
import { UpdateProgettoComponent } from './update-progetto/update-progetto.component';
import { TsProgettiComponent } from './ts-progetti/ts-progetti.component';
import { TsLegalEntityComponent } from './ts-legal-entity/ts-legal-entity.component';
import { TsActivityComponent } from './ts-activity/ts-activity.component';
import { ClientAdminComponent } from './client-admin/client-admin.component';
import { CompanyAdminComponent } from './company-admin/company-admin.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { SummaryComponent } from './summary/summary.component';
import { PreviewCertificatiComponent } from './preview-certificati/preview-certificati.component';
import { ManutenzioneComponent } from './manutenzione/manutenzione.component';
import { PresenzaTrasfertaComponent } from './presenza-trasferta/presenza-trasferta.component';
import { UpdateTrasfertaComponent } from './update-trasferta/update-trasferta.component';
import { PresenzaTrasfertEstrazioneComponent } from './presenza-trasfert-estrazione/presenza-trasfert-estrazione.component';
import { MeselocktrasfertaComponent } from './meselocktrasferta/meselocktrasferta.component';
import { AvailabilityComponent } from './availability/availability.component';
import { AvailfullComponent } from './availfull/availfull.component';
import { EstraisummaryComponent } from './estraisummary/estraisummary.component';
import { ContrattiComponent } from './dialog/contratti/contratti.component';
import { RolesComponent } from './roles/roles.component'
import { VisitmedicheComponent } from './visitmediche/visitmediche.component'
import { TrainingComponent } from './training/training.component'
import { RolesettingsComponent } from './rolesettings/rolesettings.component';
import { AdmintrainingComponent } from './admintraining/admintraining.component';
import { RubricaComponent } from './rubrica/rubrica.component';
const routes: Routes = [
  { path: 'admintraining', canActivate: [AuthGuard], component: AdmintrainingComponent},
  { path: 'rolesettings', canActivate: [AuthGuard], component: RolesettingsComponent},
  { path: 'training', canActivate: [AuthGuard], component: TrainingComponent},
  { path: 'visitmediche', canActivate: [AuthGuard], component: VisitmedicheComponent},
  { path: 'roles', canActivate: [AuthGuard], component: RolesComponent},
  { path: 'contratti', canActivate: [AuthGuard], component: ContrattiComponent},
  { path: 'estraisummary', canActivate: [AuthGuard], component: EstraisummaryComponent},
  { path: 'meselocktrasferta', canActivate: [AuthGuard], component: MeselocktrasfertaComponent},
  { path: 'estraitrasferta', canActivate: [AuthGuard], component: PresenzaTrasfertEstrazioneComponent},
  { path: 'updatetrasferta', canActivate: [AuthGuard], component: UpdateTrasfertaComponent},
  { path: 'trasferta', canActivate: [AuthGuard], component: PresenzaTrasfertaComponent},
  { path: 'client', canActivate: [AuthGuard], component: ClientAdminComponent},
  { path: 'company', canActivate: [AuthGuard], component: CompanyAdminComponent},
  { path: 'tsprogetti', canActivate: [AuthGuard], component: TsProgettiComponent },
  { path: 'tslegalentity', canActivate: [AuthGuard], component: TsLegalEntityComponent },
  { path: 'tsactivity', canActivate: [AuthGuard], component: TsActivityComponent },
  { path: 'certificato', canActivate: [AuthGuard], component: CertificatoComponent },
  { path: 'login', component: LoginComponent},
  { path: 'primoaccesso', component: PrimoAccessoComponent },
  { path: 'menu', canActivate: [AuthGuard], component: MenuComponent },
  { path: 'registrazione', component: RegisterComponent },
  { path: 'settings', canActivate: [AuthGuard], component: SettingsComponent },
  { path: 'LATE_ENTRY', canActivate: [AuthGuard], component: EntrataInRitardoComponent },
  { path: 'PERMISSION', canActivate: [AuthGuard], component: PermessoComponent },
  { path: 'AVAILABILITY', canActivate: [AuthGuard], component: AvailabilityComponent },
  { path: 'PERMISSION_ENTRY', canActivate: [AuthGuard], component: PermessoInIngressoComponent },
  { path: 'PERMISSION_EXIT', canActivate: [AuthGuard], component: PermessoInUscitaComponent },
  { path: 'VACANTION_1(DAY)', canActivate: [AuthGuard], component: FerieUnGiornoComponent },
  { path: 'VACANTIONS_+(DAYS)', canActivate: [AuthGuard], component: FeriePiuGiorniComponent },
  { path: 'ABSENCE_1(DAY)', canActivate: [AuthGuard], component: AssenzaUnGiornoComponent },
  { path: 'ABSENCES_+(DAYS)', canActivate: [AuthGuard], component: AssenzaPiuGiorniComponent },
  { path: 'OVERTIME', canActivate: [AuthGuard], component: StraordinarioComponent },
  { path: 'PLAN_VACANTION_1(DAY)', canActivate: [AuthGuard], component: PianoFerieUnGiornoComponent },
  { path: 'PLAN_VACANTIONS_+(DAYS)', canActivate: [AuthGuard], component: PianoFeriePiuGiorniComponent },
  { path: 'updaterep', canActivate: [AuthGuard], component: UpdateRepComponent },
  { path: 'reperibilita', canActivate: [AuthGuard], component: ReperibilitaComponent },
  { path: 'welcome', canActivate: [AuthGuard], component: WelcomeComponent },
  { path: 'preview', canActivate: [AuthGuard], component: PreviewComponent },
  { path: 'admin', canActivate: [AuthGuard], component: AdminComponent },
  { path: 'estrairep', canActivate: [AuthGuard], component: EstraiRepComponent },
  { path: 'meselock', canActivate: [AuthGuard], component: MeselockComponent },
  { path: 'updateaccount', canActivate: [AuthGuard], component: UpdateProfileComponent },
  { path: 'progetti', canActivate: [AuthGuard], component: ProgettiComponent },
  { path: 'nuovaanagrafica', canActivate: [AuthGuard], component: NuovaanagraficaComponent },
  { path: 'updateanagrafica', canActivate: [AuthGuard], component: UpdateanagraficaComponent },
  { path: 'updateanagraficaadmin', canActivate: [AuthGuard], component: UpdateadminanagraficaComponent },
  { path: 'updateproject', canActivate: [AuthGuard], component: UpdateProgettoComponent },
  { path: 'fileupload', canActivate: [AuthGuard], component: FileUploadComponent },
  { path: 'summary', canActivate: [AuthGuard], component: SummaryComponent },
  { path: 'previewcertificati', canActivate: [AuthGuard], component: PreviewCertificatiComponent },
  { path: 'manutenzione', canActivate: [AuthGuard], component: ManutenzioneComponent },
  { path: 'availfull', canActivate: [AuthGuard], component: AvailfullComponent },
  { path: 'rubrica', canActivate: [AuthGuard], component: RubricaComponent },
  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
