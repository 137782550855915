import { Component, OnInit, ViewChild } from '@angular/core';
import { Estrairep } from '../shared/estrairep.model';
import { AuthService } from '../auth.service';
import { ExcelService } from '../services/excel.service';
import { DatePipe} from '@angular/common';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from 'angular2-notifications';
import { HttpClient} from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent4 } from './dialog/dialog.component';
import { Summary } from 'src/app/shared/summary.model';
import { Inserttemptable } from 'src/app/shared/inserttemptable.model';
import { UploadService } from '../certificato/upload.service';
import { Email } from '../shared/email.model';
import * as XLSX from 'xlsx';
import { FormControl, Validators } from '@angular/forms';
const dateformat = require('dateformat');
export interface anno {
  value: string;
  viewValue: string;
}
export interface mese {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-estraisummary',
  templateUrl: './estraisummary.component.html',
  styleUrls: ['./estraisummary.component.scss']
})
export class EstraisummaryComponent implements OnInit {
  data: [][];
  tempcheck= [];
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  email: Email = new Email();
  uploadedFiles: Array<File>;
  tableColumns: string[] = ['id', 'data', 'Risorsa', 'Comunicazione', 'Ore', 'Specifica', 'Ore_Specifica'];
  tableColumns1: string[] = ['TS_DATE','NAME','HOURS','PRJCODE', 'EMAIL'] //, 'cognome', 'nome', 'ore', 'specifica', 'id_utente'

  summary: Summary = new Summary();
  temptable: Inserttemptable = new Inserttemptable();
  finish: any;
  company: any;
  varenabledata = false;
  list : any[];
  willDownload = false;
  // tslint:disable: variable-name
  mese_web: any = this.datePipe.transform(new Date(), 'MM');

  selectfile = false;
  anno_web: any = this.datePipe.transform(new Date(), 'yyyy');
  id_utente: any;
  setting: Estrairep = new Estrairep();
  lock: any;
  anni: anno[] = [
    { value: '2019', viewValue: '2019' },
    { value: '2020', viewValue: '2020' },
    { value: '2021', viewValue: '2021' },
    { value: '2022', viewValue: '2022' },
    { value: '2023', viewValue: '2023' },
    { value: '2024', viewValue: '2024' }
  ];
  mesi: mese[] = [
    { value: '01', viewValue: 'Gennaio' },
    { value: '02', viewValue: 'Febbraio' },
    { value: '03', viewValue: 'Marzo' },
    { value: '04', viewValue: 'Aprile' },
    { value: '05', viewValue: 'Maggio' },
    { value: '06', viewValue: 'Giugno' },
    { value: '07', viewValue: 'Luglio' },
    { value: '08', viewValue: 'Agosto' },
    { value: '09', viewValue: 'Settembre' },
    { value: '10', viewValue: 'Ottobre' },
    { value: '11', viewValue: 'Novembre' },
    { value: '12', viewValue: 'Dicembre' },
  ];
  estrazione_excel1: any;
  estrazione_excel: any;
  reperibilita: any;
  percentDone: number;
  dataSource = new MatTableDataSource();
  dataSource1= new MatTableDataSource();
  rispostacheck: any[] = [];
  uploadSuccess: boolean;
  http: any;
  fromdate:any;
  todate:any;
  incongruence: boolean;
  arrayincongruence: any[];
  corpo_email: string;
  oggetto_email: string;
  passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl1 = new FormControl('', [
    Validators.required
  ]);
  constructor(public dialogRef: MatDialog,
              private httpClient: HttpClient,
              public uploadService: UploadService,
              private _router: Router,
              public _storage: MyLocalStorageService,
              private datePipe: DatePipe,
              public _auth: AuthService,
              private excelService: ExcelService,
              private _service: NotificationsService) { }

  ngOnInit() {
    this.incongruence = false;
    this.selectfile = false;
    this.company = localStorage.getItem('company');
    // tslint:disable: no-shadowed-variable
    this.summary = {
      id:'',
      id_utente: null,
			data: '',
			giorno: '',
			mese: '',
			anno:'',
      tipo_comunicazione:'',
			certificato:'',
			idriga: '',
			specifica: '',
			orespecifica: '',
			starttime: '',
			finishtime: ''};
    const mese = this.datePipe.transform(new Date(), 'MM');
    const anno = this.datePipe.transform(new Date(), 'yyyy');
    this._storage.setItem('mese', mese);
    this._storage.setItem('anno', anno);
    this.mese_web = this._storage.getItem('mese');
    this.anno_web = this._storage.getItem('anno');
    this.loadsummaryadmin();
  }
  loadsummaryadmin() {

    this.summary.mese = this._storage.getItem('mese');
		  // tslint:disable: indent
		  this.summary.anno =  this._storage.getItem('anno');
		  this._auth.summary_report_admin(this.summary).subscribe(
			res => {
				if (res.risposta === 'no value') {
					this.dataSource = new MatTableDataSource([]);
				} else {
					this.dataSource = new MatTableDataSource(res);
				}
			},
			err => console.log(err)
		);
  }
  admin() {
    this._router.navigate(['/admin'], { skipLocationChange: true });
  }
  changemese(e) {
    this._storage.setItem('mese', e);
    this.summary.mese = this._storage.getItem('mese')
    this.summary.anno = this._storage.getItem('anno')

    this.loadsummaryadmin();
  }
  changeanno(e) {
    this._storage.setItem('anno', e);
    this.summary.mese = this._storage.getItem('mese')
    this.summary.anno = this._storage.getItem('anno')

    this.loadsummaryadmin();
  }
  async preestrazionesummary() {
    let temp = await this._auth.loadavailabilityfull('').toPromise();
    let data = await this.preestrazione1(temp);
    this.estrazionefinalesummary();
  }
  async preestrazione1(data) {

    for (let index = 0; index < data.length; index++) {
      const element = data[index];

    }
    this.setting.mese = this.mese_web;
    this.setting.anno = this.anno_web;
    this.estrazione_excel1 = await this._auth.estrairep(this.setting).toPromise();
    this.estrazione_excel = await this._auth.estrairepfull(this.setting).toPromise();
    this.exportAsXLSX(this.estrazione_excel, this.estrazione_excel1);
  }
  async estrai() {
    this.setting.mese = this.mese_web;
    this.setting.anno = this.anno_web;
    await this._auth.estrairep(this.setting).subscribe(
      res => {
        this.estrazione_excel1 = res;
      },
      err => console.log(err)
    );
  }
  async estraifull() {
    this.setting.mese = this.mese_web;
    this.setting.anno = this.anno_web;
    await this._auth.estrairepfull(this.setting).subscribe(
      res => {
        this.estrazione_excel = res;

      },
      err => console.log(err)
    );
  }
  exportAsXLSX(data, data1): void {
    this.excelService.exportAsExcelFile(data, data1, 'Summary_Report');
    // this.loadsummary();
  }
  estrazionecustomsummary() {
    const dialogRef = this.dialogRef.open(DialogComponent4, {
      width: '350px'
    });
  }
  async estrazionefinalesummary() {
    this.setting.mese = this.mese_web;
    this.setting.anno = this.anno_web;
   // this.estrazione_excel1 = await this._auth.estrairep(this.setting).toPromise();
   // this.estrazione_excel = await this._auth.estrairepfull(this.setting).toPromise();
    this.exportAsXLSX(this.estrazione_excel, this.estrazione_excel1);
  }


  onFileChange(element) {
    this.varenabledata = false
    const target : DataTransfer =  <DataTransfer>(element.target);

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = async (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname : string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      this.data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
      this.data.shift()
      //console.log(this.data.length)
    };
    reader.readAsBinaryString(target.files[0]);
    //console.log(this.dataSource.filteredData)
    this.uploadedFiles = element.target.files;
      //this.upload();

      this.summary.starttime = dateformat(this.fromdate, 'yyyy-mm-dd' )
      this.summary.finishtime = dateformat(this.todate, 'yyyy-mm-dd');
    this.sendtobackend(this.summary)
  }

  sendtobackend(summary) {
      let formData = new FormData();
      formData.append('nome', 'check');
			for (var i = 0; i < this.uploadedFiles.length; i++) {
				formData.append('uploads[]', this.uploadedFiles[i], this.uploadedFiles[i].name);
			}

			this._auth.upload(formData).subscribe(response => {
				if (response.message == 'File uploaded successfully') {
          console.log("fileupdated")
			    this.sendtobackend1(summary);
				}
			});


	}
    sendtobackend1(summary) {
      this.incongruence = true;
        this._auth.insercheckts(summary).subscribe(
          res => {
            if (res.risposta === 'no value') {
              this.dataSource1 = new MatTableDataSource([]);
            } else {
              this.dataSource1 = new MatTableDataSource(res);
            }
          },
          err => console.log(err)
        );
  }

  incongruencefunction(){
    let results_size = Object.keys(this.rispostacheck).length

    for(var i = 0; i < results_size; i++) {
      this.list.push(JSON.stringify(this.rispostacheck[i]))
    }
  }
  readexcel(){
    this.selectfile = true;
  }
  ExcelDateToJSDate(date) {
    return new Date(Math.round((date - 25569)*86400*1000));
  }
  enabledata() {
    this.varenabledata = true;
  }
  sendmail() {
   // this.arrayincongruence = this.dataSource1;
    this.arrayincongruence.forEach(async element => {
      this.corpo_email =
      '<font face="Calibri"><p>Hi,</p><p>the present to let you know that missing a comunication for <b>' +
      element.tipo +
      '</b>, on date <b>' +
      element.date+
      '</b>, for a total of <b> ' +
      element.ore +
      '</b></p></font>'
      this.oggetto_email = 'Incongruence on TS check ';
            this.email.corpo_email_send = this.corpo_email;
	        	this.email.subject_email_send = this.oggetto_email;
		        this.email.destarray = element.email;
            this.email.resource_name = element.cognome + ' ' + element.nome;
            this.email.email = 'personale@emminformatica.it'
            this._auth.invioemailinterni(this.email).subscribe(
              res => {
                if (res.status == 'SUCCESS') {
                  //this._router.navigate(['/welcome'], { skipLocationChange: true });
                  this._service.success('Success', 'An Email of was sent', {
                    position: ['bottom', 'center'],
                    timeOut: 5000,
                    showProgressBar: true,
                    pauseOnHover: false,
                    clickToClose: true,
                  });
                } else {
                  this._service.error('Errore', 'Ops... somethings gone wrong, I believe your password is wrong', {
                    position: ['bottom', 'center'],
                    timeOut: 5000,
                    showProgressBar: true,
                    pauseOnHover: false,
                    clickToClose: true,
                  });

                }
              },
              err => console.log(err)
            );
            });
  }
}

