import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { HttpClient } from '@angular/common/http';
import { Email } from '../shared/email.model';

@Component({
	// tslint:disable:indent
	selector: 'app-preview-certificati',
	templateUrl: './preview-certificati.component.html',
	styleUrls: ['./preview-certificati.component.scss'],
})
export class PreviewCertificatiComponent implements OnInit {
	sendcheck: any = '0';
	// tslint:disable:variable-name
	oggetto_email: any;
	nome: any;
	cognome: any;
	corpo_email: any;
	email: Email = new Email();
	request: any;
	day: any;
	hours: any;
	company: any;
	entry_time: any;
	reason: any;
	destinataritable: any[] = [];
	desttable: [];
	destinatari: any;
	destinatari_conoscenza: any = '';
	formattedDate: any;
	file_name: any;
	num_protocollo: any;
	id_utente: any;
	provider_email: any;
	exit_time: any;
	return_time: any;
	concordato: any;
	to_day: any;
	timesheet: any;
	attivita: any;
	minuti: any = '';
	temp: any;
	tiporichiesta: any;
	ruolo: any;
	tempdestinatari: any;
	firma: any;
	custom_reason: any;
	resource_name: any;
	resource_email: any;
  wait: any;

	constructor(
		public _storage: MyLocalStorageService,
		private router: Router,
		public _auth: AuthService,
		private _service: NotificationsService,
		private http: HttpClient
	) {}

	ngOnInit() {
    this.wait = '0';
    this.ruolo = localStorage.getItem('ruolo');
		  this.tiporichiesta = localStorage.getItem('tipo_certificato').replace(/_/g, ' ');
		  this.company = localStorage.getItem('company');
		  this.to_day = this._storage.getItem('data_al');
		  this.request = this._storage.getItem('tipo_certificato');
		  this.day = this._storage.getItem('data');
		  this.resource_email = this._storage.getItem('email');
		  this.file_name = this._storage.getItem('file_name');
		  this.num_protocollo = this._storage.getItem('protocollo');
		  this.id_utente = this._storage.getItem('id');
		  this.provider_email = this._storage.getItem('email_certificati');
		  this.cognome = this._storage.getItem('cognome');
    this.nome = this._storage.getItem('nome');
    this.firma =
			'<font face="Calibri" color="#002060">Regards,<br><b>' +
			this.nome +
			' ' +
			this.cognome +
			'</b></font><br><font face="Calibri" color="#002060"><b> ' +
			this.ruolo +
			// tslint:disable-next-line:max-line-length
			',<br>' + this._storage.getItem('companyfullname') + '</b></font><br><br><font face="Calibri" color="#002060"><b>' + this._storage.getItem('indirizzocompany') + '</font>';
		  if (this.tiporichiesta === 'Malattia') {
			(this.oggetto_email =
				'MALATTIA DAL ' +
				this.day +
				' AL ' +
				this.to_day +
				' - PROTOCOLLO ' +
				this.num_protocollo), (this.corpo_email =
				'<p>Salve,</p><p>&ensp;&ensp;&ensp;&ensp;Vi comunico che il mio numero di protocollo è <b>' +
				this.num_protocollo +
				'</b>.<p>&ensp;&ensp;&ensp;&ensp;<i>Tale certificato è relativo al periodo di assenza dal ' +
				this.day +
				' al ' +
				this.to_day +
        '.</i></p>'
        +
				this.firma);
		} else {
			(this.oggetto_email =
				'CERTIFICATO DAL ' + this.day + ' AL ' + this.to_day + ' - ' + this.tiporichiesta), (this.corpo_email =
				'<p>Salve,</p><p>&ensp;&ensp;&ensp;&ensp;in allegato troverete il mio Certificato per <b>' +
				this.tiporichiesta +
				'</b>.<p>&ensp;&ensp;&ensp;&ensp;<i>Tale certificato è relativo al periodo di assenza dal ' +
				this.day +
				' al ' +
				this.to_day +
				'.</i></p>' +
				this.firma);
		}
	}

	invioemail() {
		this.wait = '1';
		this.email.tiporichiesta = this._storage.getItem('tipo_certificato');
		this.email.username = this._storage.getItem('email');
		this.email.file_name = this._storage.getItem('file_name');
		this.email.data = this._storage.getItem('data');
		this.email.dataal = this._storage.getItem('data_al');
		this.email.num_protocollo = this._storage.getItem('protocollo');
		this.email.nome = this._storage.getItem('nome');
		this.email.id_utente = this._storage.getItem('id');
		this.email.cognome = this._storage.getItem('cognome');
		this.email.email = this._storage.getItem('email');
		this.email.email_certificati = this._storage.getItem('email_certificati');
		this._auth.inviocertificato(this.email).subscribe(
			res => {
				// tslint:disable:triple-equals
				if (res.status == 'SUCCESS') {
					this._service.success('Success', 'Mail per ' + this.email.tiporichiesta + ' inviata', {
						position: ['bottom', 'center'],
						timeOut: 5000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
					this.salvacertificato();
					this.wait = '0';
				} else {
					this._service.error('Errore', 'Ops... somethings gone wrong, I believe your password is wrong', {
						position: ['bottom', 'center'],
						timeOut: 5000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);
	}

	salvacertificato() {
		console.log('salva certificato');
		this._auth.salvacertificato(this.email).subscribe(
			res => {
				if (res.risposta == 'Success') {
					this.router.navigate(['/welcome']);
				} else {
					this._service.error('Errore', 'Ops... somethings gone wrong, I believe your password is wrong', {
						position: ['bottom', 'center'],
						timeOut: 5000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);
	}

	back() {
		this.router.navigate(['/fileupload'], { skipLocationChange: true });
	}
}
