export class Anagrafica {
    nome: any;
    cognome: any;
    // tslint:disable: variable-name
    posta_elettronica: any;
    password: any = 'firstpassword';
    tel: any;
    company: any;
    customer_team: any;
    verified: any = '0';
    profile: any = 'user'
    role: any;
    gender: any;
    customer_mail: any;
    customer: any;
    status: any;
    id: any;
    id_resource: any;
    citta: any;
    codicefiscale: any;
    datadinascita: any;
    luogodinascita: any;
    comunediresidenza: any;
    indirizzo: any;
    tipocontratto: any;
    note: any;
    datainizio: any;
    datafine: any;
    welfare: any;
    cral: any;
    datavisitamedica: any;
}
