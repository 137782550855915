export class Email {
  data: any;
  dataal: any;
  ore: any;
  orario_ingresso: any;
  motivo: any;
  tiporichiesta: any;
  destinatari: any;
  linguaemail: any;
  nome: any;
  cognome: any;
  concordato: any;
  orario_uscita: any;
  variabile_attivita: any;
  variabile_timesheet: any;
  minuti_straordinario: any;
  email:any;
  id_utente: any;
  corpo_email_send: any;
  subject_email_send:any;
  username:any;
  destarray:any;
  lunghezza:any;
  num_protocollo:any;
  file_name:any;
  email_certificati:any;
  company:any;
  resource_name:any;
  resource_email:any;
  password: any;
  wbaoneit: any;
  giorno: any;
  mese: any;
  anno: any;
  dataavailability: any;
  id: any;
  clientemail: string;
  datetosend: any;
  specifica: any;
}
