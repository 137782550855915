import { Injectable } from '@angular/core';

@Injectable()
export class MyLocalStorageService {
    private _registeredTokens: string[] = [];

    constructor() {}

    public setItem(key: string, value: string) {
        this._registeredTokens.push(key); 
        localStorage.setItem(key, value);
    }

    public setItemNoRegistry(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    public getItem(key: string) {
        return localStorage.getItem(key);
    }
    public removeItem(key: string){
        return localStorage.removeItem(key);
    }
    public clearRegistered() {
        let index: number;
        for (index = 0; index < this._registeredTokens.length; index++) {
            localStorage.removeItem(this._registeredTokens[index]);
        }
        this._registeredTokens = [];
    }

    public clearAll() {
        localStorage.clear();
    }

}
