<br>
<div style="text-align:center">
  <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
  <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<div align="center">
  <label style="font-size: 20px;color: rgb(103, 150, 238)"> {{tiporichiesta}}</label>
  <br>
  <mat-card class="example-card" style="width: 35%; background-color: rgb(153, 218, 238)" >
  <mat-form-field style="width: 100%;">
    <input matInput [matDatepicker]="picker" [(ngModel)]="variabile_data" placeholder="Late Entry DATE"
      [formControl]="passwordFormControl" required>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <br>
  <mat-form-field style="width: 100%;">
    <mat-label>Late Entry HOURS</mat-label>
    <mat-select [(ngModel)]="variabile_ora" [formControl]="passwordFormControl1" required>
      <mat-option *ngFor="let ora of ore" [value]="ora.value">
        {{ora.viewValue}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="passwordFormControl1.hasError('required')">Mandatory</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field style="width: 100%;">
      <mat-label>Late Entry REASON</mat-label>
      <mat-select [(ngModel)]="variabile_motivo" [formControl]="passwordFormControl3" required>
        <mat-option *ngFor="let motivo of motivi" [value]="motivo.value">
          {{motivo.viewValue}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="passwordFormControl3.hasError('required')">Mandatory</mat-error>
    </mat-form-field>
  <br>
  <mat-label>Entry Time</mat-label>
  <input style="width: 100%; background-color: rgb(153, 218, 238); text-align: center;" class="timepicker" [(ngModel)]="variabile_orario" value="variabile_orario" (click)="open($event)" [formControl]="passwordFormControl2" required/>
  <mat-error *ngIf="passwordFormControl2.hasError('required')">Mandatory</mat-error>
  <br>
</mat-card>
  <div align="center">
    <a [routerLink]="['/menu']"><button style="margin:5px" mat-raised-button color="primary">Back</button></a>
    <button style="margin:5px" mat-raised-button color="primary" (click)="preview()"
      [disabled]="!passwordFormControl.valid || !passwordFormControl1.valid || !passwordFormControl2.valid || !passwordFormControl3.valid">Preview</button>
  </div>
</div>
