import { Component, OnInit } from '@angular/core';
import { Changepwd } from '../shared/changepwd';
import { NgForm, FormControl, FormGroupDirective, FormGroup, Validators, EmailValidator } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from '../auth.service';
import {EncrDecrService} from '../services/EncrDecrService'

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  company: any;
  firstFormGroup: FormGroup;
  confirmnewpassword: any;
  newpassword:any;
  temporanypassword:any;
  changepwd: Changepwd = new Changepwd();
  emailFormControl = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  mandatoryFormControl = new FormControl('', [
    Validators.required
  ]);
  mandatoryFormControl1 = new FormControl('', [
    Validators.required
  ]);
  matcher = new MyErrorStateMatcher();
  constructor(private EncrDecr: EncrDecrService,private http: HttpClient, private _service: NotificationsService,  private router: Router, public _auth: AuthService) { }
  ngOnInit() {this.company = localStorage.getItem('company');}

  changepassword(){

    this.changepwd.oldpassword = this.EncrDecr.set(this.temporanypassword.toString(), 'accamieasyjob');
    this.changepwd.newpassword = this.EncrDecr.set(this.newpassword.toString(), 'accamieasyjob');
    this.changepwd.confirmnewpassword = this.EncrDecr.set(this.confirmnewpassword.toString(), 'accamieasyjob');
    this.changepwd.email = localStorage.getItem('email');
   this._auth.registerUser(this.changepwd)
    .subscribe((val) => {
      console.log("POST call successful value returned in body",
      val);
      var risposta = val.status;
      if (risposta === "Success") {
        this._service.success('Success',
        'Password changed successfully',{
          position: ["bottom", "center"],
          timeOut: 5000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this.router.navigate(['/login'])
      } else {
        this._service.error('Errore',
        'Error contact an administrator',
        {
          position: ["bottom", "center"],
          timeOut: 5000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
    })
    console.log(this.changepwd);
  }
}
