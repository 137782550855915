<br>
<div style="text-align:center">
  <img width="10%"  *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
  <img width="5%"  *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<mat-card class="example-card" style="width: 100%; background-color: rgb(153, 218, 238)">
    <br>
    <mat-form-field>
      <mat-label>Seleziona anno</mat-label>
      <mat-select [(ngModel)]="anno_web">
        <mat-option *ngFor="let anno of anni" [value]="anno.value" [(value)]="anno_web">
          {{anno.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Seleziona mese</mat-label>
        <mat-select [(ngModel)]="mese_web">
          <mat-option *ngFor="let mese of mesi" [value]="mese.value" [(value)]="mese_web">
            {{mese.viewValue}}
          </mat-option> 
        </mat-select>
      </mat-form-field>
        <br>
        <a (click)="admin()"><button mat-raised-button color="primary">Back</button></a>
        <button mat-raised-button color="primary" (click) = "check_lock()"><mat-icon>block</mat-icon>Blocca mese</button>
  <br>
        <mat-table style="border: 1px;border-color: black;border-style: solid" [dataSource]="dataSource" style="width: 100%"  matSort matSortStart="desc">
          <ng-container  matColumnDef="mese">
            <mat-header-cell *matHeaderCellDef>Mese</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.mese}}</mat-cell>
        </ng-container>
          <ng-container  matColumnDef="anno">
            <mat-header-cell *matHeaderCellDef>Anno</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.anno}}</mat-cell>
        </ng-container>
        <ng-container  matColumnDef="id">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row" ><button mat-raised-button color="warn" (click)="meseunlock1(row.id)"><mat-icon>check_circle</mat-icon>Sblocca</button></mat-cell>
      </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
        </mat-table>