import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { ExcelService } from '../services/excel.service';
export interface settings {
  startdate: string;
  todate: string;
  mese: string;
}
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent2 {
  starttimeweb: any;
  endtimeweb: any;
  startdateweb: any;
  enddateweb: any;
  // tslint:disable: variable-name
  estrazione_excel: any;
  setting: settings = {startdate: '', todate: '',mese: ''};
  estrazione_excel1: any;
  constructor(public dialogRef: MatDialogRef<DialogComponent2>, public _auth: AuthService, private excelService: ExcelService, private datePipe: DatePipe,) { }

  async doaction() {
    this.setting.startdate = this.startdateweb + ' ' + this.starttimeweb + ':00';
    this.setting.todate = this.enddateweb + ' ' + this.endtimeweb + ':00';
    this.setting.mese = this.datePipe.transform(this.setting.todate, 'MM');
    this.estrazione_excel1 = await this._auth.estrairepcustomfull(this.setting).toPromise();
    this.estrazione_excel = await this._auth.estrairepcustom(this.setting).toPromise();
    this.exportAsXLSX(this.estrazione_excel, this.estrazione_excel1);
    this.dialogRef.close();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  exportAsXLSX(data, data1): void {
    this.excelService.exportAsExcelFile(data, data1, 'availability_EMM_Full');
  }
}

