export class Certificato {
    id:any;
    data_dal: Date;
    data_al: Date;
    n_protocollo:any;
    destinatari_to:any;
    destinatari_cc:any;
    oggetto_un_giorno:any;
    oggetto_piu_giorno:any;
    corpo:any; 
    giornoselezionato:any;
    email:string;
    nome:string;
    cognome:string;
    indirizzo:string;
    numerocivico:string;
    citta:string;
    cap:string;
    provincia:string;
    pwd:any;
}
