import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { MyLocalStorageService } from './localstorage/mylocalstorage.service';
import { environment } from '../environments/environment';
const tempenv: boolean = environment.production;
let url: any = '';
if (tempenv === true) {
	url = 'https://easyjob.ddns.net/be';
} else {
	url = 'https://dev.easyjob.ddns.net/be';
}
@Injectable()
export class AuthService {

	// tslint:disable: indent
	// tslint:disable:quotemark
	// tslint:disable: variable-name
	private _salvaanagrafica = url + '/anagrafica/salvaanagrafica';
	private _loadanagrafica = url + '/anagrafica/loadanagrafica';
	private _deleteanagrafica = url + '/anagrafica/deleteanagrafica';
	private _riattivaanagrafica = url + '/anagrafica/riattivaanagrafica';
	private _loadanagraficatoupdate = url + '/anagrafica/loadanagraficatoupdate';
  	private _rubrica = url + '/anagrafica/rubrica';
	private _updateanagraficaadmin = url + '/anagrafica/updateanagraficaadmin';
	private _updateprofile = url + '/anagrafica/updateprofile';
	private _loadcontratto = url + '/api/loadcontratto';
	private _update_userrole = url + '/api/update_userrole'
	private _newuserrole = url + '/api/newuserrole'
	private _updatecontratto = url + '/api/updatecontratto'
	private _deletecontratto = url + '/api/deletecontratto'
	private _newcontratto = url + '/api/newcontratto'
	private _loadroles = url + '/api/loadroles'
	private _updateroles = url + '/api/updateroles'
	private _deleteroles = url + '/api/deleteroles'
	private _newroles = url + '/api/newroles'
	private _insert_project = url + '/api/insert_project'
	private _loadrolesettings = url + '/api/loadrolesettings'
	private _insert_legal_entity = url + '/api/insert_legal_entity'
	private _loadtraining = url + '/api/loadtraining'
	// ------------------------
	private _registerUrl = url + '/login/registrazione';
	private _loginUrl = url + '/login/login';
	private _updatepwd = url + '/login/updatepwd';
	private _firstlogin = url + '/login/firstlogin';
	private _resetpwd = url + '/login/resetpwd';
	private _check_ore_progetto = url + '/login/check_ore_progetto';
	// ------------------------
	private _salvaavailability = url + '/reperibilita/salva_availability';
	private _salvareperibilita = url + '/reperibilita/salva_reperibilita';
	private _leggiperibilita = url + '/reperibilita/leggiperibilita';
	private _deletereperibilita = url + '/reperibilita/deletereperibilita';
	private _deleteavailability = url + '/reperibilita/deleteavailability';
	private _checkreperibilita = url + '/reperibilita/checkreperibilita';
	private _leggiperibilita_global = url + '/reperibilita/leggiperibilita_global';
	private _salvatrasferta = url + '/reperibilita/salva_trasferta';
	private _leggitrasferta = url + '/reperibilita/leggitrasferta';
	private _deletetrasferta = url + '/reperibilita/deletetrasferta';
	private _checktrasferta = url + '/reperibilita/checktrasferta';
	private _leggitrasferta_global = url + '/reperibilita/leggitrasferta_global';
	private _loadavailabilityfull = url + '/reperibilita/loadavailabilityfull';
	private _loadavailabilityfullforinsert = url + '/reperibilita/loadavailabilityfullforinsert';
	private _deleteavailabilityfull = url + '/reperibilita/deleteavailabilityfull';
	private _setavailabilityfull = url + '/reperibilita/setavailabilityfull';
	private _checkreperibilitafull = url + '/reperibilita/checkreperibilitafull';
	private _insertreperibilitafull = url + '/reperibilita/insertreperibilitafull';
	// -----------------------------
	private _deletesummary = url + '/api/deletesummary'
	private _insertoracle = url + '/api/storeoracle'
	private _savecustomersettings = url + '/api/savecustomersettings'
	private _saveuserrolesettings = url + '/api/saveuserrolesettings'
	private _insertcustomersettings = url + '/api/insertcustomersettings'
	private _insertuserrolesettings = url + '/api/insertuserrolesettings'
	private _loadvisibility = url + '/api/loadvisibility'
	private _inserttemptable = url + '/api/inserttemptable';
	private _checkts = url + '/api/checkts'
	private _readexcel = url + '/api/readexcel';
	private _summary_report_admin = url + '/api/summaryreportadmin';
	private _update_report = url + '/api/update_summary';
	private _loadcompany = url + '/api/loadcompany';
	private _loadcompanyemail = url + '/api/loadcompanyemail';
	private _loadclientemail = url + '/api/loadclientemail';
	private _loadcustomeremail = url + '/api/loadcustomeremail';
	private _check_progetto_ts = url + '/api/check_progetto_ts';
	private _loadsettings = url + '/api/loadsetting';
	private _savesettings = url + '/api/savesetting';
	private _invioemail = url + '/sendmail/invio_email';
	private _inviocertificato = url + '/sendmail/inviocertificato';
	private _invioemailnuovoutente = url + '/sendmail/invioemailnuovoutente';
	private _inviocertificatointerni = url + '/sendmail/inviocertificatointerni';
	private _invioemailinterni = url + '/sendmail/invioemailinterni';
	private _inviocertificatotest = url + '/sendmail/inviocertificatotest';
	private _salvacertificato = url + '/api/salvacertificato';
	private _estrairep = url + '/reperibilita/estrairep';
	private _estrairepfull = url + '/reperibilita/estrairepfull';
	private _estrairepfullcustom = url + '/reperibilita/estrairepfullcustom';
	private _estrairepcustom = url + '/reperibilita/estrairepcustom';
	private _estraitrasferta = url + '/reperibilita/estraitrasferta';
	private _estraitrasfertafull = url + '/reperibilita/estraitrasfertafull';
	private _deletedest = url + '/api/deletedest';
	private _loadproject = url + '/api/loadproject';
	private _loadproject1 = url + '/api/loadproject1';
	private _loadprojectinterni = url + '/api/loadprojectinterni';
	private _salvacomunicazioni = url + '/api/salvacomunicazioni';
	private _confirm_uncofirm = url + '/api/confirm_uncofirm';
	private _confirmreperibilita = url + '/api/confirmreperibilita';
	private _unconfirmreperibilita = url + '/api/unconfirmreperibilita';
	private _confirmtrasferta = url + '/api/confirmtrasferta';
	private _uncofirmtrasferta = url + '/api/uncofirmtrasferta';
	private _readlocked = url + '/api/readlocked';
	private _readlockedtrasferta = url + '/api/readlockedtrasferta';
	private _mese_lock = url + '/api/mese_lock';
	private _mese_unlock = url + '/api/mese_unlock';
	private _mese_lock1 = url + '/api/mese_lock1';
	private _mese_unlock1 = url + '/api/mese_unlock1';
	private _mese_locktrasferta = url + '/api/mese_locktrasferta';
	private _mese_unlocktrasferta = url + '/api/mese_unlocktrasferta';
	private _mese_lock1trasferta = url + '/api/mese_lock1trasferta';
	private _mese_unlock1trasferta = url + '/api/mese_unlock1trasferta';
	private _loaduser = url + '/api/loaduser';
	private _loadutente = url + '/api/loadutente';
	private _loadutenteforname = url + '/api/loadutenteforname';
	private _load_lock = url + '/api/load_lock';
	private _load_lock1 = url + '/api/load_lock1';
	private _load_locktrasferta = url + '/api/load_locktrasferta';
	private _check_lock = url + '/api/check_lock';
	private _check_locktrasferta = url + '/api/check_locktrasferta';
	private _saveproject = url + '/api/saveproject';
	private _deleteproject = url + '/api/deleteproject';
	private _updateprogetto = url + '/api/updateprogetto';
	private _load_progetti_ts = url + '/api/load_progetti_ts';
	private _disable_progetti_ts = url + '/api/disable_progetti_ts';
	private _enable_progetti_ts = url + '/api/enable_progetti_ts';
	private _enable_progetti_ts_code = url + '/api/enable_progetti_ts_code';
	private _loadentity = url + '/api/loadentity';
	private _update_progetti_ts = url + '/api/update_progetti_ts';
	private _updatesummary = url + '/api/updatesummary';
	private _nuovo_progetto_ts = url + '/api/nuovo_progetto_ts';
	private _load_entity_ts = url + '/api/load_entity_ts';
	private _disable_entity_ts = url + '/api/disable_entity_ts';
	private _enable_entity_ts = url + '/api/enable_entity_ts';
	private _update_entity_ts = url + '/api/update_entity_ts';
	private _nuovo_entity_ts = url + '/api/nuovo_entity_ts';
	private _load_activity_ts = url + '/api/load_activity_ts';
	private _disable_activity_ts = url + '/api/disable_activity_ts';
	private _enable_activity_ts = url + '/api/enable_activity_ts';
	private _update_activity_ts = url + '/api/update_activity_ts';
	private _nuovo_activity_ts = url + '/api/nuovo_activity_ts';
	private _loadcombohours = url + '/api/loadcombohours';
	private _loadprojectemployee = url + '/api/loadprojectemployee';
	private _loadprojectemployeeinterni = url + '/api/loadprojectemployeeinterni';
	private _check_ore = url + '/api/check_ore';
	private _saveprojectemployee = url + '/api/saveprojectemployee';
	private _saveprojectemployeeinterni = url + '/api/saveprojectemployeeinterni';
	private _deleteprojectts = url + '/api/deleteprojectts';
	private _deleteprojecttsinterni = url + '/api/deleteprojecttsinterni';
	private _loadprojectemployee_straord = url + '/api/loadprojectemployee_straord';
	private _loadprojectemployee_straordinterni = url + '/api/loadprojectemployee_straordinterni';
	private _loadprojectemployeeinterni_straord = url + '/api/loadprojectemployeeinterni_straord';
	private _loaddestinatari = url + '/api/loaddestinatari';
	private _loadclient = url + '/api/loadclient';
	private _loadclient_admin = url + '/api/loadclient_admin';
	private _loaduserrole = url + '/api/loaduserrole'
	private _loadcustsettings = url + '/api/loadcustsettings';
	private _loadcustrolesettings = url + '/api/loadcustrolesettings'
	private _enable_client = url + '/api/enable_client';
	private _disable_client = url + '/api/disable_client';
	private _update_client = url + '/api/update_client';
	private _new_client = url + '/api/new_client';
	private _loadcompany_admin = url + '/api/loadcompany_admin';
	private _enable_company = url + '/api/enable_company';
	private _disable_company = url + '/api/disable_company';
	private _update_company = url + '/api/update_company';
	private _new_company = url + '/api/new_company';
	private _reactivateproject = url + '/api/reactivateproject';
	private _delete_rep = url + '/api/delete_rep';
	private _delete_role = url + '/api/delete_role';
	private _delete_reptrasferta = url + '/api/delete_reptrasferta';
	private _check_file = url + '/api/check_file';
	private _upload = url + '/api/upload';
	private _uploadts = url + '/api/uploadts';
	private _summary_report = url + '/api/summary_report';
	private _estraisummarycustomfull = url + '/api/estraisummarycustomfull';
	private _download = url + '/api/download';
	private _deleteprogettidagliutenti = url + '/api/deleteprogettidagliutenti';
	private _salvasummary = url + '/api/salvasummary';
	private _insercheckts = url + '/api/insercheckts';
	private _loadcitta = url + '/api/loadcitta';

	constructor(private http: HttpClient, private _router: Router, public _storage: MyLocalStorageService) { }

	registerUser(user) {
		return this.http.post<any>(this._registerUrl, user);
	}

	loginUser(login) {
		/*	const httpOptions = {headers: new HttpHeaders()
				.set('Content-Type',  'application/json')
				.append('Authorization', 'Basic ' + btoa('admin:supersecret'))};
			console.log(httpOptions);*/
		return this.http.post<any>(this._loginUrl, login);
	}

	logoutUser() {
		this._storage.clearAll();
		this._router.navigate(['']);
		this._storage.setItem('firstlogin', 'false')
	}

	getToken() {
		return this._storage.getItem('token');
	}
	loadtraining(data) {
		return this.http.post<any>(this._loadtraining, data);
	}
	loggedIn() {
		return !!this._storage.getItem('token');
	}
	loadsetting(setting) {
		return this.http.post<any>(this._loadsettings, setting);
	}
	loaduserrole() {
		return this.http.post<any>(this._loaduserrole, '');
	}
	savesetting(setting) {
		return this.http.post<any>(this._savesettings, setting);
	}
	savecustomersettings(setting) {
		return this.http.post<any>(this._savecustomersettings, setting);
	}
	saveuserrolesettings(setting) {
		return this.http.post<any>(this._saveuserrolesettings, setting);
	}
	loadcitta() {
		return this.http.post<any>(this._loadcitta, '');
	}
	insertcustomersettings(setting) {
		return this.http.post<any>(this._insertcustomersettings, setting);
	}
	insertuserrolesettings(setting) {
		return this.http.post<any>(this._insertuserrolesettings, setting);
	}
	loadvisibility(setting) {
		return this.http.post<any>(this._loadvisibility, setting);
	}
	invioemail(setting) {
		return this.http.post<any>(this._invioemail, setting);
	}
	inviocertificato(setting) {
		return this.http.post<any>(this._inviocertificato, setting);
	}
	inviocertificatointerni(setting) {
		return this.http.post<any>(this._inviocertificatointerni, setting);
	}
	invioemailinterni(setting) {
		return this.http.post<any>(this._invioemailinterni, setting);
	}

	inviocertificatotest(setting) {
		return this.http.post<any>(this._inviocertificatotest, setting);
	}
	salvareperibilita(setting) {
		return this.http.post<any>(this._salvareperibilita, setting);
	}
	leggiperibilita(setting) {
		return this.http.post<any>(this._leggiperibilita, setting);
	}
	deletereperibilita(setting) {
		return this.http.post<any>(this._deletereperibilita, setting);
	}
	checkreperibilita(setting) {
		return this.http.post<any>(this._checkreperibilita, setting);
	}
	salvatrasferta(setting) {
		return this.http.post<any>(this._salvatrasferta, setting);
	}
	leggitrasferta(setting) {
		return this.http.post<any>(this._leggitrasferta, setting);
	}
	deletetrasferta(setting) {
		return this.http.post<any>(this._deletetrasferta, setting);
	}
	checktrasferta(setting) {
		return this.http.post<any>(this._checktrasferta, setting);
	}
	deletedest(setting) {
		return this.http.post<any>(this._deletedest, setting);
	}
	updatepwd(setting) {
		return this.http.post<any>(this._updatepwd, setting);
	}
	firstlogin(setting) {
		return this.http.post<any>(this._firstlogin, setting);
	}
	resetpwd(setting) {
		return this.http.post<any>(this._resetpwd, setting);
	}
	loadrolesettings(settings) {
		return this.http.post<any>(this._loadrolesettings, settings);
	}
	loadproject(setting) {
		return this.http.post<any>(this._loadproject, setting);
	}
	loadproject1(setting) {
		return this.http.post<any>(this._loadproject1, setting);
	}
	loadprojectinterni(setting) {
		return this.http.post<any>(this._loadprojectinterni, setting);
	}
	estrairep(setting) {
		return this.http.post<any>(this._estrairep, setting);
	}
	estrairepfull(setting) {
		return this.http.post<any>(this._estrairepfull, setting);
	}
	estrairepcustom(setting) {
		return this.http.post<any>(this._estrairepcustom, setting);
	}
	estrairepcustomfull(setting) {
		return this.http.post<any>(this._estrairepfullcustom, setting);
	}
	estraitrasferta(setting) {
		return this.http.post<any>(this._estraitrasferta, setting);
	}
	estraitrasfertafull(setting) {
		return this.http.post<any>(this._estraitrasfertafull, setting);
	}
	salvacomunicazioni(setting) {
		return this.http.post<any>(this._salvacomunicazioni, setting);
	}
	leggiperibilita_global(setting) {
		return this.http.post<any>(this._leggiperibilita_global, setting);
	}
	leggitrasferta_global(setting) {
		return this.http.post<any>(this._leggitrasferta_global, setting);
	}
	confirm_uncofirm(setting) {
		return this.http.post<any>(this._confirm_uncofirm, setting);
	}
	confirmtrasferta(setting) {
		return this.http.post<any>(this._confirmtrasferta, setting);
	}
	uncofirmtrasferta(setting) {
		return this.http.post<any>(this._uncofirmtrasferta, setting);
	}
	readlocked(setting) {
		return this.http.post<any>(this._readlocked, setting);
	}
	readlockedtrasferta(setting) {
		return this.http.post<any>(this._readlockedtrasferta, setting);
	}
	mese_lock(setting) {
		return this.http.post<any>(this._mese_lock, setting);
	}
	mese_unlock(setting) {
		return this.http.post<any>(this._mese_unlock, setting);
	}
	mese_lock1(setting) {
		return this.http.post<any>(this._mese_lock1, setting);
	}
	mese_unlock1(setting) {
		return this.http.post<any>(this._mese_unlock1, setting);
	}
	mese_locktrasferta(setting) {
		return this.http.post<any>(this._mese_locktrasferta, setting);
	}
	mese_unlocktrasferta(setting) {
		return this.http.post<any>(this._mese_unlocktrasferta, setting);
	}
	mese_lock1trasferta(setting) {
		return this.http.post<any>(this._mese_lock1trasferta, setting);
	}
	mese_unlock1trasferta(setting) {
		return this.http.post<any>(this._mese_unlock1trasferta, setting);
	}
	loaduser(setting) {
		return this.http.post<any>(this._loaduser, setting);
	}
	updateprofile(setting) {
		return this.http.post<any>(this._updateprofile, setting);
	}
	load_lock(setting) {
		return this.http.post<any>(this._load_lock, setting);
	}
	load_lock1(setting) {
		return this.http.post<any>(this._load_lock1, setting);
	}
	load_locktrasferta(setting) {
		return this.http.post<any>(this._load_locktrasferta, setting);
	}
	check_lock(setting) {
		return this.http.post<any>(this._check_lock, setting);
	}
	check_locktrasferta(setting) {
		return this.http.post<any>(this._check_locktrasferta, setting);
	}
	saveproject(setting) {
		return this.http.post<any>(this._saveproject, setting);
	}
	deleteproject(setting) {
		return this.http.post<any>(this._deleteproject, setting);
	}
	salvaanagrafica(setting) {
		return this.http.post<any>(this._salvaanagrafica, setting);
	}
	loadanagrafica(setting) {
		return this.http.post<any>(this._loadanagrafica, setting);
	}
	deleteanagrafica(setting) {
		return this.http.post<any>(this._deleteanagrafica, setting);
	}
	riattivaanagrafica(setting) {
		return this.http.post<any>(this._riattivaanagrafica, setting);
	}
  rubrica(){
    return this.http.post<any>(this._rubrica, '');
  }
	loadanagraficatoupdate(setting) {
		return this.http.post<any>(this._loadanagraficatoupdate, setting);
	}
	updateanagraficaadmin(setting) {
		return this.http.post<any>(this._updateanagraficaadmin, setting);
	}
	updateprogetto(setting) {
		return this.http.post<any>(this._updateprogetto, setting);
	}
	updatesummary(setting) {
		return this.http.post<any>(this._updatesummary, setting);
	}
	load_progetti_ts(setting) {
		return this.http.post<any>(this._load_progetti_ts, setting);
	}
	disable_progetti_ts(setting) {
		return this.http.post<any>(this._disable_progetti_ts, setting);
	}
	enable_progetti_ts(setting) {
		return this.http.post<any>(this._enable_progetti_ts, setting);
	}
	enable_progetti_ts_code(setting) {
		return this.http.post<any>(this._enable_progetti_ts_code, setting);
	}
	loadentity(setting) {
		return this.http.post<any>(this._loadentity, setting);
	}
	update_progetti_ts(setting) {
		return this.http.post<any>(this._update_progetti_ts, setting);
	}
	nuovo_progetto_ts(setting) {
		return this.http.post<any>(this._nuovo_progetto_ts, setting);
	}
	load_entity_ts(setting) {
		return this.http.post<any>(this._load_entity_ts, setting);
	}
	disable_entity_ts(setting) {
		return this.http.post<any>(this._disable_entity_ts, setting);
	}
	enable_entity_ts(setting) {
		return this.http.post<any>(this._enable_entity_ts, setting);
	}
	update_entity_ts(setting) {
		return this.http.post<any>(this._update_entity_ts, setting);
	}
	nuovo_entity_ts(setting) {
		return this.http.post<any>(this._nuovo_entity_ts, setting);
	}
	load_activity_ts(setting) {
		return this.http.post<any>(this._load_activity_ts, setting);
	}
	disable_activity_ts(setting) {
		return this.http.post<any>(this._disable_activity_ts, setting);
	}
	enable_activity_ts(setting) {
		return this.http.post<any>(this._enable_activity_ts, setting);
	}
	update_activity_ts(setting) {
		return this.http.post<any>(this._update_activity_ts, setting);
	}
	nuovo_activity_ts(setting) {
		return this.http.post<any>(this._nuovo_activity_ts, setting);
	}
	loadcombohours(setting) {
		return this.http.post<any>(this._loadcombohours, setting);
	}
	loadprojectemployee(setting) {
		return this.http.post<any>(this._loadprojectemployee, setting);
	}
	loadprojectemployeeinterni(setting) {
		return this.http.post<any>(this._loadprojectemployeeinterni, setting);
	}
	check_ore(setting) {
		return this.http.post<any>(this._check_ore, setting);
	}
	saveprojectemployee(setting) {
		return this.http.post<any>(this._saveprojectemployee, setting);
	}
	saveprojectemployeeinterni(setting) {
		return this.http.post<any>(this._saveprojectemployeeinterni, setting);
	}
	deleteprojectts(setting) {
		return this.http.post<any>(this._deleteprojectts, setting);
	}
	deleteprojecttsinterni(setting) {
		return this.http.post<any>(this._deleteprojecttsinterni, setting);
	}
	loadprojectemployeeinterni_straord(setting) {
		return this.http.post<any>(this._loadprojectemployeeinterni_straord, setting);
	}
	loadprojectemployee_straord(setting) {
		return this.http.post<any>(this._loadprojectemployee_straord, setting);
	}
	loadprojectemployee_straordinterni(setting) {
		return this.http.post<any>(this._loadprojectemployee_straordinterni, setting);
	}
	loaddestinatari(setting) {
		return this.http.post<any>(this._loaddestinatari, setting);
	}
	loadcompany(setting) {
		return this.http.post<any>(this._loadcompany, setting);
	}
	loadcompanyemail(setting) {
		return this.http.post<any>(this._loadcompanyemail, setting);
	}
	loadclientemail(setting) {
		return this.http.post<any>(this._loadclientemail, setting);
	}
	loadcustomeremail(setting) {
		return this.http.post<any>(this._loadcustomeremail, setting);
	}
	loadclient(setting) {
		return this.http.post<any>(this._loadclient, setting);
	}
	loadclient_admin(setting) {
		return this.http.post<any>(this._loadclient_admin, setting);
	}
	loadcustsettings(setting) {
		return this.http.post<any>(this._loadcustsettings, setting);
	}
	loadcustrolesettings(setting) {
		return this.http.post<any>(this._loadcustrolesettings, setting);
	}
	disable_client(setting) {
		return this.http.post<any>(this._disable_client, setting);
	}
	enable_client(setting) {
		return this.http.post<any>(this._enable_client, setting);
	}
	update_client(setting) {
		return this.http.post<any>(this._update_client, setting);
	}
	update_userrole(setting) {
		return this.http.post<any>(this._update_userrole, setting);
	}
	newuserrole(setting) {
		return this.http.post<any>(this._newuserrole, setting);
	}
	new_client(setting) {
		return this.http.post<any>(this._new_client, setting);
	}
	loadcompany_admin(setting) {
		return this.http.post<any>(this._loadcompany_admin, setting);
	}
	disable_company(setting) {
		return this.http.post<any>(this._disable_company, setting);
	}
	enable_company(setting) {
		return this.http.post<any>(this._enable_company, setting);
	}
	update_company(setting) {
		return this.http.post<any>(this._update_company, setting);
	}
	new_company(setting) {
		return this.http.post<any>(this._new_company, setting);
	}
	reactivateproject(setting) {
		return this.http.post<any>(this._reactivateproject, setting);
	}
	delete_rep(setting) {
		return this.http.post<any>(this._delete_rep, setting);
	}
	delete_role(setting) {
		return this.http.post<any>(this._delete_role, setting);
	}
	delete_reptrasferta(setting) {
		return this.http.post<any>(this._delete_reptrasferta, setting);
	}
	check_file(setting) {
		return this.http.post<any>(this._check_file, setting);
	}
	upload(setting) {
		return this.http.post<any>(this._upload, setting);
	}
	uploadts(setting) {
		return this.http.post<any>(this._uploadts, setting);
	}
	summary_report(setting) {
		return this.http.post<any>(this._summary_report, setting);
	}
	estraisummarycustomfull(setting) {
		return this.http.post<any>(this._estraisummarycustomfull, setting);
	}
	download(setting) {
		return this.http.post<any>(this._download, setting);
	}
	salvacertificato(setting) {
		return this.http.post<any>(this._salvacertificato, setting);
	}
	check_progetto_ts(setting) {
		return this.http.post<any>(this._check_progetto_ts, setting);
	}
	loadutente(setting) {
		return this.http.post<any>(this._loadutente, setting);
	}
	loadutenteforname(setting) {
		return this.http.post<any>(this._loadutenteforname, setting);
	}
	check_ore_progetto(setting) {
		return this.http.post<any>(this._check_ore_progetto, setting);
	}
	deleteprogettidagliutenti(setting) {
		return this.http.post<any>(this._deleteprogettidagliutenti, setting);
	}

	unconfirmreperibilita(setting) {
		return this.http.post<any>(this._unconfirmreperibilita, setting);
	}
	confirmreperibilita(setting) {
		return this.http.post<any>(this._confirmreperibilita, setting);
	}
	salvaavailability(setting) {
		return this.http.post<any>(this._salvaavailability, setting);
	}
	deleteavailability(settings) {
		return this.http.post<any>(this._deleteavailability, settings);
	}
	loadavailabilityfull(settings) {
		return this.http.post<any>(this._loadavailabilityfull, settings);
	}
	loadavailabilityfullforinsert(settings) {
		return this.http.post<any>(this._loadavailabilityfullforinsert, settings);
	}
	setavailabilityfull(settings) {
		return this.http.post<any>(this._setavailabilityfull, settings);
	}
	deletefull(settings) {
		return this.http.post<any>(this._deleteavailabilityfull, settings);
	}
	checkreperibilitafull(settings) {
		return this.http.post<any>(this._checkreperibilitafull, settings);
	}
	insertreperibilitafull(settings) {
		return this.http.post<any>(this._insertreperibilitafull, settings);
	}
	invioemailnuovoutente(settings) {
		return this.http.post<any>(this._invioemailnuovoutente, settings);
	}
	salvasummary(settings) {
		return this.http.post<any>(this._salvasummary, settings);
	}
	update_summary(settings) {
		return this.http.post<any>(this._update_report, settings);
	}
	summary_report_admin(settings) {
		return this.http.post<any>(this._summary_report_admin, settings);
	}
	readexcel(settings) {
		return this.http.post<any>(this._readexcel, settings);
	}
	checkts(settings) {
		return this.http.post<any>(this._checkts, settings);
	}
	inserttemptable(settings) {
		return this.http.post<any>(this._inserttemptable, settings);
	}
	insertoracle(settings) {
		return this.http.post<any>(this._insertoracle, settings);
	}
	deletesummary(settings) {
		return this.http.post<any>(this._deletesummary, settings);
	}
	insercheckts(settings) {
		return this.http.post<any>(this._insercheckts, settings);
	}
	loadcontratto() {
		return this.http.post<any>(this._loadcontratto, '');
	}
	loadroles() {
		return this.http.post<any>(this._loadroles, '');
	}
	updatecontratto(settings) {
		return this.http.post<any>(this._updatecontratto, settings);
	}
	deletecontratto(settings) {
		return this.http.post<any>(this._deletecontratto, settings);
	}
	newcontratto(settings) {
		return this.http.post<any>(this._newcontratto, settings)
	}
	updateroles(settings) {
		return this.http.post<any>(this._updateroles, settings);
	}
	deleteroles(settings) {
		return this.http.post<any>(this._deleteroles, settings);
	}
	newroles(settings) {
		return this.http.post<any>(this._newroles, settings)
	}
	insert_project(settings) {
		return this.http.post<any>(this._insert_project, settings)
	}
	insert_legal_entity(settings) {
		return this.http.post<any>(this._insert_legal_entity, settings)
	}
}


