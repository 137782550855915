<br>
<div style="text-align:center">
  <img width="10%"  *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
  <img width="5%"  *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<br>
<a (click)="admin()"><button mat-raised-button color="primary">Back</button></a>
  <button *ngIf="selectfile === false" mat-raised-button color="primary" (click)="enabledata()">Check</button> <!--(click)="readexcel()"-->
 <!-- <button  *ngIf="selectfile === false" mat-raised-button color="primary" (click)="estrazionecustomsummary()">Extract custom date</button>-->
 <!-- <button  *ngIf="selectfile === true" mat-raised-button color="primary" (click)="sendtobackend1()">Send Mail</button>
  <button  *ngIf="selectfile === true" mat-raised-button color="primary" (click)="sendmail()">Send Mail</button> -->
  <br>
<br>
<mat-form-field appearance="fill" *ngIf="varenabledata==true">
  <mat-label>From date</mat-label>
  <input matInput [matDatepicker]="picker" [formControl]="passwordFormControl" required [(ngModel)]="fromdate">
  <mat-hint>MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<label>&nbsp;&nbsp;</label>
<mat-form-field appearance="fill" *ngIf="varenabledata==true">
  <mat-label>To date</mat-label>
  <input matInput [matDatepicker]="picker"[formControl]="passwordFormControl1" required [(ngModel)]="todate">
  <mat-hint>MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<input *ngIf="varenabledata==true" [disabled]="!passwordFormControl.valid || !passwordFormControl1.valid" color="primary" type="file" (change)="onFileChange($event)" />
<br>
<br>
<mat-card *ngIf="incongruence === false" class="example-card" style="width: 100%; background-color: rgb(153, 218, 238)">
    <br>
    <mat-form-field>
        <mat-label>Select month</mat-label>
        <mat-select [(value)]="mese_web" (selectionChange)="changemese($event.value)">
            <mat-option *ngFor='let mese of mesi' [value]='mese.value'>
                {{mese.viewValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Select year</mat-label>
        <mat-select [(value)]="anno_web" (selectionChange)="changeanno($event.value)">
            <mat-option *ngFor="let anno of anni" [value]="anno.value">
                {{anno.viewValue}}
            </mat-option>
        </mat-select>
    <br>
    </mat-form-field>
    <br>
    <mat-table style="border: 1px;border-color: black;border-style: solid; width: 100%" [dataSource]="dataSource" matSort>
        <ng-container  matColumnDef="id" hidden>
          <mat-header-cell *matHeaderCellDef  hidden></mat-header-cell>
          <mat-cell *matCellDef="let row"  hidden>{{ row.id}}</mat-cell>
      </ng-container>
        <ng-container  matColumnDef="data">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Data</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.data | date:"dd/MM/yyyy" }}</mat-cell>
      </ng-container>
        <ng-container  matColumnDef="Risorsa">
          <mat-header-cell *matHeaderCellDef mat-sort-header >user</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.Risorsa}}</mat-cell>
      </ng-container>
      <ng-container  matColumnDef="Comunicazione">
        <mat-header-cell *matHeaderCellDef>Comunicazione</mat-header-cell>
        <mat-cell *matCellDef="let row" >{{row.Comunicazione}} </mat-cell>
      </ng-container>
      <ng-container  matColumnDef="Ore">
        <mat-header-cell *matHeaderCellDef >Ore</mat-header-cell>
        <mat-cell *matCellDef="let row" >{{row.Ore}} </mat-cell>
      </ng-container>
      <ng-container  matColumnDef="Specifica">
        <mat-header-cell *matHeaderCellDef >Specifica</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.Specifica}} </mat-cell>
      </ng-container>
      <ng-container  matColumnDef="Ore_Specifica">
        <mat-header-cell *matHeaderCellDef >Ore Specificate</mat-header-cell>
        <mat-cell *matCellDef="let row" >{{row.Ore_Specifica}} </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
      </mat-table>
</mat-card>
<mat-card *ngIf="incongruence === true" class="example-card" style="width: 100%; background-color: rgb(153, 218, 238)">
  <div>
    <h1>List Of Incongruence</h1>
    <ul *ngFor="let result of list, let i = index">
      <li>{{result}}</li>
    </ul>
  </div>
  <mat-table style="border: 1px;border-color: black;border-style: solid; width: 100%" [dataSource]="dataSource1">
    <ng-container  matColumnDef="TS_DATE">
      <mat-header-cell *matHeaderCellDef >Data</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.TS_DATE | date:"dd/MM/yyyy" }}</mat-cell>
  </ng-container>
 <ng-container  matColumnDef="NAME">
      <mat-header-cell *matHeaderCellDef >Nome</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.NAME}}</mat-cell>
  </ng-container>
  <ng-container  matColumnDef="HOURS">
    <mat-header-cell *matHeaderCellDef >Ore</mat-header-cell>
    <mat-cell *matCellDef="let row" >{{row.HOURS}} </mat-cell>
  </ng-container>
  <ng-container  matColumnDef="PRJCODE">
    <mat-header-cell *matHeaderCellDef>PRJCODE</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.PRJCODE}} </mat-cell>
  </ng-container>
  <ng-container  matColumnDef="EMAIL">
    <mat-header-cell *matHeaderCellDef >EMAIL</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.EMAIL}} </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="tableColumns1"></mat-header-row>
  <mat-row *matRowDef="let row; columns: tableColumns1"></mat-row>
  </mat-table>
</mat-card>
