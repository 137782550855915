export class Login {
    email: string;
    password: any;
    pwd: any;
    id_utente: any;
    nome: any;
    cognome: any;
    tel:any;
    team:any;
    ruolo:any;
}
