<div *ngIf="sendcheck=='0'" mat-dialog-content>
    <label style="font-size: 30px; color: red;text-align: center;" *ngIf="sendcheck=='0'">Attention, please!!!</label><br>
    <label style="font-size: 14px; color: black; font-weight: bolder;" *ngIf="sendcheck=='0'"><b>Are you sure you want to cancel the availability of {{local_data_web}}?</b><br></label>    
    <label style="font-size: 14px; color: black; font-weight: 100;">If <b>YES</b>, you will be prompted for your WBA email password and then a cancellation notification will be sent.
    </label> 
</div>
<div align='center' *ngIf="sendcheck=='0'" mat-dialog-actions>
    <button *ngIf="sendcheck=='0'" mat-button (click)="doAction()" mat-flat-button color="primary">Yes</button>
    <button *ngIf="sendcheck=='0'" mat-button (click)="closeDialog()" mat-flat-button color="warn">No</button>
</div>
<mat-form-field *ngIf="sendcheck=='1'">
    <input matInput placeholder="Password email WBA" [(ngModel)]="emailweb" type="password" passwordToggle required>
    <mat-error>Mandatory</mat-error>
</mat-form-field>
<p *ngIf="sendcheck=='1'" style="color: red"><b>No password will be stored</b></p>
<br>
<button style="margin:5px" *ngIf="sendcheck=='1'" mat-raised-button color="accent" (click)="this.tiporichiesta_email()" type="submit">Send</button>
<button style="margin:5px" *ngIf="sendcheck=='1'" mat-raised-button color="primary" (click)="closeDialog()" type="submit">Cancel</button>