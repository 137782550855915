import { Component, OnInit } from '@angular/core';
import { Login } from '../shared/login.model';
import { AuthService } from '../auth.service';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { NotificationsService } from 'angular2-notifications';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Setting } from '../shared/setting.model';

export interface Food {
  id: string;
  progetto: string;
}

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {
  passwordFormControl = new FormControl('', [Validators.required]);
  passwordFormControl1 = new FormControl('', [Validators.required]);
  setting: Setting = new Setting();
  foods: Food[];
  nome: any;
  cognome: any;
  ruolo: any;
  company: any;
  // tslint:disable: variable-name
  email_provider: any;
  email_customer: any;
  telefono: any;
  customer_team: any;
  emailcertificati: any;
  login: Login = new Login();
  company1: any;
  // tslint:disable: max-line-length
  constructor(public _auth: AuthService, public _storage: MyLocalStorageService, private _router: Router,  private _service: NotificationsService) { }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.emailcertificati = localStorage.getItem('email_certificati');
    this.loadprofile();
  }
loadprofile() {
  this.login.id_utente = localStorage.getItem('id');
  this._auth.loaduser(this.login)
  .subscribe(
    res => {
        // tslint:disable:triple-equals
        if (res.risposta == 'Success') {
          this.nome = res.nome;
          this.cognome = res.cognome;
          this.email_provider = res.provider_email;
          this.email_customer = res.customer_mail;
          this.company1 = res.company;
          this.telefono = res.tel;
          this.customer_team = res.cust_team;
          this.ruolo = res.ruolo;
          } else {

        }
    }
  );
}
updateprofile() {
  this.login.id_utente = localStorage.getItem('id');
  this.login.team = this.customer_team;
  this.login.tel = this.telefono;
  this.login.ruolo = this.ruolo;
  console.log(this.login);
  this._auth.updateprofile(this.login)
  .subscribe(
    res => {
        if (res.risposta == 'Success') {
          this._service.success('Success',
          'Update done', {
            position: ['bottom', 'center'],
            timeOut: 1000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this._storage.setItem('ruolo', this.ruolo);
          this._storage.setItem('tel', this.telefono);
          this._router.navigate(['/welcome'], { skipLocationChange: true });

        } else {
          this._service.error('Errore',
          'Update not possible', {
            position: ['bottom', 'center'],
            timeOut: 1000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
        }
    }
  );
}
loadproject() {
  this.setting.id_utente = localStorage.getItem('id');
  this._auth.loadproject(this.setting)
  .subscribe(
    res => {
      this.foods = res;
    },
    err => console.log(err)
  );
}
}
