<br>
<mat-card class="example-card" style="width: 20em; background-color: rgb(153, 218, 238)" >
    <mat-form-field style="width: 250px;">
      <input matInput placeholder="Email" [(ngModel)]="login.email" [formControl]="emailFormControl" required>
      <mat-error *ngIf="emailFormControl.hasError('required')">Mandatory</mat-error>
      <mat-error *ngIf="emailFormControl.hasError('email')">Insert valid email</mat-error>
    </mat-form-field>
<div>
  <br>
    <button mat-raised-button color="primary" (click)= "firstlogin()" type="submit" [disabled]="!emailFormControl.valid">Invio
        <i class="material-icons right">send</i>
      </button>
</div>
</mat-card>
