<br>
<div style="text-align:center">
  <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
  <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<div align=center>
  <img src="assets\img\certificato.png" id="icon" alt="User Icon" style="height: 5em" />
</div>
<br>
<mat-form-field>
  <mat-label>Certificate type</mat-label>
  <mat-select [(ngModel)]="richiesta_web" (ngModelChange)="onChange($event)" [formControl]="requiredFormControl"
    required>
    <mat-option *ngFor="let richiesta of richieste" [value]="richiesta.value">
      {{richiesta.viewValue}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="requiredFormControl.hasError('required')">Mandatory</mat-error>
</mat-form-field>

<mat-card class="example-card" style="width: 20em; background-color: rgb(153, 218, 238)" *ngIf="request === 'certificato'">
    <br>

  <mat-form-field style="width: 100%;" *ngIf="request == 'certificato'">
    <input matInput [(ngModel)]="certificato_web" placeholder="N° Certificato" [formControl]="requiredFormControl3" required>
    <mat-error *ngIf="requiredFormControl3.hasError('required')">Mandatory</mat-error>
  </mat-form-field>
  <mat-form-field style="width: 100%;">
    <input matInput [matDatepicker]="picker" [(ngModel)]="variabile_data" placeholder="Data dal"
      [formControl]="requiredFormControl1">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="requiredFormControl1.hasError('required')">Mandatory</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field style="width: 100%;">
    <input matInput [matDatepicker]="picker1" [(ngModel)]="variabile_data_al" placeholder="Fino al"
      [formControl]="requiredFormControl2">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
    <mat-error *ngIf="requiredFormControl2.hasError('required')">Mandatory</mat-error>
  </mat-form-field>
  <form action="/api/upload" method="post" enctype="multipart/form-data">
    <input (change)="fileChange($event)" type="file" class="custom-file-input" id="inputGroupFile01" *ngIf="request != 'certificato'"
      aria-describedby="inputGroupFileAddon01">
  </form>
</mat-card>
<mat-card class="example-card" style="width: 20em; background-color: rgb(153, 218, 238)" *ngIf="request == 'other'">
    <br>
  <mat-form-field style="width: 100%;" *ngIf="request == 'other'">
    <input matInput [matDatepicker]="picker" [(ngModel)]="variabile_data" placeholder="Data dal"
      [formControl]="requiredFormControl1" *ngIf="request == 'other'">
    <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="request == 'other'" ></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="requiredFormControl1.hasError('required')">Mandatory</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field style="width: 100%;" *ngIf="request == 'other'">
    <input matInput [matDatepicker]="picker1" [(ngModel)]="variabile_data_al" placeholder="Fino al"
      [formControl]="requiredFormControl2" *ngIf="request == 'other'">
    <mat-datepicker-toggle matSuffix [for]="picker1" *ngIf="request == 'other'"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
    <mat-error *ngIf="requiredFormControl2.hasError('required')">Mandatory</mat-error>
  </mat-form-field>
  <form action="/api/upload" method="post" enctype="multipart/form-data">
    <input (change)="fileChange($event)" type="file" class="custom-file-input" id="inputGroupFile01" *ngIf="request != 'certificato'"
      aria-describedby="inputGroupFileAddon01">
  </form>
</mat-card>
<br>
  <button class="input-group-text upload" mat-raised-button color="primary" (click)="preview()" id="btnUpload"
  [disabled]="!requiredFormControl.valid || !requiredFormControl1.valid || !requiredFormControl2.valid || !requiredFormControl3.valid" *ngIf="request === 'certificato'">Preview</button>
  <button class="input-group-text upload" mat-raised-button color="primary" (click)="preview()" id="btnUpload"
  [disabled]="!requiredFormControl.valid || !requiredFormControl1.valid || !requiredFormControl2.valid" *ngIf="request === 'other'">Preview</button>
