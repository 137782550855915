import { Component, OnInit } from '@angular/core';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Router } from '@angular/router';
import { Client } from '../shared/client.model';
import { AuthService } from '../auth.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-company-admin',
  templateUrl: './company-admin.component.html',
  styleUrls: ['./company-admin.component.scss']
})
export class CompanyAdminComponent implements OnInit {
  dataSource = [];
  tableColumns: string[] = ['name', 'full_name', 'emailcertificati', 'indirizzo', 'id'];
  setting: Client = new Client();
  update_web: any ="false";
  name_web:any;
  fullname_web:any;
  address_web:any;
  emailcertificati_web:any;
  constructor(private _service: NotificationsService, public _auth: AuthService, public _storage: MyLocalStorageService, private _router: Router) { }

  ngOnInit() {

    this.loadcompany();
  }

  loadcompany(){
    this._auth.loadcompany_admin(this.setting)
    .subscribe(
      res => {
        if (res.risposta == "Errore") {
          this.dataSource = [];
        } else {
          this.dataSource = res;
        }
      },
      err => console.log(err)
    );
  }
  back(){
    this._router.navigate(['/admin'], { skipLocationChange: true });
  }
  delete(id_company, status){
    this.setting.id=id_company;
    if (status == 'active') {
      this._auth.disable_company(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
            'company disabled', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          } else {
            this._service.error('Error',
            'Ops something gone wrong', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
          this.loadcompany();
        },
        err => console.log(err)
      );

    } else {
      this._auth.enable_company(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
            'company reactivated', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          } else {
            this._service.error('Error',
            'Ops something gone wrong', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
          this.loadcompany();
        },
        err => console.log(err)
      );
    }
      }
  update(name, fullname, id){
    this.setting.id = id;
    this.name_web=name;
    this.fullname_web=fullname;
    this.update_web="true";
}
save(){
  this.setting.name = this.name_web;
  this.setting.full_name = this.fullname_web;
  this.setting.emailcertificati = this.emailcertificati_web;
  this.setting.indirizzo = this.address_web;
  this._auth.update_company(this.setting)
    .subscribe(res => {
      if (res.risposta == "Success") {
        this._service.success('Success',
        'Progetto aggiornato', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this.update_web="false";
        this.loadcompany();
      } else {
        this._service.error('Error',
        'Progetto non aggiornato', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
    },
     err => console.log(err)
   );
}
newcompany(){
  this.name_web="";
  this.fullname_web="";
  this.update_web="new";
}
savenew(){
  this.setting.name = this.name_web;
  this.setting.full_name = this.fullname_web;
  this.setting.emailcertificati = this.emailcertificati_web;
  this.setting.indirizzo = this.address_web;
  this._auth.new_company(this.setting)
    .subscribe(res => {
      if (res.risposta == "Success") {
        this._service.success('Success',
        'Company Inserted', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this.update_web="false";
        this.loadcompany();
      } else {
        this._service.error('Error',
        'Company not inserted', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
    },
     err => console.log(err)
   );
}
}
