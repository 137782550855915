import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../auth.service';
import { Availabilityfull } from '../shared/availabilityfull.model';

@Component({
  selector: 'app-availfull',
  templateUrl: './availfull.component.html',
  styleUrls: ['./availfull.component.scss']
})
export class AvailfullComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  availabilitymodel = new Availabilityfull();
  insertavailability = 'false';
  company: any;
  dataSource = new MatTableDataSource<Availabilityfull>();
  tableColumns: string[] = ['id', 'Cognome', 'Nome', 'idanagrafica', 'fullavailability'];
  constructor(private http: HttpClient, public _auth: AuthService, private _router: Router, private _service: NotificationsService) { }

  ngOnInit() {
    this.loadavailabilityfullinsert();
    this.company = localStorage.getItem('company');
  }
 
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  deleterecord(id) {
    this.availabilitymodel.id = id;
    this._auth.deletefull(this.availabilitymodel).subscribe(
      res => {
        if (res.risposta === 'success') {
          this._service.success('Success',
            'Record deleted', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this.loadavailabilityfullinsert();
        } else {
          this._service.error('Error',
            'Record Not deleted', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
        }
      },
      err => console.log(err)
    );
  }
  insert() {
    this.insertavailability = 'true';
    this.loadavailabilityfullinsert();
  }
  loadavailabilityfullinsert() {
    this._auth.loadavailabilityfullforinsert('')
      .subscribe(
        res => {
          if (res.risposta === 'novalue') {
            this.dataSource = new MatTableDataSource([]);
          } else {
            this.dataSource.data = res as Availabilityfull[];
          }
        },
        err => console.log(err)
      );
  }
  async lock(id) {
    this.availabilitymodel.idanagrafica = id;
    this.availabilitymodel.availabilityfull = 1;
    await this.setavailabilityfull();
  }
 async unlock(id) {
    this.availabilitymodel.idanagrafica = id;
    this.availabilitymodel.availabilityfull = 0;
    await this.setavailabilityfull();
  }
setavailabilityfull() {
  this._auth.setavailabilityfull(this.availabilitymodel).subscribe(
    res => {
      if (res.risposta === 'success') {
        this.loadavailabilityfullinsert();
      } else {
        this._service.error('Error',
          'Record Not updated', {
          position: ['bottom', 'center'],
          timeOut: 5000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
    },
    err => console.log(err)
  );
}

}
