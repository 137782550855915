import { Time } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth.service';
import { Summary } from 'src/app/shared/summary.model';
import { Email } from '../../shared/email.model';
import { DatePipe } from '@angular/common';
import { UsersData } from 'src/app/update-rep/dialog/dialog.component';
import { NotificationsService } from 'angular2-notifications';
import { MyLocalStorageService } from 'src/app/localstorage/mylocalstorage.service';
const dateFormat = require('dateformat');
export interface settings {
  startdate: string;
  todate: string;
}
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  providers: [DatePipe],
})
export class DialogComponent3 {
  local_data: { id: any; data: string; };
  action: any;
  orainizio: Time;
  email: Email = new Email();
  orafine: Time;
  summary: Summary = new Summary();
  idriga: any;
  specifica: any;
  oraspecifica: any;
  dataemail: any;
  idemail: any;
  firma: string;
  oggetto_email: string;
  corpo_email: string;
  temp5: string;
  constructor(public _auth: AuthService,
    private _service: NotificationsService,
    public _storage: MyLocalStorageService,
    public dialogRef: MatDialogRef<DialogComponent3>,
    private datePipe: DatePipe,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UsersData) {
  }

  async doaction() {
   /* EditSVO(element, VOFormElement ) {*/
      this.idemail = this.data.id;
     this.deletesummary(this.idemail, this.data.body, this.data.id_riga)


  }
  deletesummary (id, value, riga){
    let body = {'idtodelete' : id}
    this._auth.deletesummary(body)
    .subscribe(res => {
      console.log(res)
      if (res.risposta == 'Success') {
        this._service.success('Success',
        'Record deleted and Email sent', {
          position: ['bottom', 'center'],
          timeOut: 4000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this.sendcancelemail(value, riga);
      } else {
        this._service.error('Error',
        'Record non delete contact administator', {
          position: ['bottom', 'center'],
          timeOut: 4000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
    },
     err => console.log(err)
   );
  }

  async sendcancelemail(value, riga){
    let datetosend = value.value.VORows[riga].data.split('-').join('/')
    switch (value.value.VORows[riga].specifica) {
      case 'Hours off':
        this.email.specifica = 'HOURS OFF'
        this.oggetto_email = 'HOURS OFF ' + datetosend + ' - (' + value.value.VORows[riga].ore + ')   - CANCELLED';
        this.confirmed(datetosend)
        break;
      case 'Absence':
        this.oggetto_email = 'ABSENCE OF ' + datetosend +' - CANCELLED';
        this.email.specifica = 'ABSENCE'
        this.confirmed(datetosend)
        break;
      case 'Holiday':
        this.email.specifica = 'HOLIDAY'
        this.oggetto_email = 'HOLIDAY OF ' + datetosend + ' - CANCELLED'
        this.confirmed(datetosend)
        break;
      case 'Overtime':
        this.email.specifica = 'OVERTIME'
        this.oggetto_email = 'OVERTIME OF ' + datetosend + ' - (' + value.value.VORows[riga].ore + ') - CANCELLED'
        this.confirmed(datetosend)
        break;
      case 'Overtime at Night':
        this.email.specifica = 'OVERTIME'
        this.oggetto_email = 'OVERTIME OF ' + datetosend + ' - (' + value.value.VORows[riga].ore + ') - CANCELLED'
        this.confirmed(value.value.VORows[riga].data)
        break;
    }

}

async confirmed(data){
  this.email.destarray = this._storage.getItem('destxml');
  this.email.lunghezza = this._storage.getItem('lunghezza');
  this.email.email = this._storage.getItem('email')
  this.email.nome =  this._storage.getItem('nome');
  this.email.cognome =  this._storage.getItem('cognome');
  this.firma =
  '<font face="Calibri" color="#002060">Regards,<br><b>' +
  this.email.nome +
  ' ' +
  this.email.cognome +
  '</b></font><br><font face="Calibri" color="#002060">' +
  this._storage.getItem('ruolo') +
  ', Pharmacy and Healthcare Product Engineering - Tecnology Solution </b></font><br><br><font face="Calibri" color="#002060"><b>Walgreens Boots Alliance</b> │ Via Vicinale S.Maria del Pianto - Centro Polifunzionale - Torre 5 - Piano 8, Napoli(Italy) 80143</font><br><font face="Calibri" color="#FFFFFF"><p>[]</p></font>';
  this.corpo_email = '<font face="Calibri"><p>Hi,</p><p>the present mail to inform you that on <b>' +
  data +
    '</b> the previous comunication was cancelled <b><br><br>' +  this.firma ;

  this.email.corpo_email_send = this.corpo_email;
  this.email.subject_email_send = this.oggetto_email;


  await this._auth.invioemail(this.email).subscribe(
    res => {
      if (res.status=== 'SUCCESS') {
        this.dialogRef.close();
        // tslint:disable-next-line:no-unused-expression
      } else {
        this.dialogRef.close();
        // tslint:disable:no-unused-expression
          this._service.error('Error', 'Contact Administrator', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
      }
    },
    err => console.log(err)
  );
}
  onNoClick(): void {
    this.dialogRef.close();
  }
}

