import { AuthGuard } from './auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthService } from './auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TokenInterceptorService } from './token-interceptor.service';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { CertificatoComponent } from './certificato/certificato.component';
import { MyLocalStorageService } from './localstorage/mylocalstorage.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MyDialogComponent } from './my-dialog/my-dialog.component';
import { MenuComponent } from './menu/menu.component';
import { SettingsComponent } from './settings/settings.component';
import { EntrataInRitardoComponent } from './entrata-in-ritardo/entrata-in-ritardo.component';
import { PermessoComponent } from './permesso/permesso.component';
import { PermessoInIngressoComponent } from './permesso-in-ingresso/permesso-in-ingresso.component';
import { PermessoInUscitaComponent } from './permesso-in-uscita/permesso-in-uscita.component';
import { FerieUnGiornoComponent } from './ferie-un-giorno/ferie-un-giorno.component';
import { FeriePiuGiorniComponent } from './ferie-piu-giorni/ferie-piu-giorni.component';
import { AssenzaPiuGiorniComponent } from './assenza-piu-giorni/assenza-piu-giorni.component';
import { PianoFeriePiuGiorniComponent } from './piano-ferie-piu-giorni/piano-ferie-piu-giorni.component';
import { AssenzaUnGiornoComponent } from './assenza-un-giorno/assenza-un-giorno.component';
import { PianoFerieUnGiornoComponent } from './piano-ferie-un-giorno/piano-ferie-un-giorno.component';
import { StraordinarioComponent } from './straordinario/straordinario.component';
import { ReperibilitaComponent } from './reperibilita/reperibilita.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdateRepComponent } from './update-rep/update-rep.component';
import { JSONTableModule } from 'angular-json-table';
import { WelcomeComponent } from './welcome/welcome.component';
import { PrimoAccessoComponent } from './primo-accesso/primo-accesso.component';
import { PreviewComponent } from './preview/preview.component';
import { AdminComponent } from './admin/admin.component';
import { EstraiRepComponent } from './estrai-rep/estrai-rep.component';
import { ExcelService } from './services/excel.service';
import { DatePipe } from '@angular/common';
import { MeselockComponent } from './meselock/meselock.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { ProgettiComponent } from './progetti/progetti.component';
import { NuovaanagraficaComponent } from './nuovaanagrafica/nuovaanagrafica.component';
import { UpdateanagraficaComponent } from './updateanagrafica/updateanagrafica.component';
import { DeleteanagraficaComponent } from './deleteanagrafica/deleteanagrafica.component';
import { UpdateadminanagraficaComponent } from './updateadminanagrafica/updateadminanagrafica.component';
import { UpdateProgettoComponent } from './update-progetto/update-progetto.component';
import { TsProgettiComponent } from './ts-progetti/ts-progetti.component';
import { TsLegalEntityComponent } from './ts-legal-entity/ts-legal-entity.component';
import { TsActivityComponent } from './ts-activity/ts-activity.component';
import { ClientAdminComponent } from './client-admin/client-admin.component';
import { CompanyAdminComponent } from './company-admin/company-admin.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './certificato/dialog/dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UploadService } from './certificato/upload.service';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { SummaryComponent } from './summary/summary.component';
import { PreviewCertificatiComponent } from './preview-certificati/preview-certificati.component';
import { ManutenzioneComponent } from './manutenzione/manutenzione.component';
import { PresenzaTrasfertaComponent } from './presenza-trasferta/presenza-trasferta.component';
import { PresenzaTrasfertEstrazioneComponent } from './presenza-trasfert-estrazione/presenza-trasfert-estrazione.component';
import { UpdateTrasfertaComponent } from './update-trasferta/update-trasferta.component';
import { MeselocktrasfertaComponent } from './meselocktrasferta/meselocktrasferta.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AvailabilityComponent } from './availability/availability.component';
import { DialogComponent1 } from './update-rep/dialog/dialog.component';
import { SpinnerService } from './services/spinner.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { AvailfullComponent } from './availfull/availfull.component';
import { DialogComponent2 } from './dialog/dialog.component';
import { DialogComponent3 } from './summary/dialog/dialog.component';
import { DialogComponent4 } from './estraisummary/dialog/dialog.component';
import { EstraisummaryComponent } from './estraisummary/estraisummary.component';
import { TestComponent } from './test/test.component';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { EncrDecrService } from './services/EncrDecrService';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ContrattiComponent } from './dialog/contratti/contratti.component';
import { RolesComponent } from './roles/roles.component';
import { VisitmedicheComponent } from './visitmediche/visitmediche.component';
import { TrainingComponent } from './training/training.component';
import { RolesettingsComponent } from './rolesettings/rolesettings.component';
import { AdmintrainingComponent } from './admintraining/admintraining.component';
import { RubricaComponent } from './rubrica/rubrica.component';

@NgModule({
// tslint:disable: indent
	declarations: [
		AppComponent,
		LoginComponent,
		RegisterComponent,
		CertificatoComponent,
		MyDialogComponent,
    DialogComponent,
	DialogComponent1,
	DialogComponent2,
	DialogComponent3,
	DialogComponent4,
		MenuComponent,
		SettingsComponent,
		EntrataInRitardoComponent,
		PermessoComponent,
		PermessoInIngressoComponent,
		PermessoInUscitaComponent,
		FerieUnGiornoComponent,
		FeriePiuGiorniComponent,
		AssenzaPiuGiorniComponent,
		PianoFeriePiuGiorniComponent,
		AssenzaUnGiornoComponent,
		PianoFerieUnGiornoComponent,
		StraordinarioComponent,
		ReperibilitaComponent,
		UpdateRepComponent,
		WelcomeComponent,
		PrimoAccessoComponent,
		PreviewComponent,
		AdminComponent,
		EstraiRepComponent,
		MeselockComponent,
		UpdateProfileComponent,
		ProgettiComponent,
		NuovaanagraficaComponent,
		UpdateanagraficaComponent,
		DeleteanagraficaComponent,
		UpdateadminanagraficaComponent,
		UpdateProgettoComponent,
		TsProgettiComponent,
		TsLegalEntityComponent,
		TsActivityComponent,
		ClientAdminComponent,
		CompanyAdminComponent,
		FileUploadComponent,
		SummaryComponent,
		PreviewCertificatiComponent,
		ManutenzioneComponent,
		PresenzaTrasfertaComponent,
		PresenzaTrasfertEstrazioneComponent,
		UpdateTrasfertaComponent,
		MeselocktrasfertaComponent,
		ContactusComponent,
		AvailabilityComponent,
		LoaderComponent,
		AvailfullComponent,
		EstraisummaryComponent,
  TestComponent,
  ContrattiComponent,
  RolesComponent,
  VisitmedicheComponent,
  TrainingComponent,
  RolesettingsComponent,
  AdmintrainingComponent,
  RubricaComponent,
	],
	exports: [CertificatoComponent,MatAutocompleteModule],
	imports: [
		MatDialogModule,
		CommonModule,
		MatListModule,
    AmazingTimePickerModule,
		MatTooltipModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatSortModule,
    MatAutocompleteModule,
		FileUploadModule,
		JSONTableModule,
		MatTableModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		MatRadioModule,
		MatProgressSpinnerModule,
		FlexLayoutModule,
		ReactiveFormsModule,
		MatCardModule,
		MatSelectModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatNativeDateModule,
		MatInputModule,
		BrowserModule,
		MatButtonModule,
		BrowserAnimationsModule,
		FormsModule,
		NgbModule,
		HttpClientModule,
		MatIconModule,
		AppRoutingModule,
		MatExpansionModule,
		SimpleNotificationsModule.forRoot(),
	],
	providers: [
    EncrDecrService,
		AuthService,
    AuthGuard,
    SpinnerService,
		UploadService,
		ExcelService,
		MyLocalStorageService,
		DatePipe,
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
		LoaderService,
    	{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
      {provide: MAT_DATE_LOCALE, useValue: 'it-IT'}
	],
	entryComponents: [DialogComponent, DialogComponent1, DialogComponent2, DialogComponent3, DialogComponent4],
	bootstrap: [AppComponent],
})
export class AppModule {}
