export class Reperibilita {
    id: any;
    id_utente: any;
    data: any;
    mese: any;
    anno:any;
    giorno:any;
    datato: any;
    meseto: any;
}
