import { Component, Inject, Optional, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from 'src/app/auth.service';
import { DatePipe } from '@angular/common';
import { MyLocalStorageService } from 'src/app/localstorage/mylocalstorage.service';
import { Email } from 'src/app/shared/email.model';
import * as CryptoJS from 'crypto-js';
// tslint:disable: variable-name
export interface UsersData {
  body: any;
  id: any;
  data: string;
  id_riga: any;
}
// tslint:disable:indent
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  providers: [DatePipe]
})
// tslint:disable-next-line: component-class-suffix
export class DialogComponent1  implements OnInit {
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  action: string;
  email: Email = new Email();
  local_data: any;
  emailwba = '';
  emailweb: any;
  envid: any;
  local_data_web: any;
  nome: any;
  cognome: any;
  destinatari_conoscenza: string;
  firma: string;
  ruolo: string;
  wait: any = '0';
  resource_email: string;
  resource_name = 'vuoto';
  destinataritable: any[] = [];
  lenghtfordestinatari: any;
  destxml: any = '';
  oggetto_email: string;
  corpo_email: string;
  temp5: string;
  ack: string;
  sendcheck = '0';
  temp1: string;
  constructor(
	private datePipe: DatePipe,
 public dialogRef: MatDialogRef<DialogComponent1>,
 @Optional() @Inject(MAT_DIALOG_DATA) public data: UsersData ,
 public _auth: AuthService,
	public _storage: MyLocalStorageService,
	private _router: Router,
	private _service: NotificationsService) {
    console.log(data);
    this.local_data = {...data};
    this.local_data_web = JSON.stringify(data.data).substring(1, 11);
	   this.action = this.local_data.action;
  }
  ngOnInit() {
	localStorage.setItem('resourcename', 'vuoto');
	this.temp1 = this._storage.getItem('email_certificati');
	this.loadclientemail();
  }
  doAction(){
    if (localStorage.getItem('customer') === '2') {
			//this.invioemailinterni();
		} else {
      this.sendcheck = '1';
		}
	   this.loaddestinatari();
    console.log(this.local_data);

  }

  closeDialog() {
    this.dialogRef.close({event: 'Cancel'});
  }
	tiporichiesta_email() {
	this.email.password = this.encryptUsingAES256();
    this.nome = this._storage.getItem('nome');
    this.cognome = this._storage.getItem('cognome');
    this.ruolo = this._storage.getItem('ruolo');
    this.destinatari_conoscenza = 'PSE-NAdmin@alliance-healthcare.net';
    this.firma =

			'<font face="Calibri" color="#002060">Regards,<br><b>' +
			this.nome +
			' ' +
			this.cognome +
			'</b></font><br><font face="Calibri" color="#002060"><b> ' +
			this.ruolo +
			// tslint:disable-next-line:max-line-length
			', OneIT<br>Platform Software Engineering Naples </b></font><br><br><font face="Calibri" color="#002060"><b>Walgreens Boots Alliance</b> │ Via Luigi Volpicella 32, Naples (Italy) 80147</font>';
			 (this.oggetto_email = 'AVAILABILITY OF ' + this.local_data_web),
			 (this.corpo_email =
				'<font face="Calibri"><p>Hi,</p><p>this email to inform you that the availability of the day <b>' +
				this.local_data_web +
				'</b> has been cancelled. </font><br><br>' + this.firma);

    this.check_file();
  }
  loaddestinatari() {
	if (localStorage.getItem('resourcename') === 'vuoto') {
		this.email.id_utente = localStorage.getItem('id');
		this.resource_email = localStorage.getItem('email');
	} else {
		this.email.id_utente = localStorage.getItem('resource_id');
		this.resource_email = localStorage.getItem('resourceemail');
	}
	this.resource_name = localStorage.getItem('resourcename');
	this._auth.loaddestinatari(this.email).subscribe(
		res => {
			if (res.length === undefined) {
				this._service.error('Error', 'Please, add at least one project mail recipient!!!', {
					position: ['bottom', 'center'],
					timeOut: 5000,
					showProgressBar: true,
					pauseOnHover: false,
					clickToClose: true,
				});
				this._router.navigate(['/settings'], { skipLocationChange: true });
			}

			if (this.resource_name === 'vuoto') {
				this.destinataritable = res;
				this._storage.setItem('lunghezza', res.length);
				this.lenghtfordestinatari = res.length;
				this._storage.setItem('desttable', res);
			} else {
				this.destinataritable = res;
				this._storage.setItem('lunghezza', res.length + 1);
				this.lenghtfordestinatari = res.length + 1;
				this._storage.setItem('desttable', JSON.stringify(res));
				const tempdata = { destinatari: this.resource_email };
				this.destinataritable.push(tempdata);
			}
			console.log(this.lenghtfordestinatari);
			this.destxml = '<t:Mailbox><t:EmailAddress>' + this.temp1 + '</t:EmailAddress></t:Mailbox>';
			for (let i = 0, len = this.lenghtfordestinatari; i < len; i++) {
				console.log(this.destinataritable[i].destinatari);
				this.destxml = this.destxml + '<t:Mailbox><t:EmailAddress>' +
					this.destinataritable[i].destinatari
					+ '</t:EmailAddress></t:Mailbox>';
			}
			console.log(this.destxml);
			this._storage.setItem('destxml', this.destxml);
		},
		err => console.log(err)
	);
}
async loadclientemail() {
	const data = '';
	let desttemp = '';
	const res = await this._auth.loadclientemail(data).toPromise();
	res.forEach(element => {
		this.emailwba = this.emailwba + element.email + ';';
		desttemp = desttemp + '<t:Mailbox><t:EmailAddress>' + element.email + '</t:EmailAddress></t:Mailbox>';
	});
	this.email.clientemail = desttemp;
}
	check_file() {
    this.dialogRef.close({event: this.action});
    this.wait = '1';
    this._auth.check_file(this.email).subscribe(res => {
  if (res.risposta === 'non_esiste') {
      this.invioemail();
  } else {
      this._service.error('Errore', 'Ops... somethings gone wrong. Please contact the administrator', {
        position: ['bottom', 'center'],
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
  }
});

}
async invioemail() {
this.email.corpo_email_send = this.corpo_email;
this.email.subject_email_send = 'AVAILABILITY OF ' + this.local_data_web + ' - CANCELLED';
this.temp5 = this._storage.getItem('customer_mail').substring(this._storage.getItem('customer_mail').lastIndexOf('\@') + 1);
if ( this._storage.getItem('customer_mail').substring(this._storage.getItem('customer_mail').lastIndexOf('\@') + 1) == 'wbaoneit.com') {
      this.email.username = this._storage.getItem('customer_mail');
      this.email.wbaoneit = 'true';
    } else {
	this.email.username = this._storage.getItem('username');
	this.email.wbaoneit = 'false';
    }
this.email.destarray = this._storage.getItem('destxml');
this.email.lunghezza = this._storage.getItem('lunghezza');
this.email.resource_name = this._storage.getItem('resourcename');
this.email.resource_email = this._storage.getItem('resourceemail');
await this._auth.invioemail(this.email).subscribe(
			res => {
				if (res.risposta[0].indexOf('NoError') !== -1) {
					// tslint:disable-next-line:no-unused-expression
					this.wait === '0';
					this._service.success('Success', 'Mail of cancellation sent', {
						position: ['bottom', 'center'],
						timeOut: 5000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
					this.deleteavailability();
				} else {
          // tslint:disable:no-unused-expression
					this.wait === '0';
					this._service.error('Errore',  res.risposta[0], {
						position: ['bottom', 'center'],
						timeOut: 5000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);
	}
    deleteavailability() {
    this.email.id = this.local_data.id;
    this._auth.deletereperibilita(this.email).subscribe(
			res => {
				if (res.risposta === 'Success') {

				} else {
          // tslint:disable:no-unused-expression
					this.wait === '0';

					this._service.error('Errore', 'Ops... somethings gone wrong, record non deleted', {
						position: ['bottom', 'center'],
						timeOut: 5000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);

  }
  encryptUsingAES256() {
	let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
	let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
	let encrypted = CryptoJS.AES.encrypt(
	  JSON.stringify(this.emailweb), _key, {
		keySize: 16,
		iv: _iv,
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7
	  });
	this.encrypted = encrypted.toString();
	return this.encrypted
  }
}
