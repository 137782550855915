<br>
<div>
    <mat-card class="example-card" style="background-color: rgb(153, 218, 238);">
        <mat-form-field style="width:20%">
            <input matInput placeholder="Name" [(ngModel)]="anagrafica.nome" [formControl]="textFormControl" required>
            <mat-error *ngIf="textFormControl.hasError('required')">Mandatory</mat-error>
        </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field style="width:20%">
            <input matInput placeholder="Surname" [(ngModel)]="anagrafica.cognome" [formControl]="textFormControl1" required>
            <mat-error *ngIf="textFormControl1.hasError('required')">Mandatory</mat-error>
        </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field style="width: 15%;">
          <input matInput [matDatepicker]="picker3" [(ngModel)]="anagrafica.datadinascita" placeholder=" Data di Nascita">
          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;


      <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Luogo di Nascita</mat-label>
          <input type="text"
                 placeholder="Seleziona"
                 aria-label="Number"
                 matInput
                 [formControl]="myControl"
                 [matAutocomplete]="auto"
                 [(ngModel)]="anagrafica.luogodinascita">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field>
          <mat-label>Luogo di Residenza</mat-label>
          <mat-select>
             <input (keyup)="onKey($event.target.value)">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let state of selectedStates" [value]="state">{{state}}</mat-option>
          </mat-select>
        </mat-form-field>
    <br>
    <mat-form-field style="width:20%">
      <input matInput placeholder="Indirizzo" [(ngModel)]="anagrafica.indirizzo">
  </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field style="width:20%">
    <input matInput placeholder="Ruolo" [(ngModel)]="anagrafica.role">
</mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field class="profile" *ngIf="_storage.getItem('profile') =='1'">
            <mat-label *ngIf="_storage.getItem('profile') =='1'">Profile</mat-label>
            <mat-select *ngIf="_storage.getItem('profile') =='1'" [(ngModel)]="anagrafica.profile" [formControl]="textFormControl5" required>
                <mat-option *ngFor="let role of roles" [value]="role.value">
                    <b>{{role.viewValue}}</b>
                </mat-option>
            </mat-select>
            <mat-error *ngIf="textFormControl5.hasError('required')">Mandatory</mat-error>
        </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field style="width:20%">
          <input matInput placeholder="Codice Fiscale" [(ngModel)]="anagrafica.codicefiscale" >
      </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field style="width:20%" *ngIf="_storage.getItem('profile') =='1'">
          <mat-label *ngIf="_storage.getItem('profile') =='1'">Tipo Contratto</mat-label>
          <mat-select *ngIf="_storage.getItem('profile') =='1'" [(ngModel)]="anagrafica.tipocontratto">
              <mat-option *ngFor="let contratto of contratti" [value]="contratto.value">
                  <b>{{contratto.viewValue}}</b>
              </mat-option>
          </mat-select>
    </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
    <br>
    <mat-form-field style="width:100%">
      <input matInput placeholder="Note" [(ngModel)]="anagrafica.note">
  </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
  <br>
  <mat-form-field style="width: 15%;">
    <input matInput [matDatepicker]="picker1" [(ngModel)]="anagrafica.datainizio" placeholder=" Data Inizio">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
  <mat-form-field style="width: 15%;">
    <input matInput [matDatepicker]="picker2" [(ngModel)]="anagrafica.datafine" placeholder=" Data Fine">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field style="width:15%">
            <mat-label>Gender</mat-label>
            <mat-select [(ngModel)]="anagrafica.gender" [formControl]="textFormControl3" required>
                <mat-option *ngFor="let gender of genders" [value]="gender.value">
                    {{gender.viewValue}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="textFormControl3.hasError('required')">Mandatory</mat-error>
        </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field style="width: 30%">
            <input matInput placeholder="Mobile" [(ngModel)]="anagrafica.tel">
        </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
        <br>
        <mat-form-field style="width: 15%">
            <mat-label>Company</mat-label>
            <mat-select [(ngModel)]="anagrafica.company" [formControl]="textFormControl7" required>
                <mat-option *ngFor="let company of companies" [value]="company.value">
                    {{company.viewValue}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="textFormControl7.hasError('required')">Mandatory</mat-error>
        </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field style="width: 30%">
            <input matInput placeholder="Email Company" [(ngModel)]="anagrafica.posta_elettronica" [formControl]="textFormControl2" required>
            <mat-error *ngIf="textFormControl2.hasError('email')">Insert valid email</mat-error>
            <mat-error *ngIf="textFormControl2.hasError('required')">Mandatory</mat-error>
        </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;

        <mat-form-field style="width: 15%">
            <mat-label>Customer</mat-label>
            <mat-select [(ngModel)]="anagrafica.customer" [formControl]="textFormControl8" required>
                <mat-option [value]="0"></mat-option>
                <mat-option *ngFor="let customer of customers" [value]="customer.value">
                    {{customer.viewValue}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="textFormControl8.hasError('required')">Mandatory</mat-error>
        </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field style="width: 30%">
            <input matInput placeholder="Email Customer" [(ngModel)]="anagrafica.customer_mail">
        </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
        <br>
        <mat-form-field style="width: 30%">
          <input matInput placeholder="Welfare" [(ngModel)]="anagrafica.welfare">
      </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
      <mat-form-field style="width: 30%">
        <input matInput placeholder="Cral" [(ngModel)]="anagrafica.cral">
    </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
    <mat-form-field style="width: 15%;">
      <input matInput [matDatepicker]="picker" [(ngModel)]="anagrafica.datavisitamedica" placeholder=" Data Visite Mediche">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field> &nbsp;&nbsp;&nbsp;&nbsp;
    </mat-card>
    <br>
    <button mat-raised-button color="primary" (click)="back()"> Back
    </button>
    <button mat-raised-button color="primary" (click)="salvaanagrafica()" [disabled]="!textFormControl3.valid || !textFormControl7.valid || !textFormControl5.valid || !textFormControl2.valid || !textFormControl1.valid || !textFormControl.valid">Save</button>
</div>
<!---->
