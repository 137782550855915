<div *ngIf="disableinvio == true" class="overlay">
    <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
  </div>
<br>
<div style="text-align:center">
    <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<html>
<br>
 
<body>
   <!-- <div > -->
        <table style="border-style: outset;" *ngIf="sendcheck=='0'">
            <tr>
                <td style="border-style: outset; background-color: lightgrey; text-align: center;">
                    <b>To</b>
                </td>
                <td style="border-style: outset;">
                    <mat-table style="width: 100%; font-size: x-small" [dataSource]="dataSource1">
                        <ng-container matColumnDef="destinatari">
                            <mat-header-cell *matHeaderCellDef hidden class="mat-header-row"></mat-header-cell>
                            <mat-cell *matCellDef="let row" style="font-size: x-small"  class="mat-row">{{row.destinatari}}</mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="tableColumns1"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: tableColumns1"></mat-row>
                    </mat-table>
                </td>
            </tr>
            <tr>
                <td style="border-style: outset; background-color: lightgrey; text-align: center;">
                    <b>Cc</b>
                </td>
                <td style="border-style: outset;">
                    <mat-table style="width: 100%; font-size: x-small" [dataSource]="dataSource2">
                        <ng-container matColumnDef="email">
                            <mat-header-cell *matHeaderCellDef hidden class="mat-header-row"></mat-header-cell>
                            <mat-cell *matCellDef="let row" style="font-size: x-small;border: none; min-height: 15px;" class="mat-row">{{row.email}}</mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="tableColumns2"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: tableColumns2"></mat-row>
                    </mat-table>
                </td>
            </tr>
            <tr style="border-style: outset; ">
                <td style="border-style: outset; background-color: lightgrey; text-align: center;">
                    <b>Subject</b>
                </td>
                <td style="border-style: outset;">
                   <div style="margin: 10px;">{{oggetto_email}}</div> 
                </td>
            </tr>
            <tr style="border-style: outset;">
                <td colspan="2" style="border-style: outset;">
                    <div [innerHTML]="corpo_email" style="margin: 10px;"></div>
                </td>
            </tr>
            <tr style="border-style: outset;">
                <td colspan="2" style="border-style: outset; background-color: lightgrey; text-align: center;">
                    <b style="color: blue;">A copy of email will be send to {{temp5}}</b>
                </td>
            </tr>
        </table>
    <!-- </div> -->
    <br>
    <div align="center">
        <mat-card style="width: 50%; background-color: rgb(153, 218, 238); text-align: left;">
            <div align="center">
                <!--Remember that sending the email is your responsibility:-->
                <p style="font-size: larger; white-space: normal; color: red;">Are you sure that the subject, body and recipients are correct? </p>
                <br>
                <button style="margin:5px" *ngIf="sendcheck=='0' && ack =='0'" routerLink="/{{request}}" routerLinkActive="router-link-active" mat-raised-button color="primary" type="submit">No, Back <i
                    class="material-icons right">cancel</i></button>
                <button mat-raised-button color="accent" style="margin:5px" (click)="invioemailnew()" *ngIf="_storage.getItem('customer') !=='2'" type="submit">Yes, Send <i
            class="material-icons right">email</i></button>
                <button mat-raised-button color="accent" style="margin:5px" (click)="invioemailinterni()" *ngIf="_storage.getItem('customer') =='2'" type="submit">Send<i
              class="material-icons right">email</i></button>
            </div>
        </mat-card>
    </div>
    <br>


  <!--    <button style="margin:5px" *ngIf="sendcheck=='0' && ack =='0' && _storage.getItem('customer') =='1'" mat-raised-button color="accent" (click)="setack()" type="submit">Check</button>
    <button style="margin:5px" *ngIf="sendcheck=='0' && ack =='0' && _storage.getItem('customer') !='1'" mat-raised-button color="accent" (click)="setack()" type="submit">Send</button>
    <br>
    <br>
  <mat-form-field disabled="disableinvio == true" *ngIf="sendcheck=='1'">
        <input matInput placeholder="Password email WBA" [(ngModel)]="emailweb" type="password" [formControl]="passwordFormControl" passwordToggle required>
        <mat-error>Mandatory</mat-error>
    </mat-form-field>
    <p *ngIf="sendcheck=='1'" style="color: red"><b>No password will be stored</b></p>
    <br>
    <button style="margin:5px" *ngIf="sendcheck=='1'" mat-raised-button color="accent" (click)="check_file()" type="submit">OK</button>
-->
</body>
<app-loader></app-loader>
</html>