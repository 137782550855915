import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Setting } from '../shared/setting.model';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { AuthService } from '../auth.service';
import { Anagrafica } from '../shared/anagrafica.model';
import { Client } from '../shared/client.model';
import { NotificationsService } from 'angular2-notifications';
import { filter } from 'rxjs/operators';
// tslint:disable:class-name
export interface progetto {
	// tslint:disable:indent
	value: string;
	viewValue: string;
}
export interface richiesta {
	value: string;
	viewValue: string;
}
export interface permesso {
	value: string;
	viewValue: string;
}
export interface ferie {
	value: string;
	viewValue: string;
}
export interface pianoferie {
	value: string;
	viewValue: string;
}
export interface assenza {
	value: string;
	viewValue: string;
}
export interface utente {
	value: string;
	viewValue: string;
}
export interface citta {
  [x: string]: any;
  viewValue: string;
}
@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
@Injectable()
export class MenuComponent implements OnInit {
	tempdest = [];
	utenti: utente[];
	profile: any;
	// tslint:disable:variable-name
	resource_name: any;
	resource_email: any;
  resource_id: any;
  client: Client = new Client();
	passwordFormControl = new FormControl('', [Validators.required]);
	anagrafica: Anagrafica = new Anagrafica();
	setting: Setting = new Setting();
	public DEFAULT: string;
	public richiesta_web: string;
	public tiporichiesta: string;
	company: any;
	EMAILITALIANO: string;
	pippo: any;
	indirizziweb: string;
	tipo_richiesta: any;

	progetti: progetto[] = [
		{ value: 'GARIBALDI', viewValue: 'GARIBALDI' },
		{ value: 'WALGREEN', viewValue: 'WALGREEN' },
		{ value: 'SUPPORT', viewValue: 'SUPPORT' },
		{ value: 'HORIZON', viewValue: 'HORIZON' },
		{ value: 'PLATFORM', viewValue: 'PLATFORM' },
		{ value: 'COLUMBUS', viewValue: 'COLUMBUS' },
		{ value: 'QE', viewValue: 'QE' },
		{ value: 'INFRASTRUCTURE', viewValue: 'INFRASTRUCTURE' },
	];
	richieste: richiesta[] = [
		//{ value: 'LATE_ENTRY', viewValue: 'LATE ENTRY' },
		{ value: 'PERMISSION', viewValue: 'PERMISSION' },
		{ value: 'ABSENCE', viewValue: 'ABSENCE' },
		{ value: 'VACANTION', viewValue: 'HOLIDAY' },
		{ value: 'PLAN_VACANTION', viewValue: 'HOLIDAY (by PLAN)' },
		{ value: 'OVERTIME', viewValue: 'OVERTIME' },
		{ value: 'AVAILABILITY', viewValue: 'AVAILABILITY' }
	];
	feries: ferie[] = [
		{ value: 'VACANTION_1(DAY)', viewValue: '1 DAY' },
		{ value: 'VACANTIONS_+(DAYS)', viewValue: 'MORE DAYS' },
	];
	permessi: permesso[] = [
		{ value: 'PERMISSION', viewValue: 'FROM-TO' },
		{ value: 'PERMISSION_ENTRY', viewValue: 'IN ENTRY' },
		{ value: 'PERMISSION_EXIT', viewValue: 'IN EXIT' },
	];
	pianoferies: pianoferie[] = [
		{ value: 'PLAN_VACANTION_1(DAY)', viewValue: '1 DAY' },
		{ value: 'PLAN_VACANTIONS_+(DAYS)', viewValue: 'MORE DAYS' },
	];
	assenze: assenza[] = [
		{ value: 'ABSENCE_1(DAY)', viewValue: '1 DAY' },
		{ value: 'ABSENCES_+(DAYS)', viewValue: 'MORE DAYS' },
	];
  cittas: citta[] = [];
	constructor(
    private _service: NotificationsService,
		  public _auth: AuthService,
		  private http: HttpClient,
		  private router: Router,
		  public _storage: MyLocalStorageService
	) {}

	ngOnInit() {

	let tempavail = this._storage.getItem('availabilityfull');
  this.loadutente();
  this.loadcompanyemail();
	this.company = localStorage.getItem('company');
	this.profile = localStorage.getItem('profile');
	this.resource_email = ' ';
	this.resource_name = 'niente';
  this.pippo = 'niente';
  this._storage.setItem('resourcename', 'vuoto');
	this._storage.setItem('resourceemail', '');
		this.loadsettings();
	 if (tempavail === '1' || localStorage.getItem('availability') === '0' ) {
		this.richieste = [
			//{ value: 'LATE_ENTRY', viewValue: 'LATE ENTRY' },
			{ value: 'PERMISSION', viewValue: 'PERMISSION' },
			{ value: 'ABSENCE', viewValue: 'ABSENCE' },
			{ value: 'VACANTION', viewValue: 'HOLIDAY' },
      { value: 'PLAN_VACANTION', viewValue: 'HOLIDAY (by PLAN)' },
			{ value: 'OVERTIME', viewValue: 'OVERTIME' },
		]
	}
  }
  utenti1 = this.cittas
  onKey(value) {
    this.utenti1 = this.search(value);
    }
    search(value: string) {
      let filter = value.toLowerCase();
      return this.cittas.filter(option => option.toLowerCase().startsWith(filter));
    }
  loadcompanyemail() {
    this.client.id = this._storage.getItem('company');
		  this._auth.loadcompanyemail(this.client).subscribe(
			res => {
        this._storage.setItem('indirizzocompany', res[0].indirizzo);
        this._storage.setItem('companyfullname', res[0].full_name);
			},
			err => console.log(err)
		);
  }
	loadutenteforname() {
		this._auth.loadutenteforname(this.anagrafica).subscribe(
			res => {
				this._storage.setItem(
          'resourcename',
          // tslint:disable:only-arrow-functions
					res.map(function(a) {
						return a.resourceName;
					})
				);
				this._storage.setItem('resourceemail',res.map(function(a) {
					return a.posta_elettronica;
					})
				);
				let temp = res[0].resourceName;
				let index = temp.indexOf(' ');
				//index = temp.indexOf(' ', index + 1);
				let result = temp.substring(0, index);
				let result1 = temp.substring(index +1);
				this._storage.setItem('cognomeresource', result);
				this._storage.setItem('nomeresource', result1);
				this._storage.setItem('ruolo', res[0].ruolo);
				this._storage.setItem('email', res[0].customer_mail);

			},
			err => console.log(err)
		);
	}
	loadsettings() {
		this.setting.id_utente = localStorage.getItem('id');
		this._auth.loadsetting(this.setting).subscribe(
			res => {
				localStorage.setItem('settings', JSON.stringify(res));
				this._storage.setItem(
					'id_setting',
					res.map(function(a) {
						return a.id;
					})
				);
				this._storage.setItem(
					'destinatari',
					res.map(function(a) {
						return a.destinatari;
					})
				);
			},
			err => console.log(err)
		);
	}

	settiporichiesta() {
		// tslint:disable: triple-equals
		if (this.pippo == 'niente') {
			this.resource_name = 'niente';
		}
		if (this.tiporichiesta == 'PERMISSION') {
			this.tiporichiesta = this.tipo_richiesta;
		}
		if (this.tiporichiesta == 'VACANTION') {
			this.tiporichiesta = this.tipo_richiesta;
		}
		if (this.tiporichiesta == 'ABSENCE') {
      if (this.tipo_richiesta == undefined){
        this._service.error('Error', 'Please insert Absence type', {
          position: ['bottom', 'center'],
          timeOut: 5000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        return;
      } else {
      this.tiporichiesta = this.tipo_richiesta;
    }
		}
		if (this.tiporichiesta == 'PLAN_VACANTION') {
			this.tiporichiesta = this.tipo_richiesta;
		}
		this._storage.setItem('tiporichiesta', this.tiporichiesta);
		this.router.navigate(['/' + this.tiporichiesta], { skipLocationChange: true });
	}

	loadutente() {
		this._auth.loadutente(this.anagrafica).subscribe(
			res => {
				this.utenti = res;
        res.forEach(element => {
          this.cittas.push (element.viewValue)
        });
			},
			err => console.log(err)
		);
	}
	loadsettingsfor() {

		this.setting.id_utente = this.pippo;
		this._auth.loadsetting(this.setting).subscribe(
			res => {
				localStorage.setItem('settingsfor', JSON.stringify(res));
				this._storage.setItem(
					'destinatarifor',
					res.map(function(a) {
						return a.destinatari;
					})
				);
			},
			err => console.log(err)
		);
	}
	dataChanged() {
    this.utenti.forEach(element => {
      if (element.viewValue == this.pippo){
        this.pippo = element.value
      }
    });
		this.anagrafica.id_resource = this.pippo;
		this.loadutenteforname();
		this.loadsettingsfor();
		this._storage.setItem('resource_id', this.pippo);
	}
}
