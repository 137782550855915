<br>
<div style="text-align:center">
    <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>

<mat-card class="example-card" style="width: 45%; background-color: rgb(153, 218, 238)">
    <mat-form-field>
        <input matInput placeholder="Code" [(ngModel)]="setting.code" [value] ="setting.code">
    </mat-form-field>
    <br>
    <mat-form-field style="width: 100%;">
        <input matInput placeholder="Description" [(ngModel)]="setting.description" [value] ="setting.description" >
    </mat-form-field>
    <br>
    <button mat-raised-button color="primary" (click)="salvaprogetto()">Salva</button>
</mat-card>