import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Estrairep } from '../shared/estrairep.model';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// tslint:disable:variable-name+
// tslint:disable: class-name
export interface anno {
  value: string;
  viewValue: string;
}
export interface mese {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-meselocktrasferta',
  templateUrl: './meselocktrasferta.component.html',
  styleUrls: ['./meselocktrasferta.component.scss']
})
export class MeselocktrasfertaComponent implements OnInit {
 tableColumns: string[] = ['anno', 'mese', 'id'];
  dataSource = [];
  setting: Estrairep = new Estrairep();
  // tslint:disable:variable-name
  mese_web: any;
  anno_web: any;
  company: any;
  anni: anno[] = [
    { value: '2019', viewValue: '2019' },
    { value: '2020', viewValue: '2020' },
    { value: '2021', viewValue: '2021' },
    { value: '2022', viewValue: '2022' },
    { value: '2023', viewValue: '2023' },
    { value: '2024', viewValue: '2024' },
    { value: '2025', viewValue: '2025' },
  ];
  mesi: mese[] = [
    { value: '01', viewValue: 'Gennaio' },
    { value: '02', viewValue: 'Febbraio' },
    { value: '03', viewValue: 'Marzo' },
    { value: '04', viewValue: 'Aprile' },
    { value: '05', viewValue: 'Maggio' },
    { value: '06', viewValue: 'Giugno' },
    { value: '07', viewValue: 'Luglio' },
    { value: '08', viewValue: 'Agosto' },
    { value: '09', viewValue: 'Settembre' },
    { value: '10', viewValue: 'Ottobre' },
    { value: '11', viewValue: 'Novembre' },
    { value: '12', viewValue: 'Dicembre' }
  ];
  constructor(private datePipe: DatePipe, private _router: Router, private _service: NotificationsService, public _auth: AuthService) { }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.mese_web = this.datePipe.transform(new Date(), 'MM');
    this.anno_web = this.datePipe.transform(new Date(), 'yyyy');
    this.load_lock();
  }
  load_lock() {
    this._auth.load_locktrasferta( this.setting)
    .subscribe(
      res => {
        // tslint:disable: triple-equals
        if (res.risposta == 'novalue') {
          this.dataSource = [];
        } else {
          this.dataSource = res;
        }
      },
      err => console.log(err)
    );
  }
  check_lock() {
    this.setting.anno = this.anno_web;
    this.setting.mese = this.mese_web;
    this._auth.check_locktrasferta( this.setting)
    .subscribe(
      res => {
        if (res.risposta == 'Success') {
          this.meselock();
        } else {
          this._service.error('Errore',
          'Mese già bloccato', {
            position: ['bottom', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
        }
      },
      err => console.log(err)
    );
    this.load_lock();
  }
  meselock() {
    this.setting.anno = this.anno_web;
    this.setting.mese = this.mese_web;
    this._auth.mese_locktrasferta( this.setting)
      .subscribe(
        res => {
          if (res.risposta == 'Success') {
           this.mese_lock1();
          } else {
            this._service.error('Errore',
            'Errore update trasferta utente', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
        },
        err => console.log(err)
      );
      this.load_lock();
  }
  mese_lock1() {
    this._auth.mese_lock1trasferta( this.setting)
    .subscribe(
      res => {
        if (res.risposta == 'Success') {
          this._service.success('Success',
          'Mese bloccato con successo', {
            position: ['bottom', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this.load_lock();
        } else {
          this._service.error('Errore',
          'Errore insert tabella lock_mese', {
            position: ['bottom', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
        }
      },
      err => console.log(err)
    );
    this.load_lock();
  }
  meseunlock() {
    this.setting.anno = this.anno_web;
    this.setting.mese = this.mese_web;
    this._auth.mese_unlocktrasferta( this.setting)
      .subscribe(
        res => {
          if (res.risposta == 'Success') {
            this._service.success('Success',
            'Mese sbloccato con successo', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          } else {
            this._service.error('Errore',
            'Qualcosa è andato storto contatta amministratore', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
        },
        err => console.log(err)
      );
    this.load_lock();
  }
  meseunlock1(id) {
    this.setting.idmese = id;
    this._auth.mese_unlock1trasferta(this.setting)
    .subscribe(
      res => {
        if (res.risposta == 'Success') {
          this.meseunlock();
        }
      },
      err => console.log(err)
    );
    this.load_lock();
  }
  admin() {
    this._router.navigate(['/admin'], { skipLocationChange: true });
  }
}
