<br>
<div style="text-align:center">
    <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<div align="center">
    <label style="font-size: 20px;color: rgb(103, 150, 238)"> {{tiporichiesta}}</label>
    <br>
    <mat-card class="example-card" style="width: 35%; background-color: rgb(153, 218, 238)">
        <mat-form-field style="width: 100%">
            <input matInput [matDatepicker]="picker" [(ngModel)]="variabile_data" placeholder="From Date" [formControl]="passwordFormControl" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="passwordFormControl.hasError('required')">Mandatory</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field style="width: 100%">
            <input matInput [matDatepicker]="picker1" [(ngModel)]="variabile_data_to" placeholder="To Date" [formControl]="passwordFormControl" required>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-error *ngIf="passwordFormControl.hasError('required')">Mandatory</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field style="width: 100%">
            <input matInput placeholder="Availability REASON" [(ngModel)]="attivita" [formControl]="passwordFormControl3" required>
            <mat-error *ngIf="passwordFormControl3.hasError('required')">Mandatory</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field style="width: 100%">
            <input matInput placeholder="Agreed with" [(ngModel)]="concordato" [formControl]="passwordFormControl3" required>
            <mat-error *ngIf="passwordFormControl3.hasError('required')">Mandatory</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field style="width: 100%">
            <mat-select [(ngModel)]="ts_project" placeholder="Availability PROJECT" [formControl]="passwordFormControl4" (selectionChange)='setid(ts_project)' required>
                <mat-option *ngFor="let progetto of progetti" [value]="progetto.value">
                    {{progetto.viewValue}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="passwordFormControl4.hasError('required')">Mandatory</mat-error>
        </mat-form-field>
    </mat-card>
</div>
<br>
<div align="center">
    <a [routerLink]="['/menu']"><button style="margin:5px" mat-raised-button color="primary">Back</button></a>
    <button style="margin:5px" mat-raised-button color="primary" (click)="checkdata()" [disabled]="!passwordFormControl.valid || !passwordFormControl3.valid || !passwordFormControl4.valid">Preview</button>
</div>
