import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Login } from '../shared/login.model';
import { Setting } from '../shared/setting.model';
import { Email } from '../shared/email.model';
import {EncrDecrService} from '../services/EncrDecrService'

export class MyErrorStateMatcher implements ErrorStateMatcher {

	// tslint:disable: indent
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	email: Email = new Email();
	EMAILITALIANO: string;
	public DEFAULT: string;
	indirizziweb: string;
	id: number;
	login: Login = new Login();
	emailFormControl = new FormControl('', [Validators.required, Validators.email]);
	passwordFormControl = new FormControl('', [Validators.required]);
	id_utente: any;
	matcher = new MyErrorStateMatcher();
	setting: Setting = new Setting();

	constructor(
    private EncrDecr: EncrDecrService,
		public _auth: AuthService,
		private _router: Router,
		private _service: NotificationsService,
		public _storage: MyLocalStorageService
	) { }

	ngOnInit() { }


	loginUser() {
    this.login.password = this.EncrDecr.set(this.passwordFormControl.value.toString(), 'accamieasyjob');
		this.login.email = this.emailFormControl.value.toString();
		const suffix = this.login.email.substring( this.login.email.lastIndexOf('\@') + 1);
		this._auth.loginUser(this.login).subscribe(
			async res => {
			if (res.risposta === 'updatepwd') {
					this.login.nome = res.nome;
					this.login.cognome = res.cognome;
					this.login.id_utente = res.id;
					this._auth.updatepwd(this.login).subscribe(
						res => {
							if (res.status === 'SUCCESS') {
								this._service.success('Success', 'An email was sent with new password', {
									position: ['bottom', 'center'],
									timeOut: 5000,
									showProgressBar: true,
									pauseOnHover: false,
									clickToClose: true,
								});
								this._router.navigate(['/login'], { skipLocationChange: true });
							}
						},
						err => console.log(err)
					);
				} else if (res.risposta === 'resetpassword') {
          this.resetpwd()
          this._service.error('Warning', 'Please reset you password', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });

				} else if (res.risposta === 'changepassword') {
					this._storage.setItem('email', res.email);
					this._router.navigate(['/registrazione'], { skipLocationChange: true });
				} else if (res.risposta === 'Success') {
          const data = {"customer" : res.customer}
          this._auth.loadvisibility(data).subscribe(
						res1 => {
              this._storage.setItem('summary_report', res1[0].summary_report);
              this._storage.setItem('availability', res1[0].availability);
              this._storage.setItem('send_email', res1[0].send_email);
              this._storage.setItem('send_certificate', res1[0].send_certificate);
              this._storage.setItem('buz_trip', res1[0].buz_trip);
              this._storage.setItem('training', res1[0].training);
              this._storage.setItem('Address_Book', res1[0].Address_Book);
						},
						err => console.log(err)
					);

					this._storage.setItem('id', res.id);
					this._storage.setItem('token', res.token);
					this._storage.setItem('nome', res.nome);
					this._storage.setItem('cognome', res.cognome);
					this._storage.setItem('email', res.email);
					this._storage.setItem('profile', res.profile);
					this._storage.setItem('company', res.company);
					this._storage.setItem('tel', res.tel);
					this._storage.setItem('customer_mail', res.customer_mail);
					this._storage.setItem('username', res.username_customer);
					this._storage.setItem('email_certificati', res.email_certificati);
					this._storage.setItem('ruolo', res.ruolo);
					this._storage.setItem('customer', res.customer);
					this._storage.setItem('availabilityfull', res.availabilityfull);
					this._storage.setItem('company_name', res.company_name);
					this._storage.setItem('customer_name', res.customer_name);
					if (res.tel == '') {
						this._router.navigate(['/updateaccount'], { skipLocationChange: true });
						this._service.error('Warning', 'Please enter a valid mobile phone number', {
							position: ['bottom', 'center'],
							timeOut: 5000,
							showProgressBar: true,
							pauseOnHover: false,
							clickToClose: true,
						});
					} else if (res.ruolo == null) {
						this._router.navigate(['/updateaccount'], { skipLocationChange: true });
						this._service.error('Warning', 'Please enter your Role', {
							position: ['bottom', 'center'],
							timeOut: 5000,
							showProgressBar: true,
							pauseOnHover: false,
							clickToClose: true,
						});
					} else {
						this._service.success('Success', 'Login done', {

							position: ['bottom', 'center'],
							timeOut: 5000,
							showProgressBar: true,
							pauseOnHover: false,
							clickToClose: true,
						});
            this._storage.setItem('firstlogin','nulla')
						this._router.navigate(['/welcome'], { skipLocationChange: true });

					}
				} else {
					localStorage.clear();

					this._service.error('Errore', 'The password need to be changed. Please reset with forgot password', {
						position: ['bottom', 'center'],
						timeOut: 5000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
					this._router.navigate(['/login'], { skipLocationChange: true });
				}
			},
			err => console.log(err)
		);

	}
	resetpwd() {
    var randomstring = require("randomstring");
		this.login.email = this.emailFormControl.value;
    let password = randomstring.generate();
    this.login.id_utente = password
    this.login.password =  this.EncrDecr.set(password.toString(), 'accamieasyjob');
		this._auth.resetpwd(this.login).subscribe(
			res => {
				if (res.status == 'SUCCESS') {
					this._service.success('Success', 'An email was sent with new password', {
						position: ['bottom', 'center'],
						timeOut: 3000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);
	}
  resetpwd1() {
    var randomstring = require("randomstring");
		this.login.email = this.emailFormControl.value;
    let password = randomstring.generate();
    this.login.id_utente = password
    this.login.password =  this.EncrDecr.set(password.toString(), 'accamieasyjob');
		this._auth.resetpwd(this.login).subscribe(
			res => {
				if (res.status == 'SUCCESS') {
					this._service.success('Success', 'Welcome', {
						position: ['bottom', 'center'],
						timeOut: 3000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);
	}
}
