<br>
<div style="text-align:center">
    <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<button mat-raised-button color="primary" (click)="newactivity()">
    <mat-icon>fiber_new</mat-icon>
</button>
<button mat-raised-button color="primary" (click)="back()">Back
    <mat-icon>keyboard_backspace</mat-icon>
</button>
<br>
<mat-card class="example-card" style="width: 100%; background-color: rgb(153, 218, 238)">
    <mat-table class="tableclass" *ngIf="update_web == 'false'"
        style="border: 1px;border-color: black;border-style: solid;" [dataSource]="dataSource"
        style="width: 100%">
        <ng-container matColumnDef="description" style="text-align: -webkit-left;">
            <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.description}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="active">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row"><button mat-raised-button color="warn" (click)="delete(row.id, row.active)">
                    <mat-icon *ngIf="row.active == '1'">delete_forever</mat-icon>
                    <mat-icon style="color: green" *ngIf="row.active == '0'">check</mat-icon>
                </button></mat-cell>
        </ng-container>
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row"><button mat-raised-button color="primary" (click)="update(row)">
                    <mat-icon>update</mat-icon>
                </button></mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </mat-table>
    <br>

    <mat-form-field style="width: 100%" *ngIf="update_web == 'true'">
        <input style="width: 100%" matInput placeholder="Description" [(ngModel)]="setting.description"
            value="setting.description">
    </mat-form-field>
    <br>
    <mat-form-field style="width: 100%" *ngIf="update_web == 'new'">
        <input style="width: 100%" matInput placeholder="Description" [(ngModel)]="description_new"
            value="setting.description">
    </mat-form-field>
    <button *ngIf="update_web == 'true' || update_web == 'new' " mat-raised-button color="primary" (click)="back()">
        Back
    </button>
    <button *ngIf="update_web == 'true'" mat-raised-button color="primary" (click)="save()">
        <mat-icon>save</mat-icon>
    </button>
    <button *ngIf="update_web == 'new'" mat-raised-button color="primary" (click)="save_new()">
        <mat-icon>save</mat-icon>
    </button>
</mat-card>