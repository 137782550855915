import { Component, OnInit } from '@angular/core';
import { Progetti } from '../shared/progetti';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { AuthService } from '../auth.service';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-progetto',
  templateUrl: './update-progetto.component.html',
  styleUrls: ['./update-progetto.component.scss']
})
export class UpdateProgettoComponent implements OnInit {
company:any;
setting: Progetti = new Progetti();
  constructor(private _router: Router, public _storage: MyLocalStorageService, public _auth: AuthService, private _service: NotificationsService) { }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.setting.id = localStorage.getItem('id_project');
    this.setting.code = localStorage.getItem('project_code');
    this.setting.description = localStorage.getItem('project_description');

  }
  salvaprogetto(){
    this._auth.updateprogetto(this.setting)
    .subscribe(
      res => {
        if (res.risposta == "Errore") {
          this._service.error('Errore',
          'Errore aggiornamento progetto', {
            position: ['bottom', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
        } else {
          this._service.success('Success',
          'Progetto aggiornato', {
            position: ['bottom', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this._router.navigate(['/progetti'], { skipLocationChange: true });
        }
      },
      err => console.log(err)
    );
  }

}
