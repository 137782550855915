import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { Email } from '../shared/email.model';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { AuthService } from '../auth.service';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { Storerep } from '../shared/storerep.model';
import { Reperibilita } from '../shared/reperibilita.model';
import { element } from 'protractor';
// tslint:disable: variable-name
export interface ora {
  value: string;
  viewValue: string;
}
export interface progetto {
	// tslint:disable:indent
	value: any;
	viewValue: string;
}
@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit {
  progetti: progetto[];
  passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl1 = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl3 = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl2 = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl4 = new FormControl('', [
    Validators.required
  ]);
  storerep: Storerep = new Storerep();
	reperibilita: Reperibilita = new Reperibilita();
  variabile_data: any;
  variabile_data_to: any;
  variabile_ora: any;
  company: any;
  tiporichiesta: any;
  variabile_orario_uscita: any;
  variabile_orario_rientro: any;
  variabile_minuti: any;
  giornitocheck = '';
  attivita: any;
  concordato: any;
  check: any = [];
  ts_project: any;
  giornitemp: number;
  setting: Email = new Email();
  ore: ora[] = [];
  pipe = new DatePipe('en-US');
  temp = Date.now();
  test = this.pipe.transform(this.temp, 'dd');
  constructor(private _adapter: DateAdapter<any>,
              public _auth: AuthService,
              private datePipe: DatePipe,
              public _storage: MyLocalStorageService,
              private _router: Router,

              private _service: NotificationsService) { }

   ngOnInit() {
    this.loadcombohours();
    if (localStorage.getItem('customer') === '2') {
      this.loadproject_employee_interni();
    } else {
    this.loadproject_employee();
    this.tiporichiesta = localStorage.getItem('tiporichiesta').replace(/_/g, ' ');
    this.company = localStorage.getItem('company');
    this._adapter.setLocale('it');
    }


  }
	loadproject_employee() {
    this.setting.id_utente = localStorage.getItem('id');
		  this._auth.loadprojectemployee_straord(this.setting).subscribe(
			res => {
				if (res.risposta === 'NoValue') {
          this._service.error('Error',
          'Please, add the hours of work for a total of 8h!!!', {
          position: ['bottom', 'center'],
          timeOut: 5000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
          this._router.navigate(['/settings'], { skipLocationChange: true });
					     this.progetti = [];
				} else {
          this.progetti = res;
          const temptim = this.progetti.filter(x => x.viewValue === localStorage.getItem('timesheet'));
          this.ts_project = temptim[0].value;
				}
			},
			err => console.log(err)
		);
  }
  setid(id) {
    this.ts_project = id;
  }
  loadproject_employee_interni() {
    this.setting.id_utente = localStorage.getItem('id');
		  this._auth.loadprojectemployee_straordinterni(this.setting).subscribe(
			res => {
				if (res.risposta === 'NoValue') {
          console.log(res.risposta);
          this._service.error('Error',
          'Please, add the hours of work for a total of 8h!!!', {
          position: ['bottom', 'center'],
          timeOut: 5000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
          this._router.navigate(['/settings'], { skipLocationChange: true });
					     this.progetti = [];
				} else {
          this.progetti = res;
          const temptim = this.progetti.filter(x => x.viewValue === localStorage.getItem('timesheet'));
          this.ts_project = temptim[0].value;
				}
			},
			err => console.log(err)
		);
	}
  loadcombohours() {
    this._auth.loadcombohours(this.setting)
    .subscribe(res => {
      this.ore = res;
    },
     err => console.log(err)
   );
  }

  preview() {
    this._storage.setItem('attivita', this.attivita);
    this._storage.setItem('concordato', this.concordato);
    this._storage.setItem('timesheet', this.ts_project);
    this._storage.setItem('ora', this.variabile_ora);
    this._storage.setItem('data', this.variabile_data);
    this._storage.setItem('data_al', this.variabile_data_to);
    this._router.navigate(['/preview'], { skipLocationChange: true });
}

checkdata() {
  if (this.variabile_data > this.variabile_data_to) {
    this._service.error('Errore',
    '"To Date" is greater than "from Date"', {
      position: ['bottom', 'center'],
      timeOut: 2000,
      showProgressBar: true,
      pauseOnHover: false,
      clickToClose: true,
    });
    return;
  }
  const datada: number = Number(this.datePipe.transform(this.variabile_data, 'dd'));
  const dataa: number = Number(this.datePipe.transform(this.variabile_data_to, 'dd'));
  this.giornitemp = (dataa - datada) + 1;
  const mese: number = Number(this.datePipe.transform(this.variabile_data, 'MM'));
  const mese1: number = Number(this.datePipe.transform(this.variabile_data_to, 'MM'));
  const anno: number = Number(this.datePipe.transform(this.variabile_data_to, 'yyyy'));
  this.reperibilita.id_utente = localStorage.getItem('id');
  this.reperibilita.data = datada;
  this.reperibilita.datato = dataa;
  this.reperibilita.mese = mese;
  this.reperibilita.meseto = mese1;
  this.reperibilita.anno = anno;
  this._auth.check_lock(this.reperibilita)
     .subscribe(
       res => {
         if (res.risposta === 'Success') {
          this.reperibilita.data = this.datePipe.transform(this.variabile_data, 'yyyy-MM-dd');
          this.reperibilita.datato = this.datePipe.transform(this.variabile_data_to, 'yyyy-MM-dd');
          this._auth.checkreperibilita(this.reperibilita)
          .subscribe(res => {
            if (res.risposta === 'Success') {
              this.preview();
            } else {
              this._service.error('Errore',
              'One of inserted date are aleady stored', {
                position: ['bottom', 'center'],
                timeOut: 2000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: true,
              });
            }
          },
          err => console.log(err)
        );
         } else {
           this._service.error('Errore',
           'Mese chiuso', {
             position: ['bottom', 'center'],
             timeOut: 3000,
             showProgressBar: true,
             pauseOnHover: false,
             clickToClose: true,
           });
         }
       },
       err => console.log(err)
     );

  this.check.length = '';
  };
}
