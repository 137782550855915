import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../auth.service';
import { ExcelService } from '../../services/excel.service';
export interface settings {
  startdate: string;
  todate: string;
}
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent4 {
  starttimeweb: any;
  endtimeweb: any;
  startdateweb: any;
  enddateweb: any;
  // tslint:disable: variable-name
  estrazione_excel: any;
  setting: settings = {startdate: '', todate: ''};
  estrazione_excel1: any;
  constructor(public dialogRef: MatDialogRef<DialogComponent4>, public _auth: AuthService, private excelService: ExcelService) { }

  async doaction() {
    this.setting.startdate = this.startdateweb;
    this.setting.todate = this.enddateweb;
    this.estrazione_excel1 = await this._auth.estraisummarycustomfull(this.setting).toPromise();
    //this.estrazione_excel = await this._auth.estrairepcustom(this.setting).toPromise();
    this.exportAsXLSX(this.estrazione_excel1, this.estrazione_excel1);
    this.dialogRef.close();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  exportAsXLSX(data, data1): void {
    this.excelService.exportAsExcelFile(data, data1, 'Summary_Report');
  }
}

