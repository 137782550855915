<br>
<div style="text-align:center">
  <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
  <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<div align=center>
  <img src="assets\img\certificato.png" id="icon" alt="User Icon" style="height: 5em" />
</div>
<br>
<mat-card class="example-card" style="width: 20em; background-color: rgb(153, 218, 238)">
  <br>
  <mat-form-field>
    <mat-label>Select the certificate type</mat-label>
    <mat-select [(ngModel)]="richiesta_web" (ngModelChange)="onChange($event)">
      <mat-option *ngFor="let richiesta of richieste" [value]="richiesta.value" >
        {{richiesta.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field style="width: 100%;" *ngIf="request == 'certificato'">
    <input matInput [(ngModel)]="certificato_web" placeholder="N° Certificato">
  </mat-form-field>
</mat-card>
<button mat-raised-button (click)="openUploadDialog()">Upload</button>
