import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  company: any;
  profile: any;
  // tslint:disable: variable-name
  constructor(public _storage: MyLocalStorageService, private _router: Router) { }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.profile = localStorage.getItem('profile');
  }
  estrairep() {
    this._router.navigate(['/estrairep'], { skipLocationChange: true });
}
estraisummary() {
    this._router.navigate(['/estraisummary'], { skipLocationChange: true });
}
setavailailabilityfull() {
  this._router.navigate(['/availfull'], { skipLocationChange: true });
}
estraitrasferta() {
  this._router.navigate(['/estraitrasferta'], { skipLocationChange: true });
}
meselock() {
  this._router.navigate(['/meselock'], { skipLocationChange: true });
}
meselocktrasferta() {
  this._router.navigate(['/meselocktrasferta'], { skipLocationChange: true });
}
progetto() {
  this._router.navigate(['/progetti'], { skipLocationChange: true });
}
nuovaanagrafica() {
  this._router.navigate(['/nuovaanagrafica'], { skipLocationChange: true });
}
updateanagrafica() {
  this._router.navigate(['/updateanagrafica'], { skipLocationChange: true });
}
ts_progetto() {
  this._router.navigate(['/tsprogetti'], { skipLocationChange: true });
}
ts_entity() {
  this._router.navigate(['/tslegalentity'], { skipLocationChange: true });
}
ts_activity() {
  this._router.navigate(['/tsactivity'], { skipLocationChange: true });
}
companyroute() {
  this._router.navigate(['/company'], { skipLocationChange: true });
}
client() {
  this._router.navigate(['/client'], { skipLocationChange: true });
}
tipocontratti() {
  this._router.navigate(['/contratti'], { skipLocationChange: true });
}
programroles() {
  this._router.navigate(['/roles'], { skipLocationChange: true });
}
visitemediche() {
  this._router.navigate(['/visitmediche'], { skipLocationChange: true });
}
roles() {
  this._router.navigate(['/rolesettings'], { skipLocationChange: true });
}
admintraining(){
  this._router.navigate(['/admintraining'], { skipLocationChange: true });
}
}
