import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../auth.service';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Client } from '../shared/client.model';

@Component({
  selector: 'app-rolesettings',
  templateUrl: './rolesettings.component.html',
  styleUrls: ['./rolesettings.component.scss']
})
export class RolesettingsComponent implements OnInit {
  dataSource = [];
  dataSource1 = [];
  tableColumns: string[] = ['Role', 'id', ];
  tableColumns1: string[] = [];
  setting: Client = new Client();
  update_web: any ="false";
  name_web:any;
  fullname_web:any;
  update_settingweb: any = 'false';
  Employee:any
  Summary: any;
  Internal_Settings: any;
  Timesheet: any;
  Availability: any;
  id_customer: any;
  Admin:any;
  insert: string = 'false';
  namesetting: any;
  Training: any;
  constructor(private _service: NotificationsService, public _auth: AuthService, public _storage: MyLocalStorageService, private _router: Router) { }

  ngOnInit() {
    this.loadroles();
  }
  loadcustrolesettings(id){
    this.id_customer = id;
    let idcust = {"idcust" : id}
    this._auth.loadcustrolesettings(idcust)
    .subscribe(
      res => {
        if (res.risposta == "error") {
          this.insert = 'true'
          this.Employee = 0
          this.Summary = 0
          this.Internal_Settings = 0
          this.Timesheet = 0
          this.Availability = 0
          this.Training = 0
          this.Admin = 0
          this._service.error('Error',
          'No settings found for this Role', {
          position: ['bottom', 'center'],
          timeOut: 5000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        } else {
          this.Employee = res[0].Employee
          this.Summary = res[0].Summary
          this.Internal_Settings = res[0].Internal_Settings
          this.Timesheet = res[0].Timesheet
          this.Availability = res[0].Availability
          this.Training = res[0].Training
          this.Admin = res[0].Admin
        }
      },
      err => console.log(err)
    );
  }
  savesettings(){
    this.Employee = this.Employee.toString().split(true).join('1')
    this.Employee = this.Employee.toString().split(false).join('0')
    this.Summary = this.Summary.toString().split(true).join('1')
    this.Summary = this.Summary.toString().split(false).join('0')
    this.Internal_Settings = this.Internal_Settings.toString().split(true).join('1')
    this.Internal_Settings = this.Internal_Settings.toString().split(false).join('0')
    this.Timesheet = this.Timesheet.toString().split(true).join('1')
    this.Timesheet = this.Timesheet.toString().split(false).join('0')
    this.Availability = this.Availability.toString().split(true).join('1')
    this.Availability = this.Availability.toString().split(false).join('0')
    this.Training = this.Training.toString().split(true).join('1')
    this.Training = this.Training.toString().split(false).join('0')
    this.Admin = this.Admin.toString().split(true).join('1')
    this.Admin = this.Admin.toString().split(false).join('0')
    let settingclient ={
      "Employee" : this.Employee,
      "Summary" : this.Summary,
      "Internal_Settings" : this.Internal_Settings,
      "Timesheet" : this.Timesheet,
      "Availability" : this.Availability,
      "id_customer" : this.id_customer,
      "Training" : this.Training,
      "Admin" : this.Admin
    }
    if (this.insert === 'true') {
      this._auth.insertuserrolesettings(settingclient).subscribe(
        res => {
          if (res.risposta == "Errore") {
            this._service.error('Error',
            'Ops something gone wrong', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
            this.Employee = 0
            this.Summary = 0
            this.Internal_Settings = 0
            this.Timesheet = 0
            this.Availability = 0
            this.Training = 0
            this.Admin = 0
          } else {
            this._service.success('Success',
            'Settings Inserted', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
            this.Employee = 0
            this.Summary = 0
            this.Internal_Settings = 0
            this.Timesheet = 0
            this.Availability = 0
            this.Training = 0
            this.Admin = 0
            this.update_settingweb = false
            this.insert='false'
          }
        },
        err => console.log(err)
      );
    } else {
    this._auth.saveuserrolesettings(settingclient)
    .subscribe(
      res => {
        if (res.risposta == "Errore") {
          this._service.error('Error',
          'Ops something gone wrong', {
            position: ['bottom', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this.Employee = 0
          this.Summary = 0
          this.Internal_Settings = 0
          this.Timesheet = 0
          this.Availability = 0
          this.Training = 0
          this.Admin = 0
        } else {
          this._service.success('Success',
          'Settings Saved', {
            position: ['bottom', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this.Employee = 0
          this.Summary = 0
          this.Internal_Settings = 0
          this.Timesheet = 0
          this.Availability = 0
          this.Admin = 0
          this.Training = 0
          this.update_settingweb = false
        }
      },
      err => console.log(err)
    );
  }
  this.loadrolesettings( localStorage.getItem('profile'));
  }
  loadroles(){
    this._auth.loaduserrole()
    .subscribe(
      res => {
        if (res.risposta == "Errore") {
          this.dataSource = [];
        } else {
          this.dataSource = res;
        }
      },
      err => console.log(err)
    );
  }
  settings(id, name){
    this.namesetting = name
    this.update_settingweb = 'true'
    this.loadcustrolesettings(id)
  }
  back(){
    this._router.navigate(['/admin'], { skipLocationChange: true });
  }
  delete(id_client){
    this.setting.id=id_client;
      this._auth.delete_role(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
            'Role Deleted', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
          this.loadroles();
        },
        err => console.log(err)
      );
      }
  update(Role, id){
    this.setting.id = id;
    this.name_web=Role;
    this.update_web="true";
    this.update_settingweb = 'false'
}
save(){
  this.setting.name = this.name_web;
  this._auth.update_userrole(this.setting)
    .subscribe(res => {
      if (res.risposta == "Success") {
        this._service.success('Success',
        'Client Updated', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this.update_web="false";
        this.loadroles();
      } else {
        this._service.error('Error',
        'Client not Updated', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
    },
     err => console.log(err)
   );
}
newrole(){
  this.name_web="";
  this.fullname_web="";
  this.update_web="new";
}
savenew(){
  this.setting.name = this.name_web;
  this._auth.newuserrole(this.setting)
    .subscribe(res => {
      if (res.risposta == "Success") {
        this._service.success('Success',
        'Client Inserted', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this.update_web="false";
        this.loadroles();
      } else {
        this._service.error('Error',
        'Client not Inserted', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
    },
     err => console.log(err)
   );
}

loadrolesettings(profile) {
  const data = {"ruolo" : profile}
  this._auth.loadrolesettings(data).subscribe(
    res1 => {
      this._storage.setItem('Employeesett', res1[0].Employee);
      this._storage.setItem('Availabilitysett', res1[0].Availability);
      this._storage.setItem('Summarysett', res1[0].Summary);
      this._storage.setItem('Timesheetsett', res1[0].Timesheet);
      this._storage.setItem('Internal_Settingssett', res1[0].Internal_Settings);
      this._storage.setItem('Trainingsett', res1[0].Training);
      this._storage.setItem('Adminsett', res1[0].Admin);
    },
    err => console.log(err)
  );

}
}
