import { Component, OnInit, ViewChild } from '@angular/core';
import { Estrairep } from '../shared/estrairep.model';
import { AuthService } from '../auth.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ExcelService } from '../services/excel.service';
import { DatePipe } from '@angular/common';
import { Trasferta } from '../shared/trasferta.model';
import { RepDatasource } from '../shared/rep-datasource.model';
import { ConfirmUnconfim } from '../shared/confirm-unconfim.model';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from 'angular2-notifications';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClient } from '@angular/common/http';

// tslint:disable: indent
	// tslint:disable: variable-name
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
// tslint:disable: class-name
export interface anno {
	value: string;
	viewValue: string;
}
export interface mese {
	value: string;
	viewValue: string;
}

@Component({
  selector: 'app-presenza-trasfert-estrazione',
  templateUrl: './presenza-trasfert-estrazione.component.html',
  styleUrls: ['./presenza-trasfert-estrazione.component.scss']
})
export class PresenzaTrasfertEstrazioneComponent implements OnInit {

	@ViewChild(MatSort, { static: true })
	sort: MatSort;
  checklock: any;
	company: any;
	tableColumns: string[] = ['resource', 'total', 'locked', 'idutente'];
	setting: Estrairep = new Estrairep();
	sortdata: RepDatasource[] = [];
	dataSource = new MatTableDataSource(this.sortdata);
	reperibilita: Trasferta = new Trasferta();
	confirm_uncofirm: ConfirmUnconfim = new ConfirmUnconfim();
	mese_web: any;
	anno_web: any;
	id_utente: any;
	lock: any;
	temp_estrazione_excel: [];
	estrazione_excel: [];
	anni: anno[] = [
		{ value: '2019', viewValue: '2019' },
		{ value: '2020', viewValue: '2020' },
		{ value: '2021', viewValue: '2021' },
		{ value: '2022', viewValue: '2022' },
    { value: '2023', viewValue: '2023' },
    { value: '2024', viewValue: '2024' },
	];
	mesi: mese[] = [
		{ value: '1', viewValue: 'Gennaio' },
		{ value: '2', viewValue: 'Febbraio' },
		{ value: '3', viewValue: 'Marzo' },
		{ value: '4', viewValue: 'Aprile' },
		{ value: '5', viewValue: 'Maggio' },
		{ value: '6', viewValue: 'Giugno' },
		{ value: '7', viewValue: 'Luglio' },
		{ value: '8', viewValue: 'Agosto' },
		{ value: '9', viewValue: 'Settembre' },
		{ value: '10', viewValue: 'Ottobre' },
		{ value: '11', viewValue: 'Novembre' },
		{ value: '12', viewValue: 'Dicembre' },

	];
	constructor(
    private httpClient: HttpClient,
		private _router: Router,
		public _storage: MyLocalStorageService,
		private datePipe: DatePipe,
		public _auth: AuthService,
  private excelService: ExcelService,
  private _service: NotificationsService
	) {}

	ngOnInit() {
		this.company = localStorage.getItem('company');
		// tslint:disable: no-shadowed-variable
		const mese = this.datePipe.transform(new Date(), 'M');
		const anno = this.datePipe.transform(new Date(), 'yyyy');
		this.reperibilita.mese = mese;
		this.reperibilita.anno = anno;
		this.mese_web = mese;
    this.anno_web = anno;
    this._storage.setItem('mese', this.mese_web);
    this._storage.setItem('anno', this.anno_web);
		this.loadtrasfertaglobal();
	}
	// tslint:disable-next-line: use-lifecycle-interface
	ngAfterViewInit() {
		this.dataSource.sort = this.sort;
	}
	estrai() {
		this.setting.mese = this.mese_web;
		this.setting.anno = this.anno_web;
		this._auth.estraitrasferta(this.setting).subscribe(
			res => {
				this.estrazione_excel = res;
				this.exportAsXLSX(this.estrazione_excel);
			},
			err => console.log(err)
		);
	}
	exportAsXLSX(data: any[]): void {
		//this.excelService.exportAsExcelFile(data, 'availability_EMM_' + this.setting.anno + '_' + this.setting.mese);
	}
	loadtrasfertaglobal() {
		this.reperibilita.id_utente = localStorage.getItem('id');
		this._auth.leggitrasferta_global(this.reperibilita).subscribe(
			res => {
				// tslint:disable:triple-equals
				if (res.risposta == 'novalue') {
					this.dataSource = new MatTableDataSource([]);
				} else {
					this.dataSource = new MatTableDataSource(res);
					this.dataSource.sort = this.sort;
				}
			},
			err => console.log(err)
		);
		this._router.navigate(['/estraitrasferta'], { skipLocationChange: true });
	}
  private async  fetchData(){
    const apiUrl = ('https://easyjob.ddns.net:3001/reperibilita/leggitrasferta');
    const promise = this.httpClient.post(apiUrl, this.confirm_uncofirm).toPromise();
    console.log(promise);
    promise.then((data)=>{
    console.log("Promise resolved with: " + JSON.stringify(data));
    this.checklock = data[0].locked;
    console.log(this.checklock)
    if (this.checklock == '0') {
      this.confirmtrasferta(this.confirm_uncofirm.idutente);
    } else if  (this.checklock == '1') {
      this.unconfirmtrasferta(this.confirm_uncofirm.idutente);
    }
    }).catch((error)=>{
    console.log("Promise rejected with " + JSON.stringify(error));
    });
  }
	confirm(id_utente_web: any) {
    this.confirm_uncofirm.idutente = id_utente_web;
    this.confirm_uncofirm.mese = this.mese_web;
    this.confirm_uncofirm.anno = this.anno_web;
    this.fetchData();

  }
  confirmtrasferta(id_utente_web) {
    this.confirm_uncofirm.idutente = id_utente_web;
    this.confirm_uncofirm.mese = this._storage.getItem('mese');
      this.confirm_uncofirm.anno = this._storage.getItem('anno');
      this.confirm_uncofirm.locked= '1';
    this._auth.confirmtrasferta(this.confirm_uncofirm).subscribe(res => {
      if(res.risposta == 'retry') {
        this.confirmtrasferta(id_utente_web);
      } else if(res.risposta == 'Success'){
        this.loadtrasfertaglobal();
      }
    }, err => console.log(err));
  }
  unconfirmtrasferta(id_utente_web) {
    this.confirm_uncofirm.idutente = id_utente_web;
		  this.confirm_uncofirm.mese = this._storage.getItem('mese');
      this.confirm_uncofirm.anno = this._storage.getItem('anno');
      this.confirm_uncofirm.locked= '0';
    this._auth.uncofirmtrasferta(this.confirm_uncofirm).subscribe(res => {
      if(res.risposta == 'retry') {
      this.unconfirmtrasferta(id_utente_web);
    } else {
      this.loadtrasfertaglobal();
    }}, err => console.log(err));
}
  delete(id_utente_web: any) {
    console.log('delete');
		  this.confirm_uncofirm.idutente = id_utente_web;
		  this.confirm_uncofirm.mese = this._storage.getItem('mese');
    this.confirm_uncofirm.anno = this._storage.getItem('anno');
    console.log(this.confirm_uncofirm);
		  this._auth.delete_reptrasferta(this.confirm_uncofirm).subscribe(
			res => {
				if (res.risposta == 'Success') {
					this._service.success('Success', 'Record deleted', {
						position: ['bottom', 'center'],
						timeOut: 2000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
          });
     this._router.navigate(['/admin'], { skipLocationChange: true });
				} else {
					this._service.error('Error', 'Record not deleted', {
						position: ['bottom', 'center'],
						timeOut: 2000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);

	}
	admin() {
		this._router.navigate(['/admin'], { skipLocationChange: true });
	}
	changemese(e: any) {
		this.reperibilita.mese = this.mese_web;
    this.reperibilita.anno = this.anno_web;
    this._storage.setItem('mese', this.mese_web);
		this.loadtrasfertaglobal();
	}
	changeanno(e: any) {
		this.reperibilita.mese = this.mese_web;
    this.reperibilita.anno = this.anno_web;
    this._storage.setItem('anno', this.anno_web);
		this.loadtrasfertaglobal();
  }
}
