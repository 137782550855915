<br>
<div style="text-align:center">
    <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
    <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>

<html>
<body>
<mat-card class="example-card" style="width: 80%; background-color: rgb(153, 218, 238)">
  <mat-form-field style="width: 70%;">
    <input matInput placeholder="Name" [(ngModel)]="nome" disabled="true">
  </mat-form-field>
  <br>
  <mat-form-field style="width: 70%;">
    <input matInput placeholder="Surname" [(ngModel)]="cognome" disabled="true">
  </mat-form-field>
  <br>
  <mat-form-field style="width: 70%;">
    <input matInput placeholder="Provider Email" [(ngModel)]="email_provider" disabled="true">
  </mat-form-field>
  <br>
  <mat-form-field style="width: 70%;">
    <input matInput placeholder="Customer Email" [(ngModel)]="email_customer" disabled="true">
  </mat-form-field>
  <br>
  <mat-form-field style="width: 70%;">
    <input matInput placeholder="Company" [(ngModel)]="company1" disabled="true">
  </mat-form-field>
  <br>
  <mat-form-field style="width: 70%;">
    <input matInput placeholder="Role (it means the role covered within the project):" [(ngModel)]="ruolo" disabled="false" [formControl]="passwordFormControl" required>   
    <mat-error *ngIf="passwordFormControl.hasError('required')">Mandatory</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field style="width: 70%;">
    <input matInput placeholder="Mobile" [(ngModel)]="telefono" disabled="false" [formControl]="passwordFormControl1" required>
  </mat-form-field>
  <br>
  <button mat-raised-button color="primary" (click)="updateprofile()" [disabled]="!passwordFormControl.valid || !passwordFormControl1.valid">Update</button>
</mat-card>
<mat-card class="example-card" style="width: 80%; background-color: rgb(233, 243, 142)">
  <p> Company email for Certificate:</p> <br>
  <label> {{emailcertificati}}</label>
</mat-card>
</body>
</html>