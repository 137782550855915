import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { Email } from '../shared/email.model';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { AuthService } from '../auth.service';
import {FormControl, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import { AmazingTimePickerService } from 'amazing-time-picker';

export interface ora {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-permesso-in-uscita',
  templateUrl: './permesso-in-uscita.component.html',
  styleUrls: ['./permesso-in-uscita.component.scss'],
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'ja-JP'}]
})
export class PermessoInUscitaComponent implements OnInit {
  company: any;
  passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl1 = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl2 = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl3 = new FormControl('', [
    Validators.required
  ]);
  tiporichiesta:any;
  variabile_data = new Date();
  variabile_ora: any;
  variabile_orario_uscita: any;
  variabile_orario_rientro: any;
  concordato: any;
  setting: Email = new Email();
  ore: ora[] = [
    {value: '1', viewValue: '1'},
    {value: '2', viewValue: '2'},
    {value: '3', viewValue: '3'},
    {value: '4', viewValue: '4'},
    {value: '5', viewValue: '5'},
    {value: '6', viewValue: '6'},
    {value: '7', viewValue: '7'},
  ];
  constructor(private atp: AmazingTimePickerService, private _adapter: DateAdapter<any>, public _auth: AuthService, public _storage: MyLocalStorageService, private _router: Router,  private _service: NotificationsService) { }

  ngOnInit() {
    this.tiporichiesta=localStorage.getItem('tiporichiesta').replace(/_/g, " ");
    this.variabile_orario_uscita="14:00"
    this.company = localStorage.getItem('company');
    this._adapter.setLocale('it')
  }

  preview(){
    this._storage.setItem('orario_ingresso', null);
    this._storage.setItem('data', this.variabile_data.toString());
    this._storage.setItem('ora', this.variabile_ora);
    this._storage.setItem('orario', this.variabile_orario_uscita);
    this._storage.setItem('concordato', this.concordato);
    this._router.navigate(['/preview'], { skipLocationChange: true });
  }
  onChange(event) {
    if (event == 1 ){
      this.variabile_orario_uscita = '16:30';
    }
    if (event == 2 ){
      this.variabile_orario_uscita = '15:30';
    }
    if (event == 3 ){
      this.variabile_orario_uscita = '14:30';
    }
    if (event == 4 ){
      this.variabile_orario_uscita = '13:30';
    }
    if (event == 5 ){
      this.variabile_orario_uscita = '12:30';
    }
	}
  open(ev: any) {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => {
      this.variabile_orario_uscita = time;
    });
  }
}
