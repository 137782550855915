import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { Trasferta } from '../shared/trasferta.model';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { RepUserDatasource } from '../shared/rep-user-datasource.model';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
export interface anno {
  value: string;
  viewValue: string;
}
export interface mese {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-update-trasferta',
  templateUrl: './update-trasferta.component.html',
  styleUrls: ['./update-trasferta.component.scss']
})
export class UpdateTrasfertaComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  mese_web: any;
  anno_web: any;
  anni: anno[] = [
    { value: '2019', viewValue: '2019' },
    { value: '2020', viewValue: '2020' },
    { value: '2021', viewValue: '2021' },
    { value: '2022', viewValue: '2022' },
    { value: '2023', viewValue: '2023' },
    { value: '2024', viewValue: '2024' },
    { value: '2025', viewValue: '2025' },
  ];
  mesi: mese[] = [
    { value: '1', viewValue: 'Gennaio' },
    { value: '2', viewValue: 'Febbraio' },
    { value: '3', viewValue: 'Marzo' },
    { value: '4', viewValue: 'Aprile' },
    { value: '5', viewValue: 'Maggio' },
    { value: '6', viewValue: 'Giugno' },
    { value: '7', viewValue: 'Luglio' },
    { value: '8', viewValue: 'Agosto' },
    { value: '9', viewValue: 'Settembre' },
    { value: '10', viewValue: 'Ottobre' },
    { value: '11', viewValue: 'Novembre' },
    { value: '12', viewValue: 'Dicembre' }
  ];
  tableColumns:  string[] = ['data', 'locked'];
  reperibilita: Trasferta = new Trasferta();
  sortdata: RepUserDatasource[] =[];
  dataSource  = new MatTableDataSource(this.sortdata);
  date: any;
  id_rep: any;
  mese: boolean;
  company: any;
  constructor(public _storage: MyLocalStorageService, private datePipe: DatePipe, private _service: NotificationsService, public _auth: AuthService, private _router: Router) {

   }
  ngOnInit() {
    let mese = this.datePipe.transform(new Date(), "M");
    let anno = this.datePipe.transform(new Date(), "yyyy");
   this.company = localStorage.getItem('company');
   this.reperibilita.mese = mese;
   this.reperibilita.anno = anno;
   this.mese_web = mese;
   this.anno_web = anno;
   this.loadreperibilita();
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  loadreperibilita ()  {
    this.reperibilita.id_utente = localStorage.getItem('id');
    this._auth.leggitrasferta(this.reperibilita)
    .subscribe(
      res => {
        if (res.risposta =="novalue"){
          this.dataSource = new MatTableDataSource([]);
        } else {
          this._storage.setItem('mese', res[0].mese);
          this._storage.setItem('anno', res[0].anno);
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.sort = this.sort;
      }
      },
      err => console.log(err)
    );
}

    deleterep (id_rep){
      this.reperibilita.id_utente = localStorage.getItem('id');
      this.reperibilita.id = id_rep;
      this._auth.deletetrasferta(this.reperibilita)
      .subscribe(
        res => {
            if (res.risposta == "Success"){
              this._service.success('Success',
              'Record deleted', {
                position: ['bottom', 'center'],
                timeOut: 1000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: true,
              });
              this.loadreperibilita();
            } else {
              this._service.error('Errore',
          'Record validated or month closed', {
            position: ['bottom', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
            }
        }
      )
    }
    reperibilitaaction(){
      this._router.navigate(['/trasferta'], { skipLocationChange: true });
    }
    changemese(e){
      this.reperibilita.mese = this.mese_web;
      this.reperibilita.anno = this.anno_web;
      this.loadreperibilita();
    }
    changeanno(e){
      this.reperibilita.mese = this.mese_web;
      this.reperibilita.anno = this.anno_web;
      this.loadreperibilita();
    }
}

