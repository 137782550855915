<br>
<div style="text-align:center">
  <img width="10%" *ngIf="company=='1'" src="../assets/logo_emm_retina.png">
  <img width="5%" *ngIf="company=='2'" src="../assets/diemmegi.jpg">
</div>
<br>
<button mat-raised-button color="primary" (click)="newproject()">
  <mat-icon>fiber_new</mat-icon>Nuovo
</button>
<mat-form-field *ngIf="nuovo == true">
  <input matInput placeholder="Nuovo Progetto" [(ngModel)]="progetto">
</mat-form-field>
<br>
<button *ngIf="nuovo == true" mat-raised-button color="primary" (click)="saveproject()">
  <mat-icon>save_alt</mat-icon>Salva
</button>
<br>
<br>
<mat-card class="example-card" style="width: 100%; background-color: rgb(153, 218, 238)">
  <mat-table style="border: 1px;border-color: black;border-style: solid" [dataSource]="dataSource" style="width: 100%">
    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef>Code</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.code}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.description}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-raised-button color="primary" (click)="update(row.id, row.code, row.description)">
          <mat-icon>update</mat-icon>Update
        </button></mat-cell>
    </ng-container>
    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let row"> 
        <button *ngIf="row.active == '1'" mat-raised-button color="warn" (click)="delete(row.id)"> 
          <mat-icon>radio_button_checked</mat-icon>Deactivate
        </button>
        <button *ngIf="row.active == '0'" mat-raised-button style="background-color: chartreuse" (click)="reactivate(row.id)">
          <mat-icon>radio_button_unchecked</mat-icon>Activate
        </button></mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
  </mat-table>
</mat-card>