import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { Email } from '../shared/email.model';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { AuthService } from '../auth.service';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

@Component({
  selector: 'app-ferie-piu-giorni',
  templateUrl: './ferie-piu-giorni.component.html',
  styleUrls: ['./ferie-piu-giorni.component.scss']
})
export class FeriePiuGiorniComponent implements OnInit {

  passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl1 = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl2 = new FormControl('', [
    Validators.required
  ]);
  company: any;
  variabile_data: any;
  variabile_ora: any;
  tiporichiesta:any;
  variabile_data_al: any;
  variabile_orario_uscita: any;
  variabile_orario_rientro: any;
  concordato: any;
  setting: Email = new Email();
  constructor(private _adapter: DateAdapter<any>, public _auth: AuthService, public _storage: MyLocalStorageService, private _router: Router,  private _service: NotificationsService) { }

  ngOnInit() {
    this.tiporichiesta=localStorage.getItem('tiporichiesta').replace(/_/g, " ");
    this.company = localStorage.getItem('company');
    this._adapter.setLocale('it')
  }

  preview(){
    this._storage.setItem('data_al', this.variabile_data_al);
    this._storage.setItem('data', this.variabile_data);
    this._storage.setItem('concordato', this.concordato);
    this._storage.setItem('ora', '8');
    this._storage.setItem('orario', null);
    this._storage.setItem('orario_ingresso', null);
    this._router.navigate(['/preview'], { skipLocationChange: true });
  }
}
