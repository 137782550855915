import { Component, OnInit, ViewChild } from '@angular/core';
import { TsProgetto } from '../shared/ts-progetto.model';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { Tsproject } from '../shared/tsproject.model';
import { NotificationsService } from 'angular2-notifications';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import * as internal from 'stream';
import { Progetti } from '../shared/progetti';
export interface entity {
  value: string;
  viewValue: string;
}
export interface progetti {
  code: string;
  description: string;
  id_legal_entity: number;
}

@Component({
  selector: 'app-ts-progetti',
  templateUrl: './ts-progetti.component.html',
  styleUrls: ['./ts-progetti.component.scss']
})
export class TsProgettiComponent implements OnInit {
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  sortdata: Tsproject[] = [];
	dataSource = new MatTableDataSource(this.sortdata);
  passwordFormControl = new FormControl('', [Validators.required]);
  passwordFormControl1 = new FormControl('', [Validators.required]);
  passwordFormControl2 = new FormControl('', [Validators.required]);
  code_new:any;
  description_new:any;
  id_legal_entity_new:any;
  entities: entity [];
  progetticaricati: progetti[];
  progettidacaricare : progetti;
  company:any;
  update_web:any = "false";
  //dataSource = [];
  tableColumns: string[] = ['code', 'description', 'id_legal_entity', 'active', 'id'];
  setting: TsProgetto = new TsProgetto();
  constructor(public _storage: MyLocalStorageService, private _router: Router, private _service: NotificationsService, public _auth: AuthService) { }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.loadprogettits();
  }
	ngAfterViewInit() {
		this.dataSource.sort = this.sort;
	}
  loadprogettits(){
    this._auth.load_progetti_ts(this.setting)
    .subscribe(
      res => {
        if (res.risposta == "Errore") {
          this.dataSource = new MatTableDataSource([]);
        } else {
          this.dataSource = new MatTableDataSource(res);
          this.progetticaricati = res
					this.dataSource.sort = this.sort;
        }
      },
      err => console.log(err)
    );
  }

delete(id_ts_project, active){
this.setting.id=id_ts_project;
if (active == 1) {
  this._auth.disable_progetti_ts(this.setting)
  .subscribe(
    res => {
      if (res.risposta == "Success") {
        this._service.success('Success',
        'Progetto disabilitato', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this.deleteprogettidagliutenti();
      } else {
        this._service.error('Error',
        'Qualcosa è andato storto', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
      this.loadprogettits();
    },
    err => console.log(err)
  );

} else {

  this._auth.enable_progetti_ts(this.setting)
  .subscribe(
    res => {
      if (res.risposta == "Success") {
        this._service.success('Success',
        'Progetto riabilitato', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      } else {
        this._service.error('Error',
        'Qualcosa è andato storto', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
      this.loadprogettits();
    },
    err => console.log(err)
  );
}
  }

  update(row){
    this.setting=row;
    this.update_web="true";
    this.loadentity_web();

  }
    public importproj(e) {
      console.log(e.target.files[0]);
      /* wire up file reader */
      const target: DataTransfer = <DataTransfer>(<unknown>event.target);
      if (target.files.length !== 1) {
        throw new Error('Cannot use multiple files');
      }
      const reader: FileReader = new FileReader();
      reader.readAsBinaryString(target.files[0]);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data:any[] = XLSX.utils.sheet_to_json(ws);
        data.forEach(element => {
          let tempresult =  this.progetticaricati.findIndex(i => i.code === element.PROJECT_CODE);
         console.log(tempresult)
        if (tempresult == -1){
          this._auth.insert_project(element)
          .subscribe(
            res => {
              if (res.risposta == "Success") {
                this._service.success('Success',
                'New Project inserted', {
                  position: ['bottom', 'center'],
                  timeOut: 3000,
                  showProgressBar: true,
                  pauseOnHover: false,
                  clickToClose: true,
                });
              } else {
                this._service.error('Error',
                'Project not inserted', {
                  position: ['bottom', 'center'],
                  timeOut: 3000,
                  showProgressBar: true,
                  pauseOnHover: false,
                  clickToClose: true,
                });
              }
              this.loadprogettits();
            },
            err => console.log(err)
          );
            } else {
              this.setting.code = element.PROJECT_CODE
              this._auth.enable_progetti_ts_code(this.setting)
              .subscribe(
                res => {
                  console.log(res)
                },
                err => console.log(err)
              );
            }
        });
      };
    }
  deleteprogettidagliutenti(){

    this._auth.deleteprogettidagliutenti(this.setting)
    .subscribe(res => {
      if (res.risposta == "Success") {
        this._service.success('Success',
        'Progetto eliminato dagli utenti', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this.update_web="false";
      }
      this._router.navigate(['/tsprogetti'], { skipLocationChange: true });
    },
     err => console.log(err)
   );
  }
  loadentity_web(){
    this._auth.loadentity(this.setting)
    .subscribe(res => {
      this.entities = res;
    },
     err => console.log(err)
   );
  }
  save(){
    this._auth.update_progetti_ts(this.setting)
    .subscribe(res => {
      if (res.risposta == "Success") {
        this._service.success('Success',
        'Progetto aggiornato', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this.update_web="false";
      } else {
        this._service.error('Error',
        'Progetto non aggiornato', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
    },
     err => console.log(err)
   );
  }
back(){
  this._router.navigate(['/admin'], { skipLocationChange: true });
}
newproject(){
  this.update_web="new";
  this.loadentity_web();

}
checkprogetto(){
  this.setting.code=this.code_new;
  this.setting.description=this.description_new;
  this.setting.id_legal_entity=this.id_legal_entity_new;
  console.log(this.setting)
  this._auth.check_progetto_ts(this.setting)
  .subscribe(res => {
    if (res.risposta == "Success") {
      this.save_new();

    } else {

      this._service.error('Error',
      'Progetto Gia esistente', {
        position: ['bottom', 'center'],
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
    }
  },
   err => console.log(err)
 );
}

save_new(){
  this.setting.code=this.code_new;
  this.setting.description=this.description_new;
  this.setting.id_legal_entity=this.id_legal_entity_new;
  this._auth.nuovo_progetto_ts(this.setting)
  .subscribe(res => {
    if (res.risposta == "Success") {
      this._service.success('Success',
      'Progetto inserito', {
        position: ['bottom', 'center'],
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
      this.update_web="false";
    } else {
      this._service.error('Error',
      'Progetto non inserito', {
        position: ['bottom', 'center'],
        timeOut: 3000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
    }
    this._router.navigate(['/tsprogetti'], { skipLocationChange: true });
  },
   err => console.log(err)
 );
}
}
