import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { Anagrafica } from '../shared/anagrafica.model';
import { AuthService } from '../auth.service';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Login } from '../shared/login.model';
import { EncrDecrService } from '../services/EncrDecrService';
      // tslint:disable:triple-equals

// tslint:disable:class-name
export interface gender {
  value: string;
  viewValue: string;
}
export interface progetto {
  value: string;
  viewValue: string;
}
export interface role {
  value: string;
  viewValue: string;
}
export interface rolesuper {
  value: string;
  viewValue: string;
}
export interface company {
  value: string;
  viewValue: string;
}
export interface customer {
  value: string;
  viewValue: string;
}
export interface citta {
  [x: string]: any;
  viewValue: string;
}
export interface contratto {
  value: string;
  viewValue: string;
}
export interface StateGroup {
  letter: string;
  names: string[];
}
@Component({
  selector: 'app-nuovaanagrafica',
  templateUrl: './nuovaanagrafica.component.html',
  styleUrls: ['./nuovaanagrafica.component.scss']
})
export class NuovaanagraficaComponent implements OnInit {
  myControl = new FormControl();
  options: string[] = [];
  login: Login = new Login();
  filteredOptions: Observable<string[]>;
  anagrafica: Anagrafica = new Anagrafica();
  textFormControl  = new FormControl('', [Validators.required]);
  textFormControl1 = new FormControl('', [Validators.required]);
  textFormControl2 = new FormControl('', [Validators.required, Validators.email]);
  textFormControl3 = new FormControl('', [Validators.required]);
  textFormControl4 = new FormControl('', [Validators.required]);
  textFormControl5 = new FormControl('', [Validators.required]);
  textFormControl6 = new FormControl('', [Validators.email]);
  textFormControl7 = new FormControl('', [Validators.required]);
  textFormControl8 = new FormControl('', [Validators.required]);
  progetti: progetto [];
  companies: company [];
  customers: customer[];
  roles: role[] = [{value: '2' , viewValue: 'User' }, {value: '1' , viewValue: 'Admin' },  {value: '3' , viewValue: 'Training' }];
  // tslint:disable:max-line-length
  rolesupers: rolesuper[] = [{value: '2' , viewValue: 'User' }, {value: '1' , viewValue: 'Admin' }, {value: '3' , viewValue: 'Training'}  ];
  genders: gender[] = [{value: 'M', viewValue: 'M'}, {value: 'F', viewValue: 'F'}, {value: 'Fluid', viewValue: 'Fluid'}, {value: 'Agender', viewValue: 'Agender'}];
  contratti: contratto[];
  cittas: citta[] = [];

  // tslint:disable: variable-name
  constructor(private EncrDecr: EncrDecrService, public _storage: MyLocalStorageService, private _router: Router, public _auth: AuthService, private _service: NotificationsService) { }

  ngOnInit() {
    this.loadcompany();
    this.loadclient();
    this.loadcitta();
    this.loadcontratto();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),

    );
  }
  selectedStates = this.cittas;

  onKey(value) {
  this.selectedStates = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.cittas.filter(option => option.toLowerCase().startsWith(filter));
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
   return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  loadcontratto(){
    this._auth.loadcontratto()
  .subscribe(res => {
    this.contratti = res;
  },
   err => console.log(err)
 );
  }

  salvaanagrafica() {
    if (this.anagrafica.customer_mail == null ) {
      this.anagrafica.customer_mail = '';
    }
    if (this.anagrafica.customer_mail == undefined ) {
      this.anagrafica.customer_mail = '';
    }
    this.anagrafica.cognome = this.anagrafica.cognome.replace(/\'/, "\\'");
    this._auth.salvaanagrafica(this.anagrafica)
    .subscribe(res => {
      if (res.risposta == 'Success') {
        this.generatepwd();
        this._service.success('Success',
        'User created', {
        position: ['bottom', 'center'],
        timeOut: 1000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
        this._router.navigate(['/admin'], { skipLocationChange: true });
    } else {
      this._service.error('Errore',
      'User not created', {
      position: ['bottom', 'center'],
      timeOut: 1000,
      showProgressBar: true,
      pauseOnHover: false,
      clickToClose: true,
    });
    }},
     err => console.log(err)
    );
}

loadproject_web() {
  this._auth.loadproject1(this.anagrafica)
  .subscribe(res => {
    this.progetti = res;
  },
   err => console.log(err)
 );
}
loadcompany() {
  this._auth.loadcompany(this.anagrafica)
  .subscribe(res => {
    this.companies = res;
  },
   err => console.log(err)
 );
}
loadcitta(){
  this._auth.loadcitta()
  .subscribe(res => {
    res.forEach(element => {
      this.options.push (element.citta)
      this.cittas.push (element.citta)
    });

  },
   err => console.log(err)
 );
}
loadclient() {
  this._auth.loadclient(this.anagrafica)
  .subscribe(res => {
    this.customers = res;
  },
   err => console.log(err)
 );
}

back() {
  this._router.navigate(['/admin'], { skipLocationChange: true });
}
generatepwd() {
  var randomstring = require("randomstring");
  this.login.email = this.anagrafica.posta_elettronica;
  let password = randomstring.generate();
  this.login.id_utente = password
  this.login.password =  this.EncrDecr.set(password.toString(), 'accamieasyjob');
  this._auth.resetpwd(this.login).subscribe(
    res => {
      if (res.status == 'SUCCESS') {
        this._service.success('Success', 'An email was sent with new password', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
    },
    err => console.log(err)
  );
}



}

