import { Component, OnInit } from '@angular/core';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Router } from '@angular/router';
import { Client } from '../shared/client.model';
import { AuthService } from '../auth.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-client-admin',
  templateUrl: './client-admin.component.html',
  styleUrls: ['./client-admin.component.scss']
})
export class ClientAdminComponent implements OnInit {
  dataSource = [];
  dataSource1 = [];
  tableColumns: string[] = ['name', 'full_name', 'id'];
  tableColumns1: string[] = [];
  setting: Client = new Client();
  update_web: any ="false";
  name_web:any;
  fullname_web:any;
  update_settingweb: any = 'false';
  summary_report:any
  availability: any;
  send_email: any;
  send_certificate: any;
  buz_trip: any;
  id_customer: any;
  insert: string = 'false';
  namesetting: any;
  training: any;
  Address_Book: any;
  constructor(private _service: NotificationsService, public _auth: AuthService, public _storage: MyLocalStorageService, private _router: Router) { }

  ngOnInit() {
    this.loadclient();
  }
  loadcustsettings(id){
    this.id_customer = id;
    let idcust = {"idcust" : id}
    this._auth.loadcustsettings(idcust)
    .subscribe(
      res => {
        if (res.risposta == "error") {
          this.insert = 'true'
          this.summary_report = 0
          this.availability = 0
          this.send_email = 0
          this.send_certificate = 0
          this.buz_trip = 0
          this.training = 0
          this.Address_Book = 0
          this._service.error('Error',
          'No settings found for this company', {
          position: ['bottom', 'center'],
          timeOut: 5000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        } else {
          this.summary_report = res[0].summary_report
          this.availability = res[0].availability
          this.send_email = res[0].send_email
          this.send_certificate = res[0].send_certificate
          this.buz_trip = res[0].buz_trip
          this.training = res[0].training
          this.Address_Book = res[0].Address_Book
        }
      },
      err => console.log(err)
    );
  }
  savesettings(){
    this.summary_report = this.summary_report.toString().split(true).join('1')
    this.summary_report = this.summary_report.toString().split(false).join('0')
    this.availability = this.availability.toString().split(true).join('1')
    this.availability = this.availability.toString().split(false).join('0')
    this.send_email = this.send_email.toString().split(true).join('1')
    this.send_email = this.send_email.toString().split(false).join('0')
    this.send_certificate = this.send_certificate.toString().split(true).join('1')
    this.send_certificate = this.send_certificate.toString().split(false).join('0')
    this.buz_trip = this.buz_trip.toString().split(true).join('1')
    this.buz_trip = this.buz_trip.toString().split(false).join('0')
    this.training = this.training.toString().split(true).join('1')
    this.training = this.training.toString().split(false).join('0')
    this.Address_Book = this.Address_Book.toString().split(true).join('1')
    this.Address_Book = this.Address_Book.toString().split(false).join('0')
    let settingclient ={
      "summary_report" : this.summary_report,
      "availability" : this.availability,
      "send_email" : this.send_email,
      "send_certificate" : this.send_certificate,
      "buz_trip" : this.buz_trip,
      "id_customer" : this.id_customer,
      "training" : this.training,
      "Address_Book" : this.Address_Book
    }
    if (this.insert === 'true') {
      this._auth.insertcustomersettings(settingclient).subscribe(
        res => {
          if (res.risposta == "Errore") {
            this._service.error('Error',
            'Ops something gone wrong', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
            this.summary_report = 0
            this.availability = 0
            this.send_email = 0
            this.send_certificate = 0
            this.buz_trip = 0
            this.training = 0
            this.Address_Book = 0
          } else {
            this._service.success('Success',
            'Settings Inserted', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
            this.summary_report = 0
            this.availability = 0
            this.send_email = 0
            this.send_certificate = 0
            this.buz_trip = 0
            this.training = 0
            this.Address_Book = 0
            this.update_settingweb = false
            this.insert='false'
          }
        },
        err => console.log(err)
      );
    } else {
    this._auth.savecustomersettings(settingclient)
    .subscribe(
      res => {
        if (res.risposta == "Errore") {
          this._service.error('Error',
          'Ops something gone wrong', {
            position: ['bottom', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this.summary_report = 0
          this.availability = 0
          this.send_email = 0
          this.send_certificate = 0
          this.buz_trip = 0
          this.training = 0
          this.Address_Book = 0
        } else {
          this._service.success('Success',
          'Settings Saved', {
            position: ['bottom', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this.summary_report = 0
          this.availability = 0
          this.send_email = 0
          this.send_certificate = 0
          this.buz_trip = 0
          this.training = 0
          this.Address_Book = 0
          this.update_settingweb = false
        }
      },
      err => console.log(err)
    );
  }
  }
  loadclient(){
    this._auth.loadclient_admin(this.setting)
    .subscribe(
      res => {
        if (res.risposta == "Errore") {
          this.dataSource = [];
        } else {
          this.dataSource = res;
        }
      },
      err => console.log(err)
    );
  }
  settings(id, name){
    this.namesetting = name
    this.update_settingweb = 'true'
    this.loadcustsettings(id)
  }
  back(){
    this._router.navigate(['/admin'], { skipLocationChange: true });
  }
  delete(id_client, status){
    this.setting.id=id_client;
    if (status == 'active') {
      this._auth.disable_client(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
            'Client disabled', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          } else {
            this._service.error('Error',
            'Ops something gone wrong', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
          this.loadclient();
        },
        err => console.log(err)
      );

    } else {
      this._auth.enable_client(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
            'Client reactivated', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          } else {
            this._service.error('Error',
            'Ops something gone wrong', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
          this.loadclient();
        },
        err => console.log(err)
      );
    }
      }
  update(name, fullname, id){
    this.setting.id = id;
    this.name_web=name;
    this.fullname_web=fullname;
    this.update_web="true";
    this.update_settingweb = 'false'
}
save(){
  this.setting.name = this.name_web;
  this.setting.full_name = this.fullname_web;
  this._auth.update_client(this.setting)
    .subscribe(res => {
      if (res.risposta == "Success") {
        this._service.success('Success',
        'Client Updated', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this.update_web="false";
        this.loadclient();
      } else {
        this._service.error('Error',
        'Client not Updated', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
    },
     err => console.log(err)
   );
}
newclient(){
  this.name_web="";
  this.fullname_web="";
  this.update_web="new";
}
savenew(){
  this.setting.name = this.name_web;
  this.setting.full_name = this.fullname_web;
  this._auth.new_client(this.setting)
    .subscribe(res => {
      if (res.risposta == "Success") {
        this._service.success('Success',
        'Client Inserted', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this.update_web="false";
        this.loadclient();
      } else {
        this._service.error('Error',
        'Client not Inserted', {
          position: ['bottom', 'center'],
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
    },
     err => console.log(err)
   );
}
}
