import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { Email } from '../shared/email.model';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { AuthService } from '../auth.service';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';

// tslint:disable:class-name
export interface ora {
  value: string;
  viewValue: string;
}
export interface progetto {
  // tslint:disable:indent
  value: any;
  viewValue: string;
}

@Component({
  selector: 'app-straordinario',
  templateUrl: './straordinario.component.html',
  styleUrls: ['./straordinario.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'ja-JP' }]
})
export class StraordinarioComponent implements OnInit {
  progetti: progetto[];
  passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl1 = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl3 = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl2 = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl4 = new FormControl('', [
    Validators.required
  ]);
  // tslint:disable:variable-name
  variabile_data: any;
  variabile_ora: any;
  company: any;
  tiporichiesta: any;
  variabile_orario_uscita: any;
  variabile_orario_rientro: any;
  variabile_minuti: any;
  attivita: any;
  ts_project: any;
  concordato: any;
  setting: Email = new Email();
  ore: ora[] = [];
  pipe = new DatePipe('en-US');
  temp = Date.now();
  test = this.pipe.transform(this.temp, 'dd');
  // tslint:disable-next-line:max-line-length
  constructor(private _adapter: DateAdapter<any>, public _auth: AuthService, public _storage: MyLocalStorageService, private _router: Router, private _service: NotificationsService) { }

  ngOnInit() {
    this.loadcombohours();
    if (localStorage.getItem('customer') === '2') {
      this.loadproject_employee_interni();
    } else {
      this.loadproject_employee();
    }

    this.tiporichiesta = localStorage.getItem('tiporichiesta').replace(/_/g, ' ');
    this.company = localStorage.getItem('company');
    this._adapter.setLocale('it');
  }
  loadproject_employee() {
    this.setting.id_utente = localStorage.getItem('id');
    this._auth.loadprojectemployee_straord(this.setting).subscribe(
      res => {
        if (res.risposta === 'NoValue') {
          console.log(res.risposta);
          this._service.error('Error',
            'Please, add the hours of work for a total of 8h!!!', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this._router.navigate(['/settings'], { skipLocationChange: true });
          this.progetti = [];
        } else {
          this.progetti = res;
        }
      },
      err => console.log(err)
    );
  }
  loadproject_employee_interni() {
    this.setting.id_utente = localStorage.getItem('id');
    this._auth.loadprojectemployee_straordinterni(this.setting).subscribe(
      res => {
        if (res.risposta === 'NoValue') {
          console.log(res.risposta);
          this._service.error('Error',
            'Please, add the hours of work for a total of 8h!!!', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this._router.navigate(['/settings'], { skipLocationChange: true });
          this.progetti = [];
        } else {
          this.progetti = res;
        }
      },
      err => console.log(err)
    );
  }
  loadcombohours() {
    this._auth.loadcombohours(this.setting)
      .subscribe(res => {
        this.ore = res;
      },
        err => console.log(err)
      );
  }
  preview() {
    const checkdate = this.pipe.transform(this.variabile_data, 'dd');
    const checkmonth = this.pipe.transform(this.variabile_data, 'MM');
    const tempday = this.pipe.transform(Date.now(), 'dd');
    const tempmonth = this.pipe.transform(Date.now(), 'MM');
    if (checkdate > tempday) {
      if (tempmonth === checkmonth) {
        this._service.error('Error', 'The date cannot be greater than today', {
          position: ['bottom', 'center'],
          timeOut: 5000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        return;
      } else {
        this._storage.setItem('attivita', this.attivita);
        this._storage.setItem('timesheet', this.ts_project);
        this._storage.setItem('ora', this.variabile_ora);
        this._storage.setItem('data', this.variabile_data);
        this._storage.setItem('orario', null);
        this._storage.setItem('orario_ingresso', null);
        this._router.navigate(['/preview'], { skipLocationChange: true });
      }
    } else {
      this._storage.setItem('attivita', this.attivita);
      this._storage.setItem('timesheet', this.ts_project);
      this._storage.setItem('ora', this.variabile_ora);
      this._storage.setItem('data', this.variabile_data);
      this._storage.setItem('orario', null);
      this._storage.setItem('orario_ingresso', null);
      this._router.navigate(['/preview'], { skipLocationChange: true });
    }
  }
}
