import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Setting } from '../shared/setting.model';
import { AuthService } from '../auth.service';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { Email } from '../shared/email.model';
// tslint:disable: indent
export interface PeriodicElement {
	id: number;
	project: string;
	hours: string;
	actions: string;
}
// tslint:disable:class-name
export interface progetto {
	value: any;
	viewValue: string;
}
export interface ora {
	value: any;
	viewValue: string;
}

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
})
@Injectable()
export class SettingsComponent implements OnInit {
	oredisp: any;
	progetti: progetto[];
	// tslint:disable:max-line-length
	ore: ora[] = [{ value: '8', viewValue: '8' }, { value: '6', viewValue: '6' }, { value: '5', viewValue: '5' }, { value: '4', viewValue: '4' }, { value: '3', viewValue: '3' }, { value: '2', viewValue: '2' }];
	@ViewChild('table', { static: true })
	table;
	passwordFormControl = new FormControl('', [Validators.required, Validators.email]);
	emailFormControl = new FormControl('', [Validators.required, Validators.email]);
	passwordFormControl1 = new FormControl('', [Validators.required]);
	passwordFormControl2 = new FormControl('', [Validators.required]);
	tableColumns: string[] = ['id', 'destinatari', 'actions'];
	tableColumns1: string[] = ['id', 'hours', 'project', 'actions'];
	tableColumns2: string[] = ['email'];
	tableColumns3: string[] = ['email'];
	setting: Setting = new Setting();
	dataSource = [];
	// tslint:disable:variable-name
	temp_datasource = [];
	dataSource1 = new MatTableDataSource<PeriodicElement>();
	dataSource2 = new MatTableDataSource<PeriodicElement>();
	dataSource3 = new MatTableDataSource<PeriodicElement>();
	myitems: any[] = [];
	myItems: any[] = [];
	value1: any;
	company: any;
	temp: [];
	temp_exist: any;
	response: any[] = [];
	indirizziweb: string;
	id: number;
	id_temp: any;
	index: number;
	ar: string;
	myItemslenght: number;
	emailwba = '';
	email: Email = new Email();
	companymail: string;
	company_name: string;
	customer_name: string;
  visibility: boolean = true;
  test :number;
	constructor(
		public _auth: AuthService,
		private http: HttpClient,
		private router: Router,
		public _storage: MyLocalStorageService,
		private _service: NotificationsService
	) {}

	ngOnInit() {
		localStorage.setItem('navigation', 'settings');
    this.test = 1 ;
		this.company = localStorage.getItem('company');
		this.company_name = localStorage.getItem('company_name');
		this.customer_name = localStorage.getItem('customer_name');
		this.loadproject();
		this.loadsettings();
		this.loadproject_employee();
		this.loadclientemail();
		this.loadcustomeremail();

	}
	async loadcustomeremail() {
		const data = {id: this.company};
		const res = await this._auth.loadcustomeremail(data).toPromise();
		this.dataSource3 = res;
	}
	async loadclientemail() {
		const data = {cust_id: localStorage.getItem('customer')};
		let desttemp = '';
		const res = await this._auth.loadclientemail(data).toPromise();
    if (res.risposta === 'error') {
      this.visibility = false;
    } else {
		this.dataSource2 = res;
		res.forEach(element => {
			this.emailwba = this.emailwba + element.email + ';';
			desttemp = desttemp + element.email + ';';
		});
		this.companymail = desttemp
  }
	}
	loadproject() {
		// tslint:disable-next-line:triple-equals
		if (localStorage.getItem('customer') == '2') {
        this._auth.loadprojectinterni(this.setting).subscribe(
        res => {
          this.progetti = res;
          console.log(this.progetti);
        },
        err => console.log(err)
      );
		} else {
		this._auth.loadproject1(this.setting).subscribe(
			res => {
				this.progetti = res;
				// console.log(this.progetti);
			},
			err => console.log(err)
		);
	}
	}

	loadproject_employee() {
		this._auth.loadprojectemployee(this.setting).subscribe(
			res => {

				// tslint:disable: triple-equals
				if (res.risposta == 'NoValue') {
					this.dataSource1 = new MatTableDataSource<PeriodicElement>();
          this.dataSource1.sort
					this.myItemslenght = 0;
				} else {
					this.myItems = res;
					this.dataSource1 = res;

				}
			},
			err => console.log(err)
		);
	}
	loadprojectemployeeinterni() {
		this._auth.loadprojectemployeeinterni(this.setting).subscribe(
			res => {

				if (res.risposta == 'NoValue') {
					this.dataSource1 = new MatTableDataSource<PeriodicElement>();
					this.myItemslenght = 0;
				} else {
					this.myItems = res;
					console.log(this.myItems.length);
					this.dataSource1 = res;

				}
			},
			err => console.log(err)
		);
	}
	async savesettings(data) {
		this._auth.savesetting(data).subscribe(
			res => {
				if (res.risposta == 'Update') {
          this.loadsettings();
					this._service.success('Success', 'Update Success', {
						position: ['bottom', 'center'],
						timeOut: 1000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
    );

	}
	loadsettings() {
		this.setting.id_utente = localStorage.getItem('id');
		this._auth.loadsetting(this.setting).subscribe(
			res => {
				this.temp_exist = res.risposta;
				if (this.temp_exist == 'NoValue') {
					this.dataSource = [];
				} else {
          if (this.test===1){
            this.test = this.test + 1
          } else {
            if (this.dataSource.length == res.length ) {
              this.dataSource = res;
              this.loadsettings()
            }
          }

          this.dataSource = res;
					localStorage.setItem('settings', JSON.stringify(res));
					this._storage.setItem('id_setting',
						// tslint:disable:only-arrow-functions
						res.map(function(a) {
							// tslint:disable:no-string-literal
							return a.id;
						})
					);
					this._storage.setItem(
						'destinatari',
						res.map(function(a) {
							return a.destinatari;
						})
          );
					this.dataSource = res;
				}
			},
			err => console.log(err)
    );
	}
 addElement() {
		if (this.value1 == 'personale@emminformatica.it') {
			this._service.error('Error', 'This email \"' + this.value1 + '\" is automatically inserted', {
				position: ['bottom', 'center'],
				timeOut: 5000,
				showProgressBar: true,
				pauseOnHover: false,
				clickToClose: true,
			});
			return;
		} else if (this.value1 == 'a.melorio@emminformatica.it') {
			this._service.error('Error', 'This email \"' + this.value1 + '\" is automatically inserted', {
				position: ['bottom', 'center'],
				timeOut: 5000,
				showProgressBar: true,
				pauseOnHover: false,
				clickToClose: true,
			});
			return;
		} else if (this.value1 == 'f.miele@emminformatica.it') {
			this._service.error('Error', 'This email \"' + this.value1 + '\" is automatically inserted', {
				position: ['bottom', 'center'],
				timeOut: 5000,
				showProgressBar: true,
				pauseOnHover: false,
				clickToClose: true,
			});
			return;
		} else if (this.value1 == 'TSF-NAdmin@alliance-healthcare.net') {
			this._service.error('Error', 'This email \"' + this.value1 + '\" is not more valid', {
				position: ['bottom', 'center'],
				timeOut: 5000,
				showProgressBar: true,
				pauseOnHover: false,
				clickToClose: true,
			});
			return;
		} else if (this.value1 == 'PSE-NAdmin@alliance-healthcare.net') {
			this._service.error('Error', 'This email \"' + this.value1 + '\" is automatically inserted', {
				position: ['bottom', 'center'],
				timeOut: 5000,
				showProgressBar: true,
				pauseOnHover: false,
				clickToClose: true,
			});
			return;
		} else if (this.value1 == null) {
			this._service.error('Error', 'Please, add at least one project mail recipient!!!', {
				position: ['bottom', 'center'],
				timeOut: 5000,
				showProgressBar: true,
				pauseOnHover: false,
				clickToClose: true,
			});
			return;
		} else {
			this.setting.id_utente = localStorage.getItem('id');
			this.setting.destinatari = this.value1.trim();
			this.setting.destinatari = this.setting.destinatari.replace(/;/g, '');
		 this.savesettings(this.setting);
   this.loadsettings();
   this.router.navigate(['/settings']);
   this.value1 = '';
		}

	}
deletedest(id_rep) {
		this.setting.id_utente = localStorage.getItem('id');
		this.setting.id = id_rep;
		this._auth.deletedest(this.setting).subscribe(res => {
			if (res.risposta == 'Success') {
				this._service.success('Success', 'Record Deleted', {
					position: ['bottom', 'center'],
					timeOut: 1000,
					showProgressBar: true,
					pauseOnHover: false,
					clickToClose: true,
				});
				this.loadsettings();
				this.router.navigate(['/settings']);
			} else {
				this._service.error('Errore', 'Record Deleted', {
					position: ['bottom', 'center'],
					timeOut: 3000,
					showProgressBar: true,
					pauseOnHover: false,
					clickToClose: true,
				});
			}
		});
	}

add() {
		this.precheckore();
	}
precheckore() {
		for (let j = 0; j < this.myItemslenght; j++) {
			if (this.dataSource1[j].project == this.setting.id_timesheet) {
				this.ar = 'stop';
			}
		}
		if (this.ar == 'stop') {
			this._service.error('Errore', 'Project already inserted', {
				position: ['bottom', 'center'],
				timeOut: 3000,
				showProgressBar: true,
				pauseOnHover: false,
				clickToClose: true,
			});
			this.ar = '';
		} else {
			this.checkore();
			this.ar = '';
		}
	}
checkore() {
		this._auth.check_ore(this.setting).subscribe(
			res => {
				if (res.risposta == 'Success') {
					this.oredisp = res.ore_disponibili;

					if (this.setting.ore_timesheet <= res.ore_disponibili) {
            if (localStorage.getItem('customer') == '2') {
              this.saveprojectemployeeinterni();
            } else {
						this.saveprojectemployee(); }
					} else {
						this._service.error('Error', 'Hours available is:' + this.oredisp, {
							position: ['bottom', 'center'],
							timeOut: 3000,
							showProgressBar: true,
							pauseOnHover: false,
							clickToClose: true,
						});
					}
				} else {
					this._service.error('Error', 'You have inserted more than hours available', {
						position: ['bottom', 'center'],
						timeOut: 3000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);
	}
saveprojectemployee() {
		this._auth.saveprojectemployee(this.setting).subscribe(
			res => {
				if (res.risposta == 'Success') {
					this._service.success('Success', 'Record inserted', {
						position: ['bottom', 'center'],
						timeOut: 3000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
					this.loadproject_employee();
				} else {
					this._service.error('Errore', 'Record not inserted', {
						position: ['bottom', 'center'],
						timeOut: 1000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);
  }
saveprojectemployeeinterni() {
		this._auth.saveprojectemployeeinterni(this.setting).subscribe(
			res => {
				if (res.risposta == 'Success') {
					this._service.success('Success', 'Record inserted', {
						position: ['bottom', 'center'],
						timeOut: 3000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
					this.loadprojectemployeeinterni();
				} else {
					this._service.error('Errore', 'Record not inserted', {
						position: ['bottom', 'center'],
						timeOut: 1000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);
	}
deleteproject(id) {
		this.setting.id = id;
		this._auth.deleteprojectts(this.setting).subscribe(
			res => {
				if (res.risposta == 'Success') {
					this._service.success('Success', 'Record deleted', {
						position: ['bottom', 'center'],
						timeOut: 3000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
					if (localStorage.getItem('customer') == '2') {
						this.loadprojectemployeeinterni();
					} else {
						this.loadproject_employee();
					}
				} else {
					this._service.error('Errore', 'Record not deleted', {
						position: ['bottom', 'center'],
						timeOut: 3000,
						showProgressBar: true,
						pauseOnHover: false,
						clickToClose: true,
					});
				}
			},
			err => console.log(err)
		);
	}
}
