import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../auth.service';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';

export interface PeriodicElement {
  value: number;
  viewValue: string;
}
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  pageNumber: number = 1;
  ELEMENT_DATA: PeriodicElement[] = [];
  isLoading = true;
  VOForm: FormGroup;
  isEditableNew: boolean = true;
  displayedColumns: string[] = ['value', 'viewValue', 'action'];
  dataSource = new MatTableDataSource<any>();
  roles: any[];
  updatevalue: any;
  constructor(private _formBuilder: FormBuilder, private fb: FormBuilder, private _service: NotificationsService, public _auth: AuthService, public _storage: MyLocalStorageService, private _router: Router) { }

  ngOnInit(): void {
    this.loadroles();
    this.VOForm = this._formBuilder.group({
      VORows: this._formBuilder.array([])
    });
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  loadroles(){
    this._auth.loadroles()
  .subscribe(res => {
    if(res.length > 0) {
    this.roles = res;
    this.ELEMENT_DATA = res
    this.test(this.ELEMENT_DATA)
  } else {
    this.roles =[]
    this.ELEMENT_DATA = []
    this.test(this.ELEMENT_DATA)
  }
  },
   err => console.log(err)
 );
  }
  test(ELEMENT_DATA){
    this.VOForm = this.fb.group({
      VORows: this.fb.array(ELEMENT_DATA.map(val => this.fb.group({
        value: new FormControl(val.value),
        viewValue: new FormControl(val.viewValue),
        action: new FormControl('existingRecord'),
        isEditable: new FormControl(true),
        isNewRow: new FormControl(false),
      })
      )) //end of fb array
    }); // end of form group cretation
    this.dataSource = new MatTableDataSource((this.VOForm.get('VORows') as FormArray).controls);
    this.dataSource.paginator = this.paginator;
    this.isLoading = false;
    const filterPredicate = this.dataSource.filterPredicate;
      this.dataSource.filterPredicate = (data: AbstractControl, filter) => {
        return filterPredicate.call(this.dataSource, data.value, filter);
      }
  }
  goToPage() {
    this.paginator.pageIndex = this.pageNumber - 1;
    this.paginator.page.next({
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length
    });
  }
  applyFilter(event: Event) {
    //  debugger;
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  AddNewRow() {
    const control = this.VOForm.get('VORows') as FormArray;
    control.insert(0,this.initiateVOForm());
    this.dataSource = new MatTableDataSource(control.controls)
  }
  back(){
    this._router.navigate(['/admin'], { skipLocationChange: true });
  }
  initiateVOForm(): FormGroup {
    return this.fb.group({

      value: new FormControl(1),
                viewValue: new FormControl(''),
                action: new FormControl('newRecord'),
                isEditable: new FormControl(false),
                isNewRow: new FormControl(true),
    });
  }

  SaveVO(element, VOFormElement, i) {

    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
    if (element.value.isNewRow ===false){
      VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
      this.updatevalue = {value: element.value.value, viewValue: element.value.viewValue}
      this.storeroles(this.updatevalue)
    } else  if (element.value.isNewRow ===true){
      VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
      this.updatevalue = {viewValue: element.value.viewValue}
      this._auth.newroles( this.updatevalue)
      .subscribe(res => {
        if (res.risposta == 'Success') {
          this._service.success('Success',
          'Record inserito', {
            position: ['bottom', 'center'],
            timeOut: 2000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this._router.navigate(['/admin'],  { skipLocationChange: true });
        } else {
          this._service.error('Error',
          'Record non inserito', {
            position: ['bottom', 'center'],
            timeOut: 2000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
        }
      },
       err => console.log(err)
     );
    }

  }
  CancelSVO(VOFormElement, i) {
    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
  }
  EditSVO(VOFormElement, i) {
    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(false);
  }
  delrole(element){
    if (element.value.isNewRow ===false){
      this.updatevalue = {value: element.value.value}
      this._auth.deleteroles(this.updatevalue)
      .subscribe(res => {
        if (res.risposta == 'Success') {
          this._service.success('Success',
          'Record cancellato', {
            position: ['bottom', 'center'],
            timeOut: 2000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this._router.navigate(['/admin'],  { skipLocationChange: true });
        } else {
          this._service.error('Error',
          'Record non cancellato', {
            position: ['bottom', 'center'],
            timeOut: 2000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
        }
      },
       err => console.log(err)
     );
    }
  }
  storeroles(updatevalue1){
    this._auth.updatecontratto(updatevalue1)
    .subscribe(res => {
      if (res.risposta == 'Success') {
        this._service.success('Success',
        'Record aggiornato', {
          position: ['bottom', 'center'],
          timeOut: 2000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
        this._router.navigate(['/admin'],  { skipLocationChange: true });
      } else {
        this._service.error('Error',
        'Record non aggiornato', {
          position: ['bottom', 'center'],
          timeOut: 2000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: true,
        });
      }
    },
     err => console.log(err)
   );
  }
}
