import { Component, OnInit } from '@angular/core';
import { Certificato } from '../shared/certificato.model';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { DialogComponent } from './dialog/dialog.component';
import { UploadService } from './upload.service';
import { MatDialog } from '@angular/material/dialog';
import { Client } from '../shared/client.model';

export interface DialogData {
	password: any;
}
export interface richiesta {
	value: string;
	viewValue: string;
}

@Component({
	selector: 'app-certificato',
	templateUrl: './certificato.component.html',
	styleUrls: ['./certificato.component.scss'],
})
export class CertificatoComponent implements OnInit {
  tiporichiesta: any;
  client: Client = new Client();
	richiesta_web: any;
  request:any;
  certificato_web:any;
	richieste: richiesta[] = [
		/*{ value: 'Donazione_Sangue', viewValue: 'Donazione Sangue' },
		{ value: 'Daily_Hospital', viewValue: 'Daily Hospital' },
		{ value: 'Ferie_Matrimoniali', viewValue: 'Ferie Matrimoniali' },
		{ value: 'Lutto', viewValue: 'Lutto' },
		{ value: 'Malattia', viewValue: 'Malattia' },
		{ value: 'Nascita_Figlio', viewValue: 'Nascita Figlio' },
		{ value: 'Maternità', viewValue: 'Maternità' },
		{ value: 'Permesso_Parentale', viewValue: 'Permesso Parentale' },
		{ value: 'Permesso_Studio', viewValue: 'Permesso Studio' },
		{ value: 'Permesso_104', viewValue: 'Permesso 104' },
		{ value: 'Daily_Hospital', viewValue: 'Daily Hospital' },*/
	];
	company: any;
	password: any;
	firstFormGroup: FormGroup;

	emailFormControl = new FormControl('', [Validators.required]);
	emailFormControl1 = new FormControl('', [Validators.required]);

	certificato: Certificato = new Certificato();
	bsInlineValue = new Date();
	constructor(public _auth: AuthService, public dialog: MatDialog, public uploadService: UploadService, private http: HttpClient, public _storage: MyLocalStorageService) {}
	ngOnInit() {
    this.company = localStorage.getItem('company');
    this.loadcompanyemail();
	}
	onChange(event) {
		if (this.richiesta_web == 'Malattia') {
			this.request = 'certificato';
		} else {
			this.request = '';
		}

		console.log(this.richiesta_web);
  }
  public openUploadDialog() {
    let dialogRef = this.dialog.open(DialogComponent, { width: '50%', height: '50%' });
  }
  loadcompanyemail() {
    this.client.id = this._storage.getItem('company');
		  this._auth.loadcompanyemail(this.client).subscribe(
			res => {
        console.log(res);
        this._storage.setItem('indirizzocompany', res[0].indirizzo);
        this._storage.setItem('companyfullname', res[0].full_name);
			},
			err => console.log(err)
		);
  }
}
