import { Component, OnInit, ViewChild } from '@angular/core';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Summary } from '../shared/summary.model';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DialogComponent3 } from './dialog/dialog.component';
import { Setting } from '../shared/setting.model';
import { Email } from '../shared/email.model';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
const dateFormat = require('dateformat');
// tslint:disable: class-name
export interface anno { value: string; viewValue: string; }
export interface mese { value: string; viewValue: string; }
export interface specifica { value: string; viewValue: string; }
@Component({

	// tslint:disable: indent
	selector: 'app-summary',
	templateUrl: './summary.component.html',
	styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
	// tslint:disable: variable-name
  email: Email = new Email();
  setting: Setting = new Setting();
	mese_web: any;
	anno_web: any;
	pippo:any;
	fileUrl: any;
	sortdata: any;
	company: any;
	specificaselezionata: any = 'Diurno';
	specifiche: specifica[] = [
		{ value: 'Diurno', viewValue: 'Diurno' },
		{ value: 'Notturno', viewValue: 'Notturno'},
	];
	anni: anno[] = [
		{ value: '2023', viewValue: '2023' },
		{ value: '2024', viewValue: '2024' },
    { value: '2025', viewValue: '2025' },
		{ value: '2026', viewValue: '2026' },
		{ value: '2027', viewValue: '2027' },
		{ value: '2028', viewValue: '2028' },
	];
	mesi: mese[] = [
		{ value: '01', viewValue: 'Gennaio' },
		{ value: '02', viewValue: 'Febbraio' },
		{ value: '03', viewValue: 'Marzo' },
		{ value: '04', viewValue: 'Aprile' },
		{ value: '05', viewValue: 'Maggio' },
		{ value: '06', viewValue: 'Giugno' },
		{ value: '07', viewValue: 'Luglio' },
		{ value: '08', viewValue: 'Agosto' },
		{ value: '09', viewValue: 'Settembre' },
		{ value: '10', viewValue: 'Ottobre' },
		{ value: '11', viewValue: 'Novembre' },
		{ value: '12', viewValue: 'Dicembre' },
	];
	sort: MatSort;
	summary: Summary = new Summary();
  nuovo: boolean;
  pageNumber: number = 1;
  firma: any;
  VOForm: FormGroup;
  isEditableNew: boolean = true;
  isLoading = true;
  updatevalue: any;
  ELEMENT_DATA: Summary[] = [];
  summary_tab: any [];
	displayedColumns: string[] = ['id', 'id_utente', 'data', 'ore', 'specifica', 'finish_time', 'start_time', 'action'];
	dataSource = new MatTableDataSource<any>();
  temp5: string;
  oggetto_email: string;
  dataemail: any;
  idemail: any;
  corpo_email: string;
  destxml: string;
  lenghtfordestinatari: any;
  destinataritable: any;
  resource_email: string;
  resource_name: string;
	constructor(
		private httpClient: HttpClient,
		public _auth: AuthService,
		public _storage: MyLocalStorageService,
		private datePipe: DatePipe,
		public dialog: MatDialog,
    private _service: NotificationsService,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private fb: FormBuilder,
	) { }

	ngOnInit() {
		this.company = localStorage.getItem('company');
		// tslint:disable: no-shadowed-variable
		const mese = this.datePipe.transform(new Date(), 'MM');
		const anno = this.datePipe.transform(new Date(), 'yyyy');
		this.mese_web = mese;
		this.anno_web = anno;
    this.nuovo = false;
		this.loadsummary();
    this.loaddestinatari();
    this.VOForm = this._formBuilder.group({
      VORows: this._formBuilder.array([])
    });
    if (this.ELEMENT_DATA.length === 0){


    } else {
      this.test(this.ELEMENT_DATA)
          }
  }

  test(ELEMENT_DATA){
    this.VOForm = this.fb.group({
      VORows: this.fb.array(ELEMENT_DATA.map(val => this.fb.group({
        id: new FormControl(val.id),
        id_utente: new FormControl(val.id_utente),
        data: new FormControl( dateFormat(val.data, 'dd-mm-yy')),
       // tipo_comunicazione: new FormControl(val.tipo_comunicazione),
        ore: new FormControl(val.ore),
        specifica: new FormControl(val.specifica),
      //  ore_di_specifica: new FormControl(val.ore_di_specifica),
        start_time: new FormControl(val.start_time),
        finish_time: new FormControl(val.finish_time),
        action: new FormControl('existingRecord'),
        isEditable: new FormControl(true),
        isNewRow: new FormControl(false),
      })
      )) //end of fb array
    }); // end of form group cretation
    this.dataSource = new MatTableDataSource((this.VOForm.get('VORows') as FormArray).controls);
    this.dataSource.paginator = this.paginator;
    this.isLoading = false;
    const filterPredicate = this.dataSource.filterPredicate;
      this.dataSource.filterPredicate = (data: AbstractControl, filter) => {
        return filterPredicate.call(this.dataSource, data.value, filter);
      }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

	loadsummary() {
		this.summary.id_utente = localStorage.getItem('id');
		this.summary.mese = this.mese_web;
		this.summary.anno = this.anno_web;
    localStorage.setItem('summary', JSON.stringify(this.summary))
		this._auth.summary_report(this.summary)
    .subscribe(res => {
      if(res.length > 0) {
      this.summary_tab = res;
      this.ELEMENT_DATA = res
      this.test(this.ELEMENT_DATA)
    } else {
      this.summary_tab =[]
      this.ELEMENT_DATA = []
      this.test(this.ELEMENT_DATA)
    }
    },
			err => console.
      log(err)
		);
	}
  applyFilter(event: Event) {
    //  debugger;
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  SaveVO(element, VOFormElement, i) {
    VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
    if (element.value.isNewRow ===false){
      VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
      this.updatevalue = {value: element.value.value, viewValue: element.value.viewValue}
      this.storesummary(this.updatevalue)
    }
  }
  openDialog(element, VOFormElement ): void {
		const dialogRef = this.dialog.open(DialogComponent3, {
		  width: '250px',
		  data: {
			  	id: element.value.VORows[VOFormElement].id,
				  body: element,
          id_riga: VOFormElement
				}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.loadsummary();
		});
	  }
  EditSVO(element, VOFormElement ) {
    this.dataemail = element;
    this.idemail = VOFormElement;
   this.deletesummary(element.value.VORows[VOFormElement].id)
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginatorList = document.getElementsByClassName('mat-paginator-range-label');

   this.onPaginateChange(this.paginator, this.paginatorList);

   this.paginator.page.subscribe(() => { // this is page change event
     this.onPaginateChange(this.paginator, this.paginatorList);
   });
  }
  goToPage() {
    this.paginator.pageIndex = this.pageNumber - 1;
    this.paginator.page.next({
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length
    });
  }
//_---------------------------------------------
deletesummary (id){
  let body = {'idtodelete' : id}
  this._auth.deletesummary(body)
  .subscribe(res => {
    console.log(res)
    if (res.risposta == 'Success') {
      this._service.success('Success',
      'Record deleted and Email sent', {
        position: ['bottom', 'center'],
        timeOut: 4000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
      this.loadsummary();
      //this.sendcancelemail();
    } else {
      this._service.error('Error',
      'Record non delete contact administator', {
        position: ['bottom', 'center'],
        timeOut: 4000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
    }
  },
   err => console.log(err)
 );
}
loaddestinatari() {
  this.email.id_utente = localStorage.getItem('id');
  this.resource_email = localStorage.getItem('email');
  this._auth.loaddestinatari(this.email).subscribe(
    res => {
      this.destinataritable = res;
      this._storage.setItem('lunghezza', res.length);
      this.lenghtfordestinatari = res.length;
      let tempdestinatari =[];
      tempdestinatari = res;
      let i = 0
      tempdestinatari.forEach(element => {
        if (i== 0) {
          this.destxml = element.destinatari + ";";
        } else {
          this.destxml = this.destxml + element.destinatari + ";";
        }
        i = i + 1;
      });
      this._storage.setItem('desttable', JSON.stringify(res));
      this._storage.setItem('destxml', this.destxml);

    },
    err => console.log(err)
  );
}
/*async sendcancelemail(){
  this.email.destarray = this._storage.getItem('destxml');
  this.email.lunghezza = this._storage.getItem('lunghezza');
  this.email.email = this._storage.getItem('email')
  this.email.resource_name = this._storage.getItem('resourcename');
  this.email.nome =  this._storage.getItem('nome');
  this.email.cognome =  this._storage.getItem('cognome');
  this.email.resource_email = this._storage.getItem('resourceemail');
  this.firma =
  '<font face="Calibri" color="#002060">Regards,<br><b>' +
  this.email.nome +
  ' ' +
  this.email.cognome +
  '</b></font><br><font face="Calibri" color="#002060">' +
  this._storage.getItem('ruolo') +
  ', Pharmacy and Healthcare Product Engineering - Tecnology Solution </b></font><br><br><font face="Calibri" color="#002060"><b>Walgreens Boots Alliance</b> │ Via Vicinale S.Maria del Pianto - Centro Polifunzionale - Torre 5 - Piano 8, Napoli(Italy) 80143</font><br><font face="Calibri" color="#FFFFFF"><p>[]</p></font>';

  this.oggetto_email = 'Comunication regarding ' + this.dataemail.value.VORows[this.idemail].specifica + ' of ' + this.dataemail.value.VORows[this.idemail].data + " for a total of (" + this.dataemail.value.VORows[this.idemail].ore + ") CANCELLED";

  this.corpo_email = '<font face="Calibri"><p>Hi,</p><p>the present mail to inform you that on <b>' +
  this.dataemail.value.VORows[this.idemail].data +
    '</b> the previous comunication was cancelled <b><br><br>' +  this.firma ;

  this.email.corpo_email_send = this.corpo_email;
  this.email.subject_email_send = this.oggetto_email;
  this.temp5 = this._storage.getItem('customer_mail').substring(this._storage.getItem('customer_mail').lastIndexOf('\@') + 1);


  await this._auth.invioemail(this.email).subscribe(
    res => {
      if (res.status=== 'SUCCESS') {
        // tslint:disable-next-line:no-unused-expression
      } else {
        // tslint:disable:no-unused-expression
          this._service.error('Error', 'Contact Administrator', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
      }
    },
    err => console.log(err)
  );
} */
storesummary(updatevalue1){
      this._auth.updatesummary(updatevalue1)
      .subscribe(res => {
        if (res.risposta == 'Success') {
          this._service.success('Success',
          'Record aggiornato', {
            position: ['bottom', 'center'],
            timeOut: 2000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this.loadsummary();
        } else {
          this._service.error('Error',
          'Record non aggiornato', {
            position: ['bottom', 'center'],
            timeOut: 2000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
        }
      },
       err => console.log(err)
     );
    }
    CancelSVO(VOFormElement, i) {
      VOFormElement.get('VORows').at(i).get('isEditable').patchValue(true);
    }


  paginatorList: HTMLCollectionOf<Element>;
  idx: number;
  onPaginateChange(paginator: MatPaginator, list: HTMLCollectionOf<Element>) {
       setTimeout((idx) => {
           let from = (paginator.pageSize * paginator.pageIndex) + 1;

           let to = (paginator.length < paginator.pageSize * (paginator.pageIndex + 1))
               ? paginator.length
               : paginator.pageSize * (paginator.pageIndex + 1);

           let toFrom = (paginator.length == 0) ? 0 : `${from} - ${to}`;
           let pageNumber = (paginator.length == 0) ? `0 of 0` : `${paginator.pageIndex + 1} of ${paginator.getNumberOfPages()}`;
           let rows = `Page ${pageNumber} (${toFrom} of ${paginator.length})`;

           if (list.length >= 1)
               list[0].innerHTML = rows;

       }, 0, paginator.pageIndex);
  }


initiateVOForm(): FormGroup {
      return this.fb.group({
        value: new FormControl(1),
                  viewValue: new FormControl(''),
                  action: new FormControl('newRecord'),
                  isEditable: new FormControl(false),
                  isNewRow: new FormControl(true),
      });
    }

changemese() {
      this.summary.mese = this.mese_web;
      this.summary.anno = this.anno_web;
      this.loadsummary();
    }
changeanno() {
      this.summary.mese = this.mese_web;
      this.summary.anno = this.anno_web;
      this.loadsummary();
    }
}
