import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { Email } from '../shared/email.model';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { AuthService } from '../auth.service';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DatePipe } from '@angular/common';

// tslint:disable:class-name
export interface motivo {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-assenza-un-giorno',
  templateUrl: './assenza-un-giorno.component.html',
  styleUrls: ['./assenza-un-giorno.component.scss']
})
export class AssenzaUnGiornoComponent implements OnInit {
  company: any;
  passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl1 = new FormControl('', [
    Validators.required
  ]);
  // tslint:disable:variable-name
  variabile_data: any;
  variabile_ora: any;
  variabile_orario_uscita: any;
  variabile_motivo: any;
  variabile_orario_rientro: any;
  concordato: any;
  tiporichiesta: any;
  setting: Email = new Email();
  pipe = new DatePipe('en-US');
  motivi: motivo[] = [
    {value: 'illness', viewValue: 'Illness'},
    {value: 'familiar', viewValue: 'Familiar'},
    {value: 'personal', viewValue: 'Personal'}
  ];
  temp = Date.now();
  test = this.pipe.transform(this.temp, 'dd');
  // tslint:disable-next-line:max-line-length
  constructor(private _adapter: DateAdapter<any>, public _auth: AuthService, public _storage: MyLocalStorageService, private _router: Router,  private _service: NotificationsService) { }

ngOnInit() {
    this.tiporichiesta = localStorage.getItem('tiporichiesta').replace(/_/g, ' ');
    this.company = localStorage.getItem('company');
    this._adapter.setLocale('it');
  }
preview() {
  const checkdate = this.pipe.transform(this.variabile_data, 'dd');
  if (checkdate > this.test ) {
      this._service.error('Error', 'In Case of Planned Absence you must choose HOLIDAY', {
        position: ['bottom', 'center'],
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
      });
      this._storage.setItem('navigation', 'menu');
      this._router.navigate(['/menu'], { skipLocationChange: true });
      return;
    } else {
    this._storage.setItem('data', this.variabile_data);
    this._storage.setItem('motivo', this.variabile_motivo);
    this._storage.setItem('ora', '8');
    this._storage.setItem('orario', null);
    this._storage.setItem('orario_ingresso', null);
    this._router.navigate(['/preview'], { skipLocationChange: true });
  }
}
}

