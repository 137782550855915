import {Component,Input,Output,EventEmitter, OnInit} from '@angular/core';
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { Reperibilita } from '../shared/reperibilita.model';
import { Storerep } from '../shared/storerep.model';
import { AuthService } from '../auth.service';
import { NotificationsService } from 'angular2-notifications';
import { MyLocalStorageService } from '../localstorage/mylocalstorage.service';
import { Router } from '@angular/router';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-reperibilita',
  templateUrl: './reperibilita.component.html',
  styleUrls: ['./reperibilita.component.scss']
})
export class ReperibilitaComponent implements OnInit {
  reperibilita: Reperibilita = new Reperibilita();
  storerep: Storerep = new Storerep();
  response: any;
  verifica:any;
  new_value:any;
  display:any;
  temp_new_value:any;
  company: any;
  temp_giorno:any;
  check: any = [];
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  _datesSelected:NgbDateStruct[]=[]; 
  role:any;

  @Input()
  set datesSelected(value:NgbDateStruct[])  
  {
     this._datesSelected=value;
  }
  get datesSelected():NgbDateStruct[]
  {
    return this._datesSelected?this._datesSelected:[];
  }

  @Output() datesSelectedChange=new EventEmitter<NgbDateStruct[]>();
  constructor(private _router: Router, calendar: NgbCalendar, public _auth: AuthService, private _service: NotificationsService, public _storage: MyLocalStorageService) {}
  onDateSelection(event:any,date: NgbDateStruct) {

    event.target.parentElement.blur();  
    if (!this.fromDate && !this.toDate) {
      if (event.ctrlKey==true) 
        this.fromDate = date;
      else
        this.addDate(date);

      this.datesSelectedChange.emit(this.datesSelected);

    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
      this.addRangeDate(this.fromDate,this.toDate);
      this.fromDate=null;
      this.toDate=null;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }
  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.role = this._storage.getItem('profile');
  }
  addDate(date:NgbDateStruct)
  {
      let index=this.datesSelected.findIndex(f=>f.day==date.day && f.month==date.month && f.year==date.year);
      if (index>=0)       //If exist, remove the date
        this.datesSelected.splice(index,1);
      else   //a simple push
        this.datesSelected.push(date);
    }
    addRangeDate(fromDate:NgbDateStruct,toDate:NgbDateStruct)
    {
        //We get the getTime() of the dates from and to
        let from=new Date(fromDate.year+"-"+fromDate.month+"-"+fromDate.day).getTime();
        let to=new Date(toDate.year+"-"+toDate.month+"-"+toDate.day).getTime();
        for (let time=from;time<=to;time+=(24*60*60*1000)) //add one day
        {
            let date=new Date(time);
            //javascript getMonth give 0 to January, 1, to February...
            this.addDate({year:date.getFullYear(),month:date.getMonth()+1,day:date.getDate()});
        }   
        this.datesSelectedChange.emit(this.datesSelected);
    }
    //return true if is selected
    isDateSelected(date:NgbDateStruct)
    {
        return (this.datesSelected.findIndex(f=>f.day==date.day && f.month==date.month && f.year==date.year)>=0);
    }
  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);

  checkdata(){
    var dati_json = this.datesSelected;
      for (var j=0; j < dati_json.length; j++) {
       var new_value = dati_json[j].year +"-" + dati_json[j].month + "-" + dati_json[j].day;      
       this.reperibilita.giorno=dati_json[j].day;
       this.temp_giorno = this.reperibilita.giorno;
       this.reperibilita.id_utente = localStorage.getItem('id');
       this.reperibilita.data= new_value;
       this.temp_new_value = new_value;
       this.reperibilita.mese= dati_json[j].month;
       this.reperibilita.anno= dati_json[j].year;
       this._auth.check_lock(this.reperibilita)
       .subscribe(
         res => {
           if(res.risposta == "Success"){
             this.reperibilita.data = res.ris.data;
             this.reperibilita.id_utente = res.ris.id_utente;
             this.reperibilita.giorno = res.ris.giorno;
             this.reperibilita.mese = res.ris.mese;
             this.reperibilita.anno = res.ris.anno;
            this._auth.checkreperibilita(this.reperibilita)
            .subscribe(res => { 
              if (res.risposta == "Success") {
                this.check.push(res.risposta);
                if (this.check.length <= dati_json.length){
                 this.storerep.data= res.data;
                 this.storerep.id_utente= res.id_utente; 
                 this.storerep.giorno= res.giorno;
                 this.storerep.mese= res.mese;
                 this.storerep.anno= res.anno;
                 this.storedb(this.storerep);
                }
              } else {
                this._service.error('Errore',
                'Data già presente o validata : ' + res.data, {
                  position: ['bottom', 'center'],
                  timeOut: 2000,
                  showProgressBar: true,
                  pauseOnHover: false,
                  clickToClose: true,
                });
              }
            },
            err => console.log(err)
          );
           } else {
             this._service.error('Errore',
             'Mese chiuso', {
               position: ['bottom', 'center'],
               timeOut: 3000,
               showProgressBar: true,
               pauseOnHover: false,
               clickToClose: true,
             });
           }
         },
         err => console.log(err)
       );
    }
    this.check.length="";
    };
 
 
  storedb(reperibilita){
     this._auth.salvareperibilita(reperibilita)
     .subscribe(res => {
        if (res.risposta == "Success") {
           this._service.success('Success',
          'Dati salvati con successo.', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this._router.navigate(['/updaterep'], { skipLocationChange: true });
        } else {
          this._service.error('Errore',
          'Dati non salvati contattare amministratore', {
            position: ['bottom', 'center'],
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: false,
            clickToClose: true,
          });
          this._router.navigate(['/menu'], { skipLocationChange: true });
        }
      },
      err => console.log(err)
    );  
}
updaterep(){
  this._router.navigate(['/updaterep'], { skipLocationChange: true });
}
}
