import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { MyLocalStorageService } from './localstorage/mylocalstorage.service';
import { element, ElementFinder } from 'protractor';

// tslint:disable:member-ordering
@Component({
	// tslint:disable: indent
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	posizione: any;
	profile: any;
	barra: any;
	company1: any;
	@HostListener('window:beforeunload', ['$event'])
	beforeunloadHandler(event) {
		localStorage.clear();
	}
	title = 'Accamieasyjob';
  btn = document.getElementById('btn');
	datesSelected: NgbDateStruct[] = [];
	change(value: NgbDateStruct[]) {
		this.datesSelected = value;
	}
  firstlogin: string = 'false';
	// tslint:disable-next-line: variable-name
	constructor(public _storage: MyLocalStorageService, private _router: Router, public _authService: AuthService) {}

	ngOnInit() {
		this.barra = 'false';
    this._storage.setItem('firstlogin', this.firstlogin)
		this._router.navigate(['/welcome'], { skipLocationChange: true });
		this.company1 = localStorage.getItem('company');
		this.profile = localStorage.getItem('profile');
		localStorage.setItem('navigation', 'Benvenuto');
	}

	login() {
		this._router.navigate(['/login'], { skipLocationChange: true });
		this.posizione = 'login';
    this._storage.setItem('firstlogin','false')
		localStorage.setItem('navigation', 'login');
	}
	primoaccesso() {
    localStorage.setItem('firstlogin', 'true');
		this._router.navigate(['/primoaccesso'], { skipLocationChange: true });
		this.posizione = 'primoaccesso';
		localStorage.setItem('navigation', 'primoaccesso');
	}
	updateaccount() {
		this._router.navigate(['/updateaccount'], { skipLocationChange: true });
		this.posizione = 'updateaccount';
		localStorage.setItem('navigation', 'updateaccount');
	}
	updaterep() {
		this._router.navigate(['/updaterep'], { skipLocationChange: true });
		this.posizione = 'updaterep';
		localStorage.setItem('navigation', 'updaterep');
	}
	menu() {
		this._router.navigate(['/menu'], { skipLocationChange: true });
		this.posizione = 'menu';
		localStorage.setItem('navigation', 'menu');
	}
	welcome() {
		this._router.navigate(['/welcome'], { skipLocationChange: true });
		this.posizione = 'welcome';
		localStorage.setItem('navigation', 'Benvenuto');
	}
  corsi() {
		this._router.navigate(['/training'], { skipLocationChange: true });
		this.posizione = 'training';
		localStorage.setItem('navigation', 'training');
	}
  rubrica() {
		this._router.navigate(['/rubrica'], { skipLocationChange: true });
		this.posizione = 'rubrica';
		localStorage.setItem('navigation', 'rubrica');
	}
	settings() {
		this._router.navigate(['/settings'], { skipLocationChange: true });
		this.posizione = 'settings';
		localStorage.setItem('navigation', 'settings');
	}
	certificato() {
		this._router.navigate(['/fileupload'], { skipLocationChange: true });
		this.posizione = 'certificato';
		localStorage.setItem('navigation', 'certificato');
	}
	summary() {
		this._router.navigate(['/summary'], { skipLocationChange: true });
		this.posizione = 'summary';
		localStorage.setItem('navigation', 'summary');
	}
  updatetrasferta() {
		this._router.navigate(['/updatetrasferta'], { skipLocationChange: true });
		this.posizione = 'updatetrasferta';
		localStorage.setItem('navigation', 'updatetrasferta');
	}
	admin(){
		this._router.navigate(['/admin'], { skipLocationChange: true });
	  }
}
