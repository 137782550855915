import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Estrairep } from '../shared/estrairep.model';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SpinnerService } from '../services/spinner.service';
// tslint:disable: class-name
export interface anno {
  value: string;
  viewValue: string;
}
export interface mese {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-meselock',
  templateUrl: './meselock.component.html',
  styleUrls: ['./meselock.component.scss']
})
export class MeselockComponent implements OnInit {
  tableColumns: string[] = ['anno', 'mese', 'id'];
  dataSource = [];
  setting: Estrairep = new Estrairep();
  mese_web: any;
  anno_web: any;
  company: any;
  anni: anno[] = [
    { value: '2019', viewValue: '2019' },
    { value: '2020', viewValue: '2020' },
    { value: '2021', viewValue: '2021' },
    { value: '2022', viewValue: '2022' }
  ];
  mesi: mese[] = [
    { value: '01', viewValue: 'Gennaio' },
    { value: '02', viewValue: 'Febbraio' },
    { value: '03', viewValue: 'Marzo' },
    { value: '04', viewValue: 'Aprile' },
    { value: '05', viewValue: 'Maggio' },
    { value: '06', viewValue: 'Giugno' },
    { value: '07', viewValue: 'Luglio' },
    { value: '08', viewValue: 'Agosto' },
    { value: '09', viewValue: 'Settembre' },
    { value: '10', viewValue: 'Ottobre' },
    { value: '11', viewValue: 'Novembre' },
    { value: '12', viewValue: 'Dicembre' }
  ];
  meselocked: any[] = [];
  // tslint:disable-next-line:max-line-length
  constructor(public _spinnerService: SpinnerService, private datePipe: DatePipe, private _router: Router, private _service: NotificationsService, public _auth: AuthService) { }

  async ngOnInit() {
    this.company = localStorage.getItem('company');
    this.mese_web = this.datePipe.transform(new Date(), "MM");
    this.anno_web = this.datePipe.transform(new Date(), "yyyy");
    await this.load_lock();
    await this.load_lock1();
  }
  async load_lock() {

    await this._auth.load_lock(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "novalue") {
            this.dataSource = [];
          } else {
            this.dataSource = res;
          }
        },
        err => console.log(err)
      );
    return this.dataSource;
  }
  async load_lock1() {
    await this._auth.load_lock1(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "novalue") {
            this.meselocked = [];
          } else {
            this.meselocked = res;
          }
        },
        err => console.log(err)
      );
  }
  async check_lock() {
    this.setting.anno = this.anno_web;
    this.setting.mese = this.mese_web;
    await this._auth.check_lock(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this.meselock();
          } else {
            this._service.error('Errore',
              'Mese già bloccato', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
        },
        err => console.log(err)
      );
  }
  async meselock() {
    this.setting.anno = this.anno_web;
    this.setting.mese = this.mese_web;
    await this._auth.mese_lock(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this.mese_lock1();
          } else {
            this._service.error('Errore',
              'Errore update reperibilita utente', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
        },
        err => console.log(err)
      );

  }
  async mese_lock1() {
    await this._auth.mese_lock1(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
              'Mese bloccato con successo', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
            this.load_lock();
            this.load_lock1();
            this.dataSource = this.dataSource;
          } else {
            this._service.error('Errore',
              'Errore insert tabella lock_mese', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }

        },
        err => console.log(err)
      );

  }
  async meseunlock(settings) {
    this.setting = settings;
    //this.setting.anno=this.anno_web;
    //this.setting.mese=this.mese_web;
    await this._auth.mese_unlock(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this._service.success('Success',
              'Mese sbloccato con successo', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          } else {
            this._service.error('Errore',
              'Qualcosa è andato storto contatta amministratore', {
              position: ['bottom', 'center'],
              timeOut: 3000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: true,
            });
          }
        },
        err => console.log(err)
      );
    this.load_lock();
  }
  async meseunlock1(id, row) {
    console.log(row);
    console.log(id);
    const temp = this.meselocked.filter(x => x.id === row.id);
    this.setting.mese = temp[0].mese;
    this.setting.anno = row.anno;
    this.setting.idmese = id;
    await this._auth.mese_unlock1(this.setting)
      .subscribe(
        res => {
          if (res.risposta == "Success") {
            this.meseunlock(this.setting);
          }
        },
        err => console.log(err)
      );
  }
  admin() {
    this._router.navigate(['/admin'], { skipLocationChange: true });
  }
}
