<br>

<mat-card class="example-card" style="width: 20em; background-color: rgb(153, 218, 238)" >
    <mat-form-field style="width: 250px;">
      <input matInput placeholder="Email" [formControl]="emailFormControl" required>
      <mat-error *ngIf="emailFormControl.hasError('required')">Mandatory</mat-error>
      <mat-error *ngIf="emailFormControl.hasError('email')">Insert valid email</mat-error>
    </mat-form-field>
    <div>
    <mat-form-field style="width: 250px;">
        <input matInput placeholder="Password" (keyup.enter)= "loginUser()"type="password" [formControl]="passwordFormControl" passwordToggle required >
        <mat-error *ngIf="passwordFormControl.hasError('required')">Mandatory</mat-error>
      </mat-form-field>
    </div>
    <button style="margin:5px" mat-raised-button color="primary"  (click)= "loginUser()" type="submit" [disabled]="!emailFormControl.valid || !passwordFormControl.valid">Login
      <i class="material-icons right">send</i>
    </button>
</mat-card>

<br>
<button mat-raised-button (click)= "resetpwd()" type="submit" style="margin:5px; font-size: 12px; background-color: white" [disabled]="!emailFormControl.valid" >Forgot password
</button>
